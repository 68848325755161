import React, { Component, useEffect } from 'react';
import { NavMenu } from '../Nav/NavMenu';
import { SideMenu } from '../SideMenu/SideMenu';

import { OffcanvasMenu } from '../SideMenu/OffcanvasMenu';
import UserListLoader from '../Helpers/UserListLoader'

import { Outlet } from 'react-router-dom';
import styles from './Layout.module.scss'
import { useParams } from 'react-router-dom';
import CurrentUserService from '../../services/currentUser.service';

export function Layout({ menuType }) {  
  let { userUid } = useParams();

  useEffect(() => {
    console.log('layout userUid:', userUid);
    if (userUid) {
      CurrentUserService.setCurrentUser(userUid);
    };

  }, [userUid]);
  
    return (
      <>
        <UserListLoader />
        <OffcanvasMenu menuType={menuType} />
        <div className={`${styles.root}`}>
          <div className={styles.header}>          
            <NavMenu />
          </div>
          {/*<main>*/}
          <div className={styles.body}>
            <div className={`${styles.sideMenu} collapse collapse-horizontal show`} id="navbarSideMenu">
              <SideMenu menuType={menuType} />
            </div>
            <div className={styles.main}>
              <div className={styles.mainInner}>                
                <Outlet />
              </div>
            </div>
          </div>
          {/*</main>*/}
        </div>
      </>
    );
  
}
