import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import UserDocuments from '../../../components/Shared/Documents/UserDocuments'

import MainBlockContainer from '../../MainBlockContainer';
import MainBlockHeader from '../../MainBlockHeader'
import { useTranslation } from 'react-i18next';

export default function Documents() {  
  let { consultationUid } = useParams();
  const { t } = useTranslation();

  //useEffect(() => {   
    
  //}, []);


  return (
    <>              
      <MainBlockHeader header={t('docs.header')} >
      </MainBlockHeader>

      <MainBlockContainer>
        <UserDocuments documentsBaseUrl={`/consultation/expert/accepted/${consultationUid}/user/files`} />
      </MainBlockContainer>
    </>
  );  
}

