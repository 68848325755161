import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import 'flag-icons/css/flag-icons.min.css'

const lngs = {
  en: { flag: 'us', nativeName: 'English' },
  ru: { flag: 'ru', nativeName: 'Русский' }
};

export { NavLngDropdown };

function NavLngDropdown({ includeTextInHeader }) {
  const { t, i18n } = useTranslation();

  let generateSelectButtonText = (lng) => {
    return (<><span className={`fi fi-${lngs[lng].flag}`}></span> {lngs[lng].nativeName}</>)    
  };
  let generateSelectMainButtonText = (lng, addName) => {
    return (<><span className={`fi fi-${lngs[lng].flag}`}></span> {addName ? lngs[lng].nativeName : ''}</>)
  };

  return (
    <Dropdown drop="start">
      {
        includeTextInHeader &&
        <Dropdown.Toggle variant="transparent" id="dropdown-basic">
          {generateSelectMainButtonText(i18n.resolvedLanguage)}
        </Dropdown.Toggle>
      }
      {
        !includeTextInHeader &&
        <Dropdown.Toggle variant="light" id="dropdown-basic" className="w-100" style={{ textAlign: 'left' }} >
          {generateSelectMainButtonText(i18n.resolvedLanguage, true)}
        </Dropdown.Toggle>
      }

      <Dropdown.Menu>
        {Object.keys(lngs).map((lng) => (
          <Dropdown.Item key={lng} onClick={() => i18n.changeLanguage(lng)}>
            {generateSelectButtonText(lng)}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
