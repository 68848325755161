import React from 'react';
import {
  flexRender,
} from "@tanstack/react-table";

import * as CommonIcons from '../../CommonIcons';
import Dropdown from 'react-bootstrap/Dropdown';
import { useParams, Link } from 'react-router-dom';

export default function TableRows({ table, styles, isRowHover, rowStyle, cellStyle, innerCellStyle, allowWrapStyle, headerNameStyle, valueStyle, onRowClick, dropDownMenuActions }) {
  if (styles) {
    if (!rowStyle) rowStyle = isRowHover ? `${styles.row} ${styles.hoverable}` : styles.row;
    if (!cellStyle) cellStyle = styles.cell;
    if (!innerCellStyle) innerCellStyle = styles.innerCell;
    if (!allowWrapStyle) allowWrapStyle = styles.allowWrap;
    if (!headerNameStyle) headerNameStyle = styles.headerName;
    if (!valueStyle) valueStyle = styles.value;
  }

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div      
      ref={ref}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  ));

  return (
    <>
      {table.getRowModel().rows.map(row => {
        return (
          <div key={row.id} className={rowStyle} onClick={() => { if (onRowClick) onRowClick(row.original) }}>
            <>
              {dropDownMenuActions && dropDownMenuActions.length > 0 && (
                <div className={styles.menu}>
                  <Dropdown drop="start" >
                    <Dropdown.Toggle as={CustomToggle} variant="transparent" id="dropdown-basic">
                      <CommonIcons.ThreeDotsMenu />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {dropDownMenuActions.map((action, index) => {                                        
                        return (
                          <Dropdown.Item
                            key={`dd-${row.id}-${index}`}
                            as={action.as}
                            to={action.getLinkTo ? action.getLinkTo(row.original) : null}
                            onClick={action.onClick ? (e) => { e.stopPropagation(); action.onClick(row.original) } : null}
                          >
                            {action.text}
                          </Dropdown.Item>
                        )
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
              {row.getVisibleCells().map(cell => {
                let hasMeta = cell.getContext().cell.column.columnDef.meta;
                return (
                  <div key={cell.id}
                    className={`${cellStyle} ${cell.column.columnDef.className ?? ''} ${cell.column.columnDef.hideOnMobile ? styles.hideOnMobile : ''}`}                    
                  >
                    <div className={`${innerCellStyle} ${cell.column.columnDef.allowWrap ? allowWrapStyle : ''}`}>
                      <div className={headerNameStyle}>{cell.column.columnDef.header}:</div>
                      <div className={valueStyle}
                        {...(hasMeta && { ...hasMeta.getCellContextInner(cell.getContext()) })}
                      >{flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                      </div>
                    </div>
                  </div>
                )
              })}
            </>
          </div>
        )
      })}      
    </>
  )
}