import React, { useState, useEffect, useRef } from 'react';
import api from "../../../services/api";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CommonHighchartsLine } from '../Ecg/Line/CommonHighchartsLine'
import { EcgHighcharts } from '../Ecg/EcgHighcharts'

export function AverageIntervalChart({ displayName, data, chartName, markers }) {
  const { t } = useTranslation();


  const chartRef = useRef(null);
  
  //const yAxisSettings = [{ title: { text: 'HR, bpm' }, labels: { format: '{value} bpm' }, opposite: false }];

  //const chartOptions = {
  //  title: {
  //    text: t('recordView.processedData.charts.averageInterval.header')
  //  },
  //  xAxis: {
  //    maxRange: undefined
  //  },
  //  yAxis: {
  //    min: -3,
  //    max: 3
  //  },
  //  legend: {
  //    enabled: false
  //  }
  //};

  const yAxisSettings = [{ title: { text: '' }, labels: { format: '{value} mV' }, opposite: false }];

  useEffect(() => {
    console.log(displayName);

    if (data) {
      chartRef.current.setupYAxis(yAxisSettings);
      chartRef.current.setInitialData(data, null, markers);      
    }
  }, []);

  useEffect(() => {
    console.log(displayName);

    if (data) {

      //let min = Math.min(...data.map(x => x[1]));
      //let max = Math.max(...data.map(x => x[1]));

      //chartRef.current.setupYAxis(yAxisSettings);
      chartRef.current.setInitialData(data, null, markers);
      //chartRef.current.setChartExtremesX(data[data.length - 1][0], 0);
      //chartRef.current.setChartExtremesYMinMax(min, max);
    }
  }, []);

  const specificChartOptions = {
    chart: {
      height: undefined
    },
    title: {
      text: t('recordView.processedData.charts.averageInterval.header')
    },
    legend: {
      enabled: false
    },
    xAxis: {
      softMin: 0,
      softMax: 1440      
    }
  }; 

  return (
    <>
      <h3>{displayName}</h3>
      {/*<CommonHighchartsLine ref={chartRef} seriesName={'HR'} chartOptions={chartOptions} />*/}
      <EcgHighcharts ref={chartRef} isStockChart={false} isHideControls={true} specificChartOptions={specificChartOptions}
        hideCalibrationSeries={false}
        calibrationSeriesType={'small'} />    
    </>
  );
}

