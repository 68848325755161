import { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import styles from './SideMenu.module.scss'

import UserLoginPermissions from "../../services/userLogin.permissions.service";

import { MenuListUser } from './Components/MenuListUser'
import { MenuListExpert } from './Components/MenuListExpert'
import { MenuListMetrology } from './Components/MenuListMetrology'
import { MenuListAdmin } from './Components/MenuListAdmin'
import { MenuListOperator } from './Components/MenuListOperator'

import { useTranslation } from 'react-i18next';

import Select from 'react-select';

export { SideMenuMainSplitted };
function SideMenuMainSplitted({ closeOffcanvas }) {
  let navigate = useNavigate();

  const location = useLocation();
  const { t } = useTranslation();

  const path = location.pathname;

  let { consultationUid } = useParams();

  const OPTION_CLIENT = "client";
  const OPTION_EXPERT = "expert";
  const OPTION_METROLOG = "metrolog";
  const OPTION_OPERATOR = "operator";
  const OPTION_ADMIN = "admin";

  const options = [
    { value: OPTION_CLIENT, label: t('sidebar.selector.client'), linkTo: '/' }
  ];

  const fillMenuOptions = function() {
    if (UserLoginPermissions.isExpert())
      options.push({ value: OPTION_EXPERT, label: t('sidebar.selector.expert'), linkTo: '/consultations/expert/active/list' });

    if (UserLoginPermissions.canDoMetrology())
      options.push({ value: OPTION_METROLOG, label: t('sidebar.selector.metrologyst'), linkTo: '/metrology/devices' });

    if (UserLoginPermissions.isOperator())
      options.push({ value: OPTION_OPERATOR, label: t('sidebar.selector.operator'), linkTo: '/operator/device-list' });

    if (UserLoginPermissions.isAdmin())
      options.push({ value: OPTION_ADMIN, label: t('sidebar.selector.admin'), linkTo: '/admin/device-list' });
  }

  const [selectedOption, setSelectedOption] = useState(options[0]);  

  const handleChange = (option) => {
    if (option.linkTo && option.linkTo !== '')
      navigate(option.linkTo);

    setSelectedOption(option);
  };
  

  useEffect(() => {
    
  });

  const closeOffcanvasAction = () => {
    if (closeOffcanvas) {
      let closeBtn = document.getElementById('closeOffcanvasButton'); //dirty hack to find a button and close to avoid integrating bootstrap.js
      if (closeBtn) closeBtn.click();
    }
  };  
    
  const isExpertViewMode = () => consultationUid || path.includes(`/consultations/expert/`) || path.includes(`/metrology/devices`) || path.includes(`/admin/`);

  const selectStylesNoGap = {
    menu: base => ({
      ...base,
      marginTop: 0
    })
  };

  fillMenuOptions();

  return (
    <>  
      <span className={styles.menuSelector}>
        <Select 
          value={selectedOption} onChange={handleChange} options={options} styles={selectStylesNoGap} className={styles.selector } />
      </span>

      {selectedOption.value === OPTION_CLIENT && <MenuListUser closeOffcanvasAction={closeOffcanvasAction} /> }

      {selectedOption.value === OPTION_EXPERT && UserLoginPermissions.isExpert() && (
        <MenuListExpert closeOffcanvasAction={closeOffcanvasAction} isExpertViewMode={isExpertViewMode()} />
      )}


      {selectedOption.value === OPTION_METROLOG && UserLoginPermissions.canDoMetrology() && (
        <MenuListMetrology closeOffcanvasAction={closeOffcanvasAction} />
      )}

      {selectedOption.value === OPTION_OPERATOR && UserLoginPermissions.isOperator() && (
        <MenuListOperator closeOffcanvasAction={closeOffcanvasAction} />
      )}


      {selectedOption.value === OPTION_ADMIN && UserLoginPermissions.isAdmin() && (
        <MenuListAdmin closeOffcanvasAction={closeOffcanvasAction} />
       )}

    </>
  )
}