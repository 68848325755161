import TokenService from "./token.service";
class UserLoginPermissions {
  getPermissions() {    
    return TokenService.getUserRoles()?.permissions ?? TokenService.getUser()?.permissions;
  }

  checkPermission(permissions, value) {
    return ((permissions ?? this.getPermissions()) & value) > 0;
  }

  canCreateUsers(permissions) {
    return this.checkPermission(permissions, 1);
  }

  canCreateUserGroups(permissions) {
    return this.checkPermission(permissions, 2);
  }
  canDoMetrology(permissions) {    
    return this.checkPermission(permissions, 4);
  }

  isExpert(permissions) {
    return this.checkPermission(permissions, 512);    
  }

  isOperator(permissions) {
    return this.checkPermission(permissions, 1024);
  }

  isAdmin(permissions) {
    return this.checkPermission(permissions, 8192);    
  }

  combinePermissions(canCreateUsers, canCreateUserGroups, canDoMetrology, isExpert, isOperator, isAdmin) {
    return (canCreateUsers ? 1 : 0) +
      (canCreateUserGroups ? 2 : 0) +
      (canDoMetrology ? 4 : 0) +
      (isExpert ? 512 : 0) +
      (isOperator ? 1024 : 0) +
      (isAdmin ? 8192 : 0);
  }
}
export default new UserLoginPermissions();