import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';

import Highcharts from 'highcharts/highstock'
import AnnotationsFactory from "highcharts/modules/annotations";
import HighchartsReact from 'highcharts-react-official'
import { useTranslation } from 'react-i18next';
import mergeDeep from '../../../utils/mergeDeep';
import api from "../../../services/api";
import { use } from 'i18next';
import { highchartsLabelFormatterFromMs } from "../../../utils/dateFormatter";
import * as ChartConsts from '../../ChartSettings/ChartSettings'

// init the module
AnnotationsFactory(Highcharts);

Highcharts.SVGRenderer.prototype.symbols.rectangleDown = function (x, y, w, h) {  
  let width = w;
  let height = h * 5;

  return ['M', x + width / 4, y,
    'L', x + width / 4, y + height,
    'L', x + width * 3 / 4, y + height,
    'L', x + width * 3 / 4, y,
    'z'];
};
Highcharts.SVGRenderer.prototype.symbols.rectangleUp = function (x, y, w, h) {
  let width = w;
  let height = - h * 5 + h;

  return ['M', x + width / 4, y + h,
    'L', x + width / 4, y + height,
    'L', x + width * 3 / 4, y + height,
    'L', x + width * 3 / 4, y + h,
    'z'];
};

const OnlineHighchartsAsyncForwardRef = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const chartComponentRef = useRef(null);
  const chartBigComponentRef = useRef(null);
  const chartDataRef = useRef({});
  const viewModeRef = useRef(0);
  const currentChartSpeed = useRef(null);
  const previousOnlineExceededBorders = useRef(null);

  const currentTimeAdjustment = useRef(0);
  const latestReceivedTime = useRef(0);

  const TOTAL_CELLS_ON_Y = 30;
  const MAKE_SQUARE_CELLS_ON_ANY_MONITOR_COEFFICIENT = 0.9;

  const maxXAxisWidth = useRef(10000);
  const DEFAULT_SERIES_NAME = 'series-data';
  const DEFAULT_SERIES_NAV_NAME = 'series-data-navigator';

  const asyncDataLoadUrlTemplate = useRef(null);
  const maxRecordLength = useRef(0);

  const markersEnabled = useRef(false);

  const getBigChart = () => chartBigComponentRef.current.chart;
  const getNavigationChart = () => chartComponentRef.current.chart;

  const OFFSET_LEAVE_WINDOW_PERCENT_AFTER_MOVE = 0; // from 0 to 1, 0 - clear all data, 0.3 - leave 30% of data

  useImperativeHandle(ref, () => ({
    setInitialData(data, seriesSettings) {
      setInitialData(data, seriesSettings);
    },

    setInitialDataForLongSeries(longData, asyncDataLoadUrl, seriesSettings) {
      //console.log('setInitialDataForLongSeries');

      asyncDataLoadUrlTemplate.current = asyncDataLoadUrl;
      let newSeriesSettings = seriesSettings ?? {};
      newSeriesSettings.showInNavigator = false;
      setInitialData([], newSeriesSettings);

      let chart = getNavigationChart();

      let seriesId = DEFAULT_SERIES_NAV_NAME;

      //longData.navigationData.push([15000, 1]);
      let navigatorSeries =
      {
        id: seriesId,
        data: longData.navigationData,
        showInNavigator: true,
        visible: false,
        navigatorOptions: {
          visible: true
        },
        dataGrouping: {
          enabled: false
        }
        //name: (seriesSettings && seriesSettings.name) ?? props.seriesName,
        //color: (seriesSettings && seriesSettings.color) ?? 'blue',
        //showInNavigator: true,
        //yAxis: (seriesSettings && seriesSettings.yAxisId) ?? 0,
        //zoneAxis: (seriesSettings && seriesSettings.zoneAxis) ?? undefined,
        //zones: (seriesSettings && seriesSettings.zones) ?? undefined,
        //type: (seriesSettings && seriesSettings.type) ?? undefined
      };
      //if (chart.get(seriesId))
      //  chart.get(seriesId).update(newSeries);
      //else
      if (chart.get(seriesId))
        chart.get(DEFAULT_SERIES_NAV_NAME).remove();

      chart.addSeries(navigatorSeries);

      if (longData.navigationData.length > 0) {
        maxRecordLength.current = longData.navigationData[longData.navigationData.length - 1][0];
        setChartExtremesX(getNavigationChart(), currentChartSpeed.current ?? props.defaultCellTimeXms, longData.navigationData[0][0]);
      }

      //console.log(chart.series);
      //chart.series[0].setData({});
    },
    setDataViewMode(mode) {
      setDataViewMode(mode);
    },
    addNewDataToChart(data, seriesId) {
      addNewDataToChart(data, seriesId);
    },
    addNewDataToChartLongSeries(data) {
      addNewDataToChartLongSeries(data)
    },
    //getData() {
    //  return chartDataRef.current[DEFAULT_SERIES_NAME];
    //},
    //getChart() {
    //  return chartComponentRef.current.chart;
    //},
    setupYAxis(settings) {
      let chart = getBigChart();

      settings.map((s, index) => {
        if (!s.id && index == 0)
          chart.yAxis[index].update(s);
        else {
          if (s.id && chart.get(s.id))
            chart.get(s.id).update(s);
          else
            chart.addAxis(s);
        }
      });
    },
    setChartExtremesX(cellTimeMs, preferedPointStart) {
      //console.log('setChartExtremesX', cellTimeMs, preferedPointStart);
      
      let res = setChartExtremesX(getBigChart(), cellTimeMs, preferedPointStart);
      //console.log('calculated setChartExtremesX ', res);
      setNavigationChartExtremesX(getNavigationChart(), cellTimeMs, preferedPointStart, res.visibleTimeOnXAxis);

      //if (viewModeRef.current == 1) //refresh
      //  clearDataOnChart();
    },
    setChartExtremesY(voltagePerCell) {
      let
        chart = getBigChart(),
        yAxis = chart.yAxis[0];

      yAxis.update({ minorTickInterval: voltagePerCell, tickInterval: voltagePerCell * 10 });
      yAxis.setExtremes(- TOTAL_CELLS_ON_Y * voltagePerCell, TOTAL_CELLS_ON_Y * voltagePerCell);
    },
    setChartExtremesYMinMax(min, max) {
      let
        chart = getBigChart(),
        yAxis = chart.yAxis[0];
      yAxis.setExtremes(min, max);
    },
    scrollToEnd() {
      //setCurrentViewLimitMoveToEnd();
      moveViewWindowToRight(true);
    },
    addAnnotation(newAnnotation) {
      getBigChart().addAnnotation(newAnnotation);
    },
    setPlotLinesX(plotLinesX) {
      setPlotLinesX(plotLinesX);
    },
    setMarkersVisibility(isVisible) {
      setMarkersVisibility(isVisible);
    }
  }));

  useEffect(() => {
    //console.log('accumulation mode:', props.mode);    

  }, []);


  const setInitialData = function (data, seriesSettings) {
    chartDataRef.current[(seriesSettings && seriesSettings.seriesId) ?? DEFAULT_SERIES_NAME] = data;
    let chart = getBigChart();
    let seriesId = (seriesSettings && seriesSettings.seriesId) ?? DEFAULT_SERIES_NAME;

    let newSeries =
    {
      id: seriesId,
      data: data,
      name: (seriesSettings && seriesSettings.name) ?? props.seriesName,
      color: (seriesSettings && seriesSettings.color) ?? 'blue',
      showInNavigator: (seriesSettings && seriesSettings.showInNavigator !== undefined) ? seriesSettings.showInNavigator : true,
      yAxis: (seriesSettings && seriesSettings.yAxisId) ?? 0,
      zoneAxis: (seriesSettings && seriesSettings.zoneAxis) ?? undefined,
      zones: (seriesSettings && seriesSettings.zones) ?? undefined,
      type: (seriesSettings && seriesSettings.type) ?? undefined,
      lineWidth: 2
    };
    if (chart.get(seriesId))
      chart.get(seriesId).update(newSeries);
    else
      chart.addSeries(newSeries);
  };

  const setPlotLinesX = function (plotLinesX) {
    let chart = getBigChart(),
      xAxis = chart.xAxis[0];

    if (plotLinesX && plotLinesX.length > 0) {
      plotLinesX.map(line => xAxis.addPlotLine(line));
    };
  };

  const setMarkersVisibility = (isVisible) =>{
    markersEnabled.current = isVisible;

    let chart = getBigChart(),
      xAxis = chart.xAxis[0];

    //console.log(xAxis.min, xAxis.max);
    
    afterSetExtremes({ target: { chart: chart }, min: xAxis.min, max: xAxis.max });
  };

  const moveViewWindowToRight = (ignoreOffset) => {
    let chart = getBigChart(),
      cellTimeMs = currentChartSpeed.current ?? props.defaultCellTimeXms,
      visibleTimeOnXAxis = calcMaxXAxisVisibleTimeMs(chart, cellTimeMs),
      currentMax = Math.max(maxRecordLength.current, latestReceivedTime.current);    

    setChartExtremesX(chart, cellTimeMs, currentMax - visibleTimeOnXAxis * (ignoreOffset ? 0 : OFFSET_LEAVE_WINDOW_PERCENT_AFTER_MOVE));
  };

  const setChartExtremesX = function (chart, cellTimeMs, preferedPointStart) {   
    console.log('setChartExtremesX');

    currentChartSpeed.current = cellTimeMs;

    let xAxis = chart.xAxis[0],
      pointStart = preferedPointStart ?? xAxis.min,
      visibleTimeOnXAxis = calcMaxXAxisVisibleTimeMs(chart, cellTimeMs),
      pointEnd = pointStart + visibleTimeOnXAxis;

    if (pointStart < xAxis.dataMin) pointStart = xAxis.dataMin;

    maxXAxisWidth.current = visibleTimeOnXAxis;

    if (maxRecordLength.current > 0 && pointEnd > maxRecordLength.current + visibleTimeOnXAxis) {
      pointEnd = pointStart ?? 0 + visibleTimeOnXAxis;
      //pointStart = pointEnd - visibleTimeOnXAxis;
      //console.log('fixed pointStart - ', pointStart, 'pointEnd - ', pointEnd);
      //console.log(chart);
    }

    //console.log('pointEnd - ', pointEnd);
    //console.log('min', xAxis.min, ' - ', xAxis.max);
    //console.log('dataMin', xAxis.dataMin, ' - ', xAxis.dataMax);

    console.log('min:', xAxis.min, ' max:', xAxis.max, 'dataMin:', xAxis.dataMin, ' dataMax:', xAxis.dataMax);

    console.log('pointStart-', pointStart ?? 0, ' pointEnd-', pointEnd);

    xAxis.setExtremes(pointStart ?? 0, pointEnd);    
    xAxis.update({ minorTickInterval: cellTimeMs, tickPositioner: function () { return getChartTickPositions(this, cellTimeMs) } });
    chart.reflow();
    return { visibleTimeOnXAxis: visibleTimeOnXAxis };
  };

  const setNavigationChartExtremesX = function (chart, cellTimeMs, preferedPointStart, visibleTimeOnXAxis) {
    currentChartSpeed.current = cellTimeMs;

    let xAxis = chart.xAxis[0],
      pointStart = preferedPointStart ?? xAxis.min,      
      pointEnd = pointStart + visibleTimeOnXAxis;

    maxXAxisWidth.current = visibleTimeOnXAxis;

    if (maxRecordLength.current > 0 && pointEnd > maxRecordLength.current + visibleTimeOnXAxis) {
      pointEnd = pointStart ?? 0 + visibleTimeOnXAxis;
      //pointStart = pointEnd - visibleTimeOnXAxis;

      if (pointStart < xAxis.dataMin)
        pointStart = xAxis.dataMin;
      //console.log('fixed pointStart - ', pointStart, 'pointEnd - ', pointEnd);
      //console.log(chart);
    }

    //console.log('pointEnd - ', pointEnd);
    console.log('min:', xAxis.min, ' max:', xAxis.max, 'dataMin:', xAxis.dataMin, ' dataMax:', xAxis.dataMax);

    console.log('pointStart-', pointStart ?? 0, ' pointEnd-', pointEnd);

    xAxis.setExtremes(pointStart ?? 0, pointEnd);
    //xAxis.update({ minorTickInterval: cellTimeMs, tickPositioner: function () { return getChartTickPositions(this, cellTimeMs) } });
    chart.reflow();
  };

  const setDataViewMode = (mode) => {
    viewModeRef.current = mode;
    //console.log('mode:', mode, '; maxXAxisWidth:', maxXAxisWidth.current);

    //let chart = getBigChart();

    //if (mode === 0) { // accumulation mode
    //  setAccumulationMode();
    //  if (props.accumulationModeSetCallback)
    //    props.accumulationModeSetCallback(chart, currentTimeAdjustment.current);
    //}
    //if (mode === 1) { // refresh mode
    //  setRefreshMode();
    //  if (props.refreshModeSetCallback)
    //    props.refreshModeSetCallback(chart, currentTimeAdjustment.current);
    //}

    if (mode === 0) {
      //navigator: { adaptToUpdatedData: false }
      //chart.update({ navigator: { adaptToUpdatedData: true } }, true);
    }

    if (mode === 2) {
      //setCurrentViewLimitMoveToEnd();
      getBigChart().get(DEFAULT_SERIES_NAME).setData([]);
      moveViewWindowToRight(true);
      //navigator: { adaptToUpdatedData: false }
      //chart.update({ navigator: { adaptToUpdatedData: false } }, true);
    }
  };

  const getChartTickPositions = function (xAxis, cellTimeMs) {
    let positions = [],
      min = xAxis.min,
      max = xAxis.max,
      increment = cellTimeMs * 10,
      tick = Math.floor(min / increment) * increment;

    //if (xAxis.showTicks !== undefined && !xAxis.showTicks)
    //  return positions;

    if (max !== null && min !== null) {
      for (tick; tick - increment <= max; tick += increment) {
        //check why tickes are still visible if the window is out of the range
        positions.push(tick);
      }
    }
    return positions;
  };

  let calcMaxXAxisVisibleTimeMs = function (chart1, cellTimeMs) {
    let chart = getBigChart();
    let chartWidth = chart.xAxis[0].width; //chart.chartWidth
    let standardCellHeightInPx = chart.chartHeight * MAKE_SQUARE_CELLS_ON_ANY_MONITOR_COEFFICIENT / (TOTAL_CELLS_ON_Y * 2),
      visibleCellsX = chartWidth / standardCellHeightInPx,
      cellTime = cellTimeMs;

    return cellTime * visibleCellsX;
  }; 

  //const clearDataOnChart = () => {
  //  //console.log('currentTimeAdjustment.current: ', currentTimeAdjustment.current);
  //  if (chartDataRef.current[DEFAULT_SERIES_NAME].length > 0) {
  //    //console.log(chartDataRef.current[DEFAULT_SERIES_NAME].slice(-1));
  //    //if (viewModeRef.current == 0) // now accumulation, but was continuous
  //    //  currentTimeAdjustment.current = currentTimeAdjustment.current + chartDataRef.current[DEFAULT_SERIES_NAME].slice(-1)[0][0] ?? 0;
  //    //if (viewModeRef.current == 1) // now continuous, but was accumulation
  //    //  currentTimeAdjustment.current = chartDataRef.current[DEFAULT_SERIES_NAME].slice(-1)[0][0] ?? 0;
  //    currentTimeAdjustment.current = latestReceivedTime.current;
  //  }
  //  //console.log('currentTimeAdjustment.current: ', currentTimeAdjustment.current);

  //  for (let keyName in chartDataRef.current) {
  //    chartDataRef.current[keyName] = [];
  //    let chart = chartComponentRef.current.chart;
  //    if (chart.get(keyName) && chart.xAxis[0]) {
  //      chart.get(keyName).setData(chartDataRef.current[keyName], true, false, false);
  //      chart.xAxis[0].setExtremes(0, maxXAxisWidth.current);
  //    }
  //  }    
  //}

  //const setAccumulationMode = () => {    
  //  clearDataOnChart();
  //};

  //const setRefreshMode = () => {
  //  clearDataOnChart();    
  //};

  const checkDataLengthLessAllowedView = (series) => {
    if (props.defaultCellTimeXms || currentChartSpeed.current) {
      let speed = currentChartSpeed.current ?? props.defaultCellTimeXms;
      let maxPossibleLength = calcMaxXAxisVisibleTimeMs(getBigChart(), speed);
      let currentLength = series.xAxis.dataMax - series.xAxis.dataMin;

      //console.log('maxPossibleLength:', maxPossibleLength, '; currentLength: ', currentLength);

      return currentLength < maxPossibleLength;
    }
    return false;
  };

  const setCurrentViewLimitMoveToEnd = () => {  
    if (props.defaultCellTimeXms || currentChartSpeed.current) {
      let chart = getNavigationChart(),
        speed = currentChartSpeed.current ?? props.defaultCellTimeXms,
        xAxis = chart.xAxis[0];
      //var navSeries = chart.get(DEFAULT_SERIES_NAV_NAME);

      //console.log('setCurrentViewLimitMoveToEnd');
      //console.log('navSeries', navSeries);
      //console.log(calcMaxXAxisVisibleTimeMs(chart, speed));
      //console.log('latestReceivedTime: ', latestReceivedTime.current);
      let startPoint = latestReceivedTime.current > 0 ? latestReceivedTime.current : (maxRecordLength.current ?? 0);
      let moveBack = calcMaxXAxisVisibleTimeMs(getBigChart(), speed);
      setChartExtremesX(chart, speed, startPoint - moveBack);
    }
  };

  const addPointsToSeries = (chart, data, seriesId) => {
    let workingSeriesId = seriesId;
    if (data.length > 0) {
      latestReceivedTime.current = data.slice(-1)[0][0] ?? 0;
      maxRecordLength.current = latestReceivedTime.current;
    }
    let series = chart.get(workingSeriesId);
    for (let i = 0; i < data.length; i++) {
      series.addPoint(data[i], false);
      //if (i % Math.ceil(data.length / 5) === 0) {
      //  chart.redraw(false);
      //}
    }
  };

  const addNewDataToChartLongSeries = (item) => {    

    switch (viewModeRef.current) {
      case 0: //update navigator series only
        {
          let chart = getNavigationChart();
          let data = item.longRecord.navigationData;
          addPointsToSeries(chart, data, DEFAULT_SERIES_NAV_NAME);

          //let chartOutsideBorders = checkDataLengthLessAllowedView(series);

          chart.redraw({ duration: 110 });

          //if (previousOnlineExceededBorders.current && chartOutsideBorders != previousOnlineExceededBorders.current)
          //  setCurrentViewLimitMoveToEnd();
          //previousOnlineExceededBorders.current = chartOutsideBorders;

          break;
        }
      //case 0:
      case 2: //update navigator and main series
        {
          addPointsToSeries(getNavigationChart(), item.longRecord.navigationData, DEFAULT_SERIES_NAV_NAME);
          addPointsToSeries(getBigChart(), item.data, DEFAULT_SERIES_NAME);

          let series = getBigChart().get(DEFAULT_SERIES_NAME);
          //let chartStillInsideBorders = checkDataLengthLessAllowedView(series);
          let chartStillInsideBorders = getBigChart().xAxis[0].max > Math.max(maxRecordLength.current, latestReceivedTime.current);

          getBigChart().redraw({ duration: 50 });
          getNavigationChart().redraw({ duration: 50 });

          //if (previousOnlineExceededBorders.current && chartStillInsideBorders != previousOnlineExceededBorders.current)
          //  setCurrentViewLimitMoveToEnd();
          //previousOnlineExceededBorders.current = chartStillInsideBorders;

          if (previousOnlineExceededBorders.current && chartStillInsideBorders != previousOnlineExceededBorders.current) {
            moveViewWindowToRight();           
          }

          previousOnlineExceededBorders.current = chartStillInsideBorders;

          break;
        }
      default:
        throw 'Not implemented yet';
    }    
  };

  const addNewDataToChart = (data, seriesId) => {    
    let chart = getBigChart();
    let workingSeriesId = seriesId ?? DEFAULT_SERIES_NAV_NAME;

    if (data.length > 0)
      latestReceivedTime.current = data.slice(-1)[0][0] ?? 0;

    switch (viewModeRef.current) {
      case 0: //accumulation mode
        {
          let series = chart.get(workingSeriesId);

          let chartOutsideBorders = checkDataLengthLessAllowedView(series);                  

          for (let i = 0; i < data.length; i++) {
            series.addPoint(data[i], false);
          }          

          chart.redraw({ duration: 110 });

          if (previousOnlineExceededBorders.current && chartOutsideBorders != previousOnlineExceededBorders.current)
            setCurrentViewLimitMoveToEnd();
          previousOnlineExceededBorders.current = chartOutsideBorders;

          break;
        }
      case 1: //refresh mode
        {
          let array_chunks = [];

          let i = 0;
          let tempTimeAdjustment = currentTimeAdjustment.current;
          while (i < data.length) {
            let newPortion = 0;
            let startTime = data[i][0] - tempTimeAdjustment;
            if (startTime >= maxXAxisWidth.current) { tempTimeAdjustment = data[i][0]; startTime = 0; }
            while (((i + newPortion) < data.length) && (data[i + newPortion][0] - tempTimeAdjustment < maxXAxisWidth.current))
              newPortion++;

            let chunk = data.slice(i, i + newPortion);
            array_chunks.push(chunk);
            i += newPortion;
            if (newPortion === 0) break;
          }

          array_chunks.map(chunk => {
            let start = chunk[0][0] - currentTimeAdjustment.current;
            if (start >= maxXAxisWidth.current) { currentTimeAdjustment.current = chunk[0][0]; start = 0; }

            let end = chunk[chunk.length - 1][0] - currentTimeAdjustment.current;
            //if (end <= maxXAxisWidth.current) {
            let currentData = chartDataRef.current[workingSeriesId];
            let cutStart = currentData.findIndex(x => x[0] >= start);
            if (cutStart < 0) cutStart = currentData.length;
            let cutEnd = currentData.findIndex(x => x[0] > end);
            if (cutEnd < 0) cutEnd = currentData.length;

            //console.log('currentTimeAdjustment.current: ', currentTimeAdjustment.current, 'cutStart: ', cutStart, 'cutEnd: ', cutEnd);

            let dataToInsert = [];
            chunk.map(x => dataToInsert.push([x[0] - currentTimeAdjustment.current, x[1]]));

            //console.log('dataToInsert: ', dataToInsert);

            chartDataRef.current[workingSeriesId].splice(cutStart, cutEnd - cutStart, ...dataToInsert);

            drawVerticalLine(chunk[chunk.length - 1][0] - currentTimeAdjustment.current);
            //}

          });

          chart.get(workingSeriesId).setData(chartDataRef.current[workingSeriesId], true, false, false);
          chart.redraw(false);
          break;
        }
      default:
        throw 'Not implemented yet';
    }    

  };

  const drawVerticalLine = (x) => {
    let chart = getBigChart();    
    chart.xAxis[0].update({
      plotLines: [{
        color: '#FF0000', // Red
        width: 3,
        value: x // Position, you'll have to translate this to the values on your x axis
      }]
    });
  };

  const initChart = function (e) {
  };

  const afterSetExtremes = function (e) {
    console.log('afterSetExtremes', e);    
    if (e.trigger === 'navigator') setDataViewMode(0);
    if (viewModeRef.current === 2) return;

    if (asyncDataLoadUrlTemplate.current !== null) {
      //const { chartNavigation } = e.target;      

      let chart = getBigChart();

      chart.showLoading(t('common.loadingFromServer'));
      let min = Math.round(e.min);
      let max = Math.round(e.max);

      let requestUrl = asyncDataLoadUrlTemplate.current + `&start=${min}&end=${max}`;
      //chartNavigation.xAxis[0].setExtremes(min, max);

      console.log('afterSetExtremes min', min, 'max', max);
      chart.xAxis[0].setExtremes(min, max);
      chart.xAxis[0].min = min;
      chart.xAxis[0].max = max;

      if (!chart) return;
      //chart.xAxis[0].update({ minorTicks: false });

      api
        .get(requestUrl)
        .then((response) => {
          let receivedData = response.data;          
          chart.get(DEFAULT_SERIES_NAME).setData(receivedData.data, { updatePoints: false });          
          chart.hideLoading();
          //chart.xAxis[0].update({ minorTicks: true });
          if (receivedData.markers && markersEnabled.current) {
            //console.log('got markers');
            setTimeout(() => markPointsOnChart(chart, receivedData.markers), 500);
          }
          if (receivedData.plotLinesX) {
            setPlotLinesX(receivedData.plotLinesX);
          }
        }, (error) => {
          console.log('error', error);
          chart.hideLoading();
        });
    }
  };

//  const setExtremesEvent = (e) => {
//    console.log('setExtremes', e);
//};

  //const maxTriesToPlotMarkers = 10;
  const markPointsOnChart = function (chart, points, attempt) {    
    //console.log('markPointsOnChart');
    let series = chart.get(DEFAULT_SERIES_NAME);
    if (points && points.length > 0) {
      //if (series.xData.indexOf(points[0].x) === -1) {
      //  console.log(series.xData);
      //  if (attempt === undefined) attempt = 1;
      //  if (attempt > maxTriesToPlotMarkers) return;
      //  setTimeout(() => markPointsOnChart(chart, points, attempt + 1), 1000);
      //  return;
      //}

      points.map(point => {
        let index = series.xData.indexOf(point.x);
        if (index > 0) {
          let p = series.points[index];
          if (p) {           
            let smb = point.isR ? 'rectangleUp' : 'rectangleDown';
            let clr = point.isR ? 'green' : 'purple';
            let label = {};
            if (point.title) {
              label.format = point.title;
              label.enabled = true;
            }

            p.update({
              marker: {
                symbol: smb,
                enabled: true,
                lineColor: clr,
                fillColor: clr,
                states: {
                  hover: {
                    enabled: false
                  }
                }
              },
              //name: point.title ?? undefined,
              dataLabels: label
            }, false);
          }
        }
      });      

      chart.redraw();
    };


      

  };

  const getOptions = () => {
    let commonOptions = ChartConsts.regularChartOptions;
    let newOptions = {
      chart: {
        events: {
          load: function (x) {
            if (props.onChartLoadEvent)
              props.onChartLoadEvent(this);

            setTimeout(() => {
              try {
                this.reflow();
              }
              catch { }
            }, 100);

            //if (props.defaultCellTimeXms) {
            //  let xTimeMs = props.defaultCellTimeXms;

            //  setChartExtremesX(this, xTimeMs, 0);
            //  //this is required as otherwise the whole series is selected when panning on x axis.
            //  //Because of an extra series. Maybe add the extra series later ??
            //  //setTimeout(() => updateChart(40, 0), 2000); 
            //  setTimeout(() => {
            //    try {
            //      setChartExtremesX(this, xTimeMs, 0)
            //    } catch { }
            //  }, 200);
            //}
          }
        },
      },
      xAxis: {
        maxRange: maxXAxisWidth.current + 1,
        tickPositioner: function () { return getChartTickPositions(this, props.defaultCellTimeXms) },        
        events: {
          //afterSetExtremes: afterSetExtremes
        },
      }
    };
    let common = mergeDeep(commonOptions, newOptions);

    if (props.specificChartOptions) {      
      return mergeDeep(common, props.specificChartOptions);
    }

    return common;
  }

  const getNavigatorOptions = () => {
    let commonOptions = ChartConsts.navigationChartOptions;
    let newOptions = {
      chart: {
        events: {
          load: function (x) {
            setTimeout(() => {
              try {
                this.reflow();
              }
              catch { }
            }, 100);

            if (props.defaultCellTimeXms) {
              let xTimeMs = props.defaultCellTimeXms;
              //console.log('commonNavigationChartOptions - load event fired ');
              setChartExtremesX(this, xTimeMs, 0);
              //this is required as otherwise the whole series is selected when panning on x axis.
              //Because of an extra series. Maybe add the extra series later ??
              //setTimeout(() => updateChart(40, 0), 2000); 
              //setTimeout(() => {
              //  try {
              //    setChartExtremesX(this, xTimeMs, 0)
              //  } catch { }
              //}, 200);
            }
          }
        },
      },
      xAxis: {
        visible: false,
        events: {
          afterSetExtremes: afterSetExtremes,
          //setExtremes: setExtremesEvent
        },
      },
    };

    let common =  mergeDeep(commonOptions, newOptions);

    if (props.specificNavigatorOptions) {
      return mergeDeep(common, props.specificNavigatorOptions);
    }

    return common;
  }

  return (
    <>
      <HighchartsReact
        ref={chartBigComponentRef}
        highcharts={Highcharts}
        constructorType={'chart'}
        options={getOptions()}
      />

      <HighchartsReact
        ref={chartComponentRef}
        highcharts={Highcharts}
        constructorType={(props.isStockChart === undefined || props.isStockChart) ? 'stockChart' : 'chart'}
        options={getNavigatorOptions()}
      />

    </>
  )


});
export const OnlineHighchartsAsync = React.memo(OnlineHighchartsAsyncForwardRef);