import React, { useState, useEffect, useRef } from 'react';
import api from "../../../services/api";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CommonHighchartsAsync } from '../Ecg/CommonHighchartsAsync'


export function SIChart({ displayName, data, chartName, requestUrl }) {
  const { t } = useTranslation();
  const MAX_SI = 2500;
  const MIN_SI = 30;

  const chartRef = useRef(null);
  

  const chartOptions = {
    title: {
      text: t('recordView.processedData.charts.siChart.header')
    },
    xAxis: {
      maxRange: undefined,
      tickPositioner: undefined,
      tickInterval: undefined,
      minorTicks: false,
      minorTickInterval: undefined
    }, 
    yAxis: {
      //min: 30,
      //max: 3000,
      title: { 
        text: t('recordView.processedData.charts.siChart.axisName')
      },
      labels: {
        format: '{value}'
      },
      opposite: false
    },
    plotOptions: {
      series: {
        marker: {          
          enabled: true,
          enabledThreshold: 3
        }
      }
    }
  };

  const navigatorOptions = {
    xAxis: {
      maxRange: 5 * 3600000,
      range: 1 * 3600000
    }
  };

  useEffect(() => {
   // console.log(displayName);

    if (data) {

      //let min = Math.min(...data.map(x => x[1]));
      //let max = data.reduce((acc, x) => Math.max(acc, x[1]), -Infinity);
      //if (max < MAX_SI)
      //  max = MAX_SI;

      //chartRef.current.setupYAxis(yAxisSettings);
      chartRef.current.setInitialData([]);
      chartRef.current.setInitialDataForLongSeries(data.longRecord, requestUrl);
      //chartRef.current.setChartExtremesX(data[data.length - 1][0], 0);
      chartRef.current.setChartExtremesYMinMax(MIN_SI, MAX_SI);
    }
  }, []);


 

  return (
    <>
      <h3>{displayName}</h3>
      <CommonHighchartsAsync ref={chartRef} seriesName={'SI'} chartOptions={chartOptions} navigatorOptions={navigatorOptions} />            
    </>
  );
}

