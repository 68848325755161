import React, { useState, useEffect } from 'react';

//import { useParams } from 'react-router-dom';

import DeviceList from '../../components/Metrology/DeviceList'

import MainBlockHeader from '../MainBlockHeader'
import MainBlockContainer from '../MainBlockContainer';
import { useTranslation } from 'react-i18next';

export default function Devices() {  
  //let { userUid } = useParams();
  const { t } = useTranslation();

  return (
    <>              
      <MainBlockHeader header={t('metrology.deviceList.pageTitle')} >
      </MainBlockHeader>

      <MainBlockContainer>        
        <DeviceList getDeviceListUrl='/metrology/devices' baseUrl='/metrology/devices'  />
      </MainBlockContainer>
    </>
  );  
}

