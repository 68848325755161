import { React, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Form } from 'react-bootstrap';

import { dateFormatter } from '../../utils/dateFormatter'
import Loading from '../Shared/Loading'
import api from "../../services/api";

import fileDownloadShow from "../../services/fileDownloadShow";
import * as CommonIcons from '../CommonIcons';

import TableFull from '../Shared/CommonTable/TableFull';

import styles from './DeviceList.module.scss'

export default function DevicesList({ getDeviceListUrl, baseUrl, allowAdd, allowEdit }) {
  const { t } = useTranslation(); 
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  let navigate = useNavigate();

  const [globalFilter, setGlobalFilter] = useState("");

  const downloadOrShowFile = (e, target, row) => {
    e.stopPropagation();
    fileDownloadShow(`${getDeviceListUrl}/${row.uid}/protocol/latest/download`);
  };

  useEffect(() => {   
    loadDeviceList();
  }, []);

  const loadDeviceList = function () {   
    api
      .get(getDeviceListUrl)
      .then((response) => {
        setData(response.data.items);
        setLoading(false);
      });
  };

  const columns = [
    //{
    //  header: t('metrology.deviceList.deviceType'),
    //  accessorKey: "deviceName",
    //  enableGlobalFilter: false,
    //  enableColumnFilter: false,
    //},
    {
      header: t('metrology.deviceList.modelName'),
      accessorKey: "modelName",
      enableGlobalFilter: false,
      enableColumnFilter: false,
      sortUndefined: 'last'
    },
    {
      header: t('metrology.deviceList.bluetoothAddress'),
      accessorKey: "bluetoothAddress",
      enableColumnFilter: true,
      enableGlobalFilter: true,
    },
    {
      header: t('metrology.deviceList.serialNumber'),
      accessorKey: "serialNumber",
      enableGlobalFilter: true,
      enableColumnFilter: true,
    },
    {
      id: 'lastProtocolDate-date',
      header: t('metrology.deviceList.lastRecordDate'),
      className: styles.dateCell,
      maxWidth: '3rem',
      accessorKey: "lastProtocolDate",
      enableGlobalFilter: false,
      enableColumnFilter: false,
      cell: info => <>{dateFormatter(info.getValue())}</>,
    },
    {
      header: t('metrology.deviceList.numberOfProtocols'),
      accessorKey: "numberOfProtocols",
      enableGlobalFilter: false,
      enableColumnFilter: false,
    },
    {
      id: 'lastProtocolDate-download',
      header: t('devices.table.header.download'),
      maxWidth: '3rem',
      className: `${styles.center}`,
      enableGlobalFilter: false,
      enableColumnFilter: false,
      accessorKey: "lastProtocolDate",
      cell: info => {
        return (
          <>
            {info.getValue() && <button className={`${styles.btn} ${styles.editIcon}`} onClick={(e, target) => downloadOrShowFile(e, target, info.row.original)}><CommonIcons.DownloadButton /></button>}
          </>
        )
      }
    }
  ];
  if (allowEdit) 
    columns.push({
      id: 'edit-buttons',
      header: '',
      accessorKey: "uid",
      className: `${styles.center}`,
      maxWidth: '3rem',
      hideOnMobile: true,
      enableGlobalFilter: false,
      enableColumnFilter: false,
      cell: info => {
        return (          
          <>
            {info.getValue() && <button className={`${styles.btn}`} onClick={(e, target) => onEditClick(e, info.row.original)}><CommonIcons.PensilEdit /></button>}
          </>
        )
      }
    });

  const onEditClick = (e, row) => {
    e.stopPropagation();
    navigate(`${baseUrl}/${row.uid}/edit`);
  };

  const onRecordClickHandler = (record) => {    
    navigate(`${baseUrl}/${record.uid}/records/`);
  };

  if (loading)
    return <Loading />;

  return (
    <>
      {allowAdd && 
        <Row className={styles.buttonRow}>
          <Col xxl={12} xl={12} lg={12} md={12} className="d-flex justify-content-end">
            <Link className={`btn btn-primary mt-2 mb-2`} variant="primary" to={`${baseUrl}/add`}>{t('metrology.edit.addNew')}</Link>
          </Col>
        </Row>
      }      
      <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
        <Form.Label column sm="1" className="fw-bold">
          {t('metrology.deviceList.searchPrompt')}
        </Form.Label>
        <Col sm="4">
          <Form.Control type="text" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} className={styles.input} placeholder={t('metrology.deviceList.searchPlaceholder')} />
        </Col>
      </Form.Group>

      

      

      <TableFull styles={styles}
        data={data}
        columns={columns}
        onRowClick={onRecordClickHandler}
        allowColumnHeaderSort={true}
        allowGlobalFilter={true}
        globalFilterText={globalFilter}
        mainTableClassName={allowEdit ? styles.table : styles.tableNoEdit} />
    </>
  )
}