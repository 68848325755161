import { React } from 'react';
//import { Link } from 'react-router-dom';
//import { useTranslation } from 'react-i18next';
//import { useRecoilValue } from 'recoil';
//import { selectedUserState } from '../states/selectedUserState';
//import * as Consts from '../consts/userPermissions';
//import CurrentUserService from '../services/currentUser.service';
import { MenuListSettings } from './Components/MenuListSettings';

export { SideMenuSettings };

function SideMenuSettings({ closeOffcanvas }) { 
  //const { t } = useTranslation();

  const closeOffcanvasAction = () => {
    if (closeOffcanvas) {
      let closeBtn = document.getElementById('closeOffcanvasButton'); //dirty hack to find a button and close to avoid integrating bootstrap.js
      if (closeBtn) closeBtn.click();
    }
  };  

  return (
    <>
      <MenuListSettings closeOffcanvasAction={closeOffcanvasAction} />
    </>
  )
}

