import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Accordion, Form } from 'react-bootstrap';
import CheckBox from '../CheckBox'

export default function OneUserLoginPermissions({ userLoginUid, permissions, isDisabled, onPermissionUpdate, isGroup }) {
  const { t } = useTranslation();
  const [perms, setPerms] = useState(permissions);
  const [blockRemovalOfFullControl] = useState(permissions.isGroupOwner || permissions.isUserOwner);
  const [radioValue, setRadioValue] = useState(3);
  const [radioDisabled] = useState(permissions.isGroupOwner || permissions.isUserOwner);

  const [editingIsDisabled] = useState(isDisabled || permissions.isGroupOwner || permissions.isUserOwner);

  const accordionRef = useRef();

  useEffect(() => {
    console.log(perms);
    if (onPermissionUpdate) onPermissionUpdate(perms);
    checkSelectionOfradioValue();
  }, [perms]);

  const updatePerm = (name, value, dependantEditToOff, dependantViewToOn) => {
    let changes = { [name]: value };
    if (dependantEditToOff !== undefined && !value)
      changes[dependantEditToOff] = false;

    if (dependantViewToOn !== undefined && value)
      changes[dependantViewToOn] = true;

    setPerms({ ...perms, ...changes });
    setRadioValue(3);
  }

  const updateViewPerm = (name, value, connectedEditPermission) => {
    updatePerm(name, value, connectedEditPermission);
  };

  const updateEditPerm = (name, value, connectedViewPermission) => {
    updatePerm(name, value, null, connectedViewPermission);
  };

  const viewPermissionsOnly = {
    isGroupOwner: blockRemovalOfFullControl, isUserOwner: blockRemovalOfFullControl,
    canViewUser: true, canEditUser: false,
    canViewData: true, canEditData: false,
    canViewFiles: true, canEditFiles: false,
    canViewDiary: true, canEditDiary: false,
    canViewConsultations: true, canRequestConsultations: false,
    canViewUserDevices: true
  };

  const setViewerPermissionsOnly = () => {  
    setRadioValue(1);
    setPerms({ ...perms, ...viewPermissionsOnly });
  }

  const editPermissionsOnly = {
    isGroupOwner: blockRemovalOfFullControl, isUserOwner: blockRemovalOfFullControl,
    canViewUser: true, canEditUser: true,
    canViewData: true, canEditData: true,
    canViewFiles: true, canEditFiles: true,
    canViewDiary: true, canEditDiary: true,
    canViewConsultations: true, canRequestConsultations: false,
  };

  const setEditPermissionsOnly = () => {   
    setRadioValue(2);
    setPerms({ ...perms, ...editPermissionsOnly });      
    //btnRef.current.collapse();
  }

  const setCustomPermissionsOnly = () => {
    setRadioValue(3);
    hackAccordionOpen();
  }

  const checkSelectionOfradioValue = () =>{
    //setRadioValue(1);
  }

  const hackAccordionOpen = () => {
    if (!accordionRef.current.getElementsByClassName('accordion-collapse')[0].classList.contains('show'))
      accordionRef.current.getElementsByClassName('accordion-button')[0].click();
  }  

  const PermissionColumnHeader = ({ children }) => <Col sm={12} md={6} lg={4}>{children}</Col>;

  const PermissionColumnTick = ({ wide, children }) => {
    let isWide = wide !== undefined;

    return(
      <Col sm={isWide ? 12 : 6} md={isWide ? 6 : 3} lg={isWide ? 8 : 2}>{children}</Col>
    )
  };

  const ViewEditPermissionRow = ({ id, displayName, viewPermission, editPermission, viewPermissionName, editPermissionName, isDisabled }) => {
    return (
      <Row>
        <PermissionColumnHeader>{displayName}:</PermissionColumnHeader>
        <PermissionColumnTick><CheckBox id={`{id}-0-${userLoginUid}`} onChange={(value) => { updateViewPerm(viewPermission, value, editPermission); }} name={viewPermissionName} value={perms[viewPermission]} isDisabled={isDisabled} viewIcon /></PermissionColumnTick>
        {editPermission && <PermissionColumnTick><CheckBox id={`{id}-1-${userLoginUid}`} onChange={(value) => { updateEditPerm(editPermission, value, viewPermission); }} name={editPermissionName} value={perms[editPermission]} isDisabled={isDisabled} editIcon /></PermissionColumnTick>}
      </Row>
    )
  }

  return (
    <>
      <Row>
        <Col className="mt-3 mb-2 fw-bold">{t('settings.userPermissions.edit.quickSettings')}:</Col>
      </Row>
      <div key={`inline-radio`} className="mb-3">
        <Form.Check
          label={t('settings.userPermissions.edit.viewer')}
          name={`group-${userLoginUid}`}
          type="radio"
          id={`radio-${userLoginUid}-1`}
          onChange={setViewerPermissionsOnly}
          checked={radioValue === 1}
          disabled={radioDisabled}
        />
        <Form.Check            
          label={t('settings.userPermissions.edit.editor')}
          name={`group-${userLoginUid}`}
          type="radio"
          id={`radio-${userLoginUid}-2`}
          onChange={setEditPermissionsOnly}          
          checked={radioValue === 2}
          disabled={radioDisabled}
        />
        <Form.Check                        
          label={t('settings.userPermissions.edit.custom')}
          name={`group-${userLoginUid}`}
          type="radio"
          id={`radio-${userLoginUid}-3`}
          onChange={setCustomPermissionsOnly}
          checked={radioValue === 3}
          disabled={radioDisabled}
        />
      </div>

      <Accordion ref={accordionRef}>
        <Accordion.Item eventKey="0">
          <Accordion.Header>{t('settings.userPermissions.edit.detailed')}:</Accordion.Header>
          <Accordion.Body>
            <ViewEditPermissionRow id={1}
              displayName={t('settings.userPermissions.edit.profile')}
              viewPermission='canViewUser' editPermission='canEditUser'
              viewPermissionName={t('settings.userPermissions.canViewUser')} editPermissionName={t('settings.userPermissions.canEditUser')}
              isDisabled={editingIsDisabled} />

            <ViewEditPermissionRow id={2}
              displayName={t('settings.userPermissions.edit.data')}
              viewPermission='canViewData' editPermission='canEditData'
              viewPermissionName={t('settings.userPermissions.canViewData')} editPermissionName={t('settings.userPermissions.canEditData')}
              isDisabled={editingIsDisabled} />

            <ViewEditPermissionRow id={3}
              displayName={t('settings.userPermissions.edit.files')}
              viewPermission='canViewFiles' editPermission='canEditFiles'
              viewPermissionName={t('settings.userPermissions.canViewFiles')} editPermissionName={t('settings.userPermissions.canEditFiles')}
              isDisabled={editingIsDisabled} />

            <ViewEditPermissionRow id={4}
              displayName={t('settings.userPermissions.edit.diary')}
              viewPermission='canViewDiary' editPermission='canEditDiary'
              viewPermissionName={t('settings.userPermissions.canViewDiary')} editPermissionName={t('settings.userPermissions.canEditDiary')}
              isDisabled={editingIsDisabled} />

            <ViewEditPermissionRow id={5}
              displayName={t('settings.userPermissions.edit.devices')}
              viewPermission='canViewUserDevices' 
              viewPermissionName={t('settings.userPermissions.canViewUserDevices')}
              isDisabled={editingIsDisabled} />
              <hr/>
            <Row>
              <PermissionColumnHeader>{t('settings.userPermissions.edit.consultations.title')}:</PermissionColumnHeader>
              <PermissionColumnTick><CheckBox id={`7-${userLoginUid}`} onChange={(value) => { updatePerm('canViewConsultations', value); }} name={t('settings.userPermissions.edit.consultations.view')} value={perms.canViewConsultations} isDisabled={editingIsDisabled} /></PermissionColumnTick>
            </Row>
            <Row>
              <PermissionColumnHeader></PermissionColumnHeader>
              <PermissionColumnTick><CheckBox id={`8-${userLoginUid}`} onChange={(value) => { updatePerm('canRequestConsultations', value); }} name={t('settings.userPermissions.edit.consultations.request')} value={perms.canRequestConsultations} isDisabled={editingIsDisabled} /></PermissionColumnTick>
            </Row>
            <hr />
            {isGroup && <Row>
              <PermissionColumnHeader>{t('settings.userPermissions.edit.fullControl')}:</PermissionColumnHeader>
              <PermissionColumnTick><CheckBox id={`9-${userLoginUid}`} onChange={(value) => { updatePerm('isGroupOwner', value); }} name={t('settings.userPermissions.edit.ownerControl')} value={perms.isGroupOwner} isDisabled={editingIsDisabled || blockRemovalOfFullControl} /></PermissionColumnTick>
            </Row>
            }

            {!isGroup && <Row>
              <PermissionColumnHeader>{t('settings.userPermissions.edit.fullControl')}:</PermissionColumnHeader>
              <PermissionColumnTick wide><CheckBox id={`10-${userLoginUid}`} onChange={(value) => { updatePerm('isUserOwner', value); }} name={t('settings.userPermissions.edit.ownerControl')} value={perms.isUserOwner} isDisabled={editingIsDisabled || blockRemovalOfFullControl} allowWrap/></PermissionColumnTick>
            </Row>
              }
          </Accordion.Body>
          </Accordion.Item>
      </Accordion>

    </>
  )
}