import React from 'react';

import MainBlockHeader from '../MainBlockHeader'
import MainBlockContainer from '../MainBlockContainer';
import { useTranslation } from 'react-i18next';

export default function Privacy() {
  //let { userUid } = useParams();
  const { t } = useTranslation();

  return (
    <>
      <MainBlockHeader header='Privacy' >
      </MainBlockHeader>

      <MainBlockContainer>
        <div>
          <p>
            Effective Date: 2023-01-01
          </p>
          <p>
            <b>Our Commitment to Privacy</b>
          </p>
          <p>Welcome to Delfihealth Ltd ("we," "us," or "our"). We are committed to protecting the privacy and security of your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our website/application/service (the "Service"). Please read this Privacy Policy carefully. If you do not agree with the terms of this Privacy Policy, please do not access the Service.</p>

          <p>
            <b>Information We Collect</b>
          </p>
          <p>We may collect and process the following types of personal information from you:</p>

          <ul>
            <ol><strong>Name and Last Name</strong>: We collect your name and last name to personalize your experience with our Service.</ol>
            <ol><strong>Date of Birth (DOB)</strong>: We collect your date of birth to ensure that you meet the age requirements to use our Service or to provide age-related services.</ol>
            <ol><strong>Email Address</strong>: We collect your email address to create and manage your account, to send you important information about your account, and to send you marketing emails.</ol>
            <ol><strong>Health Data</strong>: We may collect various health-related data as provided by you. This may include medical history, wellness information, and other health-related details. This data is collected to offer personalized services, recommendations, or to provide specific functionalities related to health and wellness.</ol>            
          </ul>
          <p>
            <b>Location Data and Bluetooth Functionality</b>
          </p>

          <p>To ensure the optimal performance of our Google application's Bluetooth features, we require access to your device's location services. Please note that our application uses location data solely for the purpose of enabling and enhancing Bluetooth connectivity; it is not used for tracking your movements or for any other purpose unrelated to the functionality of the Bluetooth features. We are committed to respecting your privacy and handling all location data with the highest standards of security and confidentiality. By enabling location services for our application, you agree to allow us to access your location data under these specific conditions.</p>
          <p>
            <b>How We Use Your Information</b>
          </p>
          <p>We may use the information we collect from you for various purposes, including:</p>

          <ul>
            <li>Providing and maintaining the Service.</li>
            <li>Personalizing your experience and improving the Service.</li>
            <li>Responding to your requests, comments, or questions.</li>
            <li>Providing you with relevant health-related information, insights, and recommendations based on the data you provide.</li>
            <li>Fulfilling legal and regulatory obligations.</li >
            <li>Protecting our rights and interests and those of other users.</li >
          </ul>

          <p>
            <b>Data Sharing and Disclosure</b>
          </p>
          <p>We do not share your personal information, including your name, last name, date of birth, and health data, with third parties without your explicit consent. However, we may share your information under the following circumstances:</p>

          <ul>
            <li>With your explicit consent.</li>
            <li>To comply with legal obligations or respond to lawful requests.</li>
            <li>To protect and defend our rights, property, or safety, as well as those of our users or others.</li>
            <li>In connection with the sale, merger, acquisition, or any other change of control of our company.</li>
          </ul>

          <p><b>Your Rights</b></p>

          <p>You have the right to access, correct, update, or delete your personal information. You can also request to restrict the processing of your data or object to certain processing activities. To exercise any of these rights, please contact us at support@delfihealth.com. </p>

          <p><b>Data Retention</b></p>

          <p>We will retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law. </p>

          <p><b>Security</b></p>

          <p>We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no data transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security. </p>

          <p><b>Changes to this Privacy Policy</b></p>

          <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the effective date. </p>

          <p><b>Contact Us</b></p>

          <p>If you have any questions about this Privacy Policy, please contact us at support@delfihealth.com.</p>

          <p>By using our Service, you acknowledge that you have read and understood this Privacy Policy and consent to the practices described herein.</p>

          <p> Effective Date: 2023-01-01</p>         

        </div>
      </MainBlockContainer>
    </>
  );
}

