import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';

import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { dateFormatter } from '../../../utils/dateFormatter'
import {recordLengthFormatter} from '../../../utils/recordLengthFormatter'

import styles from './UserViewOneRecord.module.scss';

export const UserViewOneRecordInfo = forwardRef((props, ref) => {
  const [processedData, setProcessedData] = useState(null);

  useImperativeHandle(ref, () => {
    return {
      setProcessedData(data) {
        setProcessedData(data);
      },
    };
  }, []);

  const { t } = useTranslation();

  const [description] = useState(props.description);

  const RecordDescriptionLine = ({ header, text, postFix }) => {
    return <Col lg><b>{header}:</b> {text}{postFix}</Col>
  }

  return (
    <>
      <Row className={`pt-3 mt-3`}>
        <Col>
          {/*<Button variant="link" onClick={showAllRecordsClick}>{t('recordList.backToAllRecords')}</Button>*/}
        </Col>
      </Row>
      <Row>
        <RecordDescriptionLine header={t('recordView.details.branch')} text={description?.branch} />
        <RecordDescriptionLine header={t('recordView.details.description')} text={description?.description} />
        <RecordDescriptionLine header={t('recordView.details.recordPlace')} text={description?.place} />
      </Row>
      <Row className={'mt-3'}>
        <RecordDescriptionLine header={t('recordView.details.recordType')} text={description?.recordTypeName} />
        <RecordDescriptionLine header={t('recordView.details.recordName')} text={description?.recordName} />
        <Col lg></Col>
      </Row>

      <Row className={`${styles.bt} pt-3 mt-3`}>
        <RecordDescriptionLine header={t('recordView.details.deviceSn')} text={description?.serialNumber} />
        <RecordDescriptionLine header={t('recordView.details.date')} text={dateFormatter(description?.date)} />
        {!processedData &&
          <RecordDescriptionLine header={t('recordView.details.length')} text={recordLengthFormatter(description?.recordLengthSeconds)} />}
        {processedData &&
          <>
            <RecordDescriptionLine header={t('recordView.details.length')} text={recordLengthFormatter(processedData?.recordLength)} />
            <RecordDescriptionLine header={t('recordView.processedData.batteryPercentage')} text={processedData?.batteryPercentage} postFix='%' />
          </>
        }
      </Row>

    </>
  );
});
