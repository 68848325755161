import React, { useState, useEffect, useRef } from 'react';

import api from "../../../services/api";
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import { Button } from 'react-bootstrap'
import TokenService from "../../../services/token.service";

export function EcgWebSocketReceiver({ realtimeDataUrl, receiveNewDataCallback, finalizeCallback }) {

  const [webSocketTryAgain, setWebSocketTryAgain] = useState(false);
  const webSocket = useRef(null);

  useEffect(() => {    
    if (realtimeDataUrl) {
      webSocket.current = createWebSocketConnection(realtimeDataUrl);            
    };

    return () => {
      if (webSocket.current) {
        webSocket.current.close();
        console.log("close ws");
      }
    };

  }, [webSocketTryAgain]);


  const createWebSocketConnection = function (webSocketUrl) {
    console.log('creating webSocket');
    let url = new URL(webSocketUrl, window.location.href);
    url.protocol = 'wss';
    let authToken = TokenService.getLocalAccessToken();

    let ws = new WebSocket(url.href, [authToken]);
    ws.onopen = function () {
      sendConfirmationDataReceived(ws, 0);
    };

    ws.onmessage = function (event) {      
      const json = JSON.parse(event.data);
      try {
        //console.log(json?.multilineData?.processed);
        if ((json.event = "data")) {
          if (json.isLastMessage) {
            finalize();
          } else {
            if (receiveNewDataCallback) receiveNewDataCallback(json.multilineData);            
            sendConfirmationDataReceived(ws, json.msgId);
          }
        }
      } catch (err) {
        console.log(err);
      }
    };

    ws.onerror = function (event) {
      console.log(event);
    };

    ws.onclose = function (event) {
      console.log(event);
      if (event.code === 1008 && event.reason === 'Unauthorized') {
        api
          .post('/auth/ok')
          .then(() => {
            setWebSocketTryAgain(true);
          });
      }
    };

    return ws;
  };

  const sendConfirmationDataReceived = function (ws, msgId) {
    //console.log('Confirming ', msgId);
    ws.send(JSON.stringify({ msgId: msgId }));
  };

  const finalize = function () {
    if (finalizeCallback) finalizeCallback();
  };

  return (
    <>
     
    </>
  )
}