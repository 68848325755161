import { Form, Col, Row, Button } from 'react-bootstrap';
import baseStyles from './CommonForm.module.scss'


export const FormEditLine = ({ styles, displayName, children, xxlWide }) => {
  if (!styles) styles = baseStyles;
  let xxlNameSize = (xxlWide !== undefined) ? "6" : "3";
  let xxlValueSize = (xxlWide !== undefined) ? "6" : "9";

  return (
    <Col className={styles.editCell}>
      <Form.Group as={Row}>
        <Form.Label column xl="12" xxl={xxlNameSize} className={styles.header}>{displayName}</Form.Label>
        <Col xl="12" xxl={xxlValueSize} className="align-self-center">
          {children}
        </Col>
      </Form.Group>
    </Col>
  )
}

export const SaveButtonSeparateRow = ({ styles, onClick, children }) => {
  if (!styles) styles = baseStyles;

  return (
    <SeparateRowForButtonOnly styles={styles}>
      <Button onClick={onClick} className={styles.wideBtn}>{children}</Button>
    </SeparateRowForButtonOnly>    
  )
};

export const SeparateRowForButtonOnly = ({ styles, children }) => {
  if (!styles) styles = baseStyles;

  return (
    <Row className={styles.buttonRow}>
      <Col className="d-flex justify-content-end">
        {children}
      </Col>
    </Row>
  )
};