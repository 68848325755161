import React, { useState, useEffect } from 'react';
import api from "../../../services/api";


const RecordsListListener = ({ getRecordListUrl, onNewDataReceived }) => {

  useEffect(() => {    
    const askServerForUpdates = () => {
      api
        .get(`${getRecordListUrl}/updates`)
        .then((response) => {                    
          if (response.data?.items && response.data.items.length > 0 && onNewDataReceived) onNewDataReceived(response.data.items);
        }, (error) => {
          if (timer) clearInterval(timer);
        });
    };


    const timer = setInterval(() => {
      askServerForUpdates();
    }, 15000);    
    
    return () => {      
      if (timer) clearInterval(timer);
    };
  }, [getRecordListUrl]);

  return (
    <></>
  );
};

export default RecordsListListener;

//import * as signalR from '@microsoft/signalr';
//
//const RecordsListListener = ({ getRecordListUrl }) => { 
//  const [number, setNumber] = useState(null);
//  //let timer = null;

//  useEffect(() => {
//    // Create connection
//    const connection = new signalR.HubConnectionBuilder()
//      .withUrl(`/randomNumberHub`) // Backend API URL
//      .withAutomaticReconnect()
//      .build();

//    let timer;
//    // Start connection and listen for random number updates
//    connection.start().then(() => {
//      console.log("SignalR Connected.");

//      connection.on("ReceiveRandomNumber", (number) => {
//        setNumber(number);
//        console.log(number);
//      });

//      // Send request to SendMessage SignalR every 15 seconds
//      timer = setInterval(() => {
//        connection.invoke("SendMessage", "Hello from timer");
//      }, 15000);

//    }).catch(error => console.error("Connection failed: ", error));

//    // Clean up the connection and timer when the component is unmounted
//    return () => {
//      connection.stop();
//      if (timer) clearInterval(timer);
//    };
//  }, []);

//  return (
//    <div>
//      {number !== null ? <p>{JSON.stringify(number)}</p> : <p>Waiting for number...</p>}
//    </div>
//  );
//};

//export default RecordsListListener;