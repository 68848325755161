import { React, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import fileDownloadNamed from "../../services/fileDownloadNamed";
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import { dateFormatter } from '../../utils/dateFormatter'
import Loading from '../Shared/Loading'
import api from "../../services/api";

import DeviceInfo from './Components/DeviceInfo';
import DeviceHeaderInfo from './Components/DeviceHeaderInfo';

import TableFull from '../Shared/CommonTable/TableFull';

import styles from './DeviceItemList.module.scss'

export default function DeviceItemList({ getDeviceItemsBaseUrl, uiBaseUrl }) {
  const { t } = useTranslation();
  const [data, setData] = useState();
  const [record, setRecord] = useState();
  const [device, setDevice] = useState();

  const [loading, setLoading] = useState(true);
  let navigate = useNavigate();

  useEffect(() => {
    loadItemsList();
  }, []);

  const loadItemsList = function () {
    api
      .get(getDeviceItemsBaseUrl)
      .then((response) => {
        setData(response.data.items);
        setRecord(response.data.record);
        setDevice(response.data.device);
        setLoading(false);
      });
  };

  const columns = useMemo(
    () =>
      [
        {
          header: t('metrology.itemList.date'),
          accessorKey: "dateCreatedUtc",
          enableColumnFilter: false,
          cell: props => <>{dateFormatter(props.getValue())}</>,
        },
        {
          header: t('metrology.itemList.recordType'),
          accessorKey: "metrologyRecordTypeName",
          enableColumnFilter: false,

        },
        {
          header: t('metrology.itemList.sectionName'),
          accessorKey: "metrologySectionName",
          enableColumnFilter: false,
        },
        {
          header: t('metrology.itemList.operationType'),
          accessorKey: "metrologyOperationName",
          enableColumnFilter: false,
        }
      ],
    [t]
  ); 

  const onRecordClickHandler = (record) => {
    if (record.metrologyRecord === "protocol")
      fileDownloadNamed(`${getDeviceItemsBaseUrl}/${record.uid}/protocol`, 'pdf');
    else
      navigate(`${uiBaseUrl}/${record.uid}/record`);
  };

  if (loading)
    return <Loading />;

  return (
    <>     
      <DeviceInfo device={device} />

      <Row className="mt-4">      
      </Row>

      <DeviceHeaderInfo record={record} />

      <Row className="h4 mt-4">
        <Col>{t('metrology.itemList.itemList')}</Col>
      </Row>

      <TableFull styles={styles} data={data} columns={columns} onRowClick={onRecordClickHandler} />
    </>
  )
}