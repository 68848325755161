import { Link, useLocation } from 'react-router-dom';
import styles from '../SideMenu.module.scss'

export { MenuList };

function MenuList({ itemsList, closeOffcanvasAction }) {
  const location = useLocation();
  const path = location.pathname;

  const MenuLine = (props) => {
    return (
      <li className={`${styles.item} ${(!props.isActive && path.endsWith(props.link)) || (props.isActive && props.isActive()) ? `${styles.active}` : ""} ${props.nextLevel ? `${styles.nextLevel}` : ""}`}>
        <Link to={props.link} className={`nav-link ${props.isDisabled ? "disabled" : ""}`} aria-current="page" onClick={closeOffcanvasAction}>
          {props.icon}
          {props.text}
        </Link>
      </li>)
  };

  return (
    <ul>
      {itemsList.filter(x => x.hasPermission === undefined || x.hasPermission).map((item, ind) => (
        <MenuLine key={ind} link={item.link} text={item.text} icon={item.icon} isActive={item.isActive} isDisabled={item.isDisabled} />
      ))}
    </ul>
  );

}