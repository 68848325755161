import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import api from "../../../services/api";
import Loading from '../Loading'
import { dateOnlyFormatter } from '../../../utils/dateFormatter'

import fileDownloadShow from "../../../services/fileDownloadShow";

import styles from './UserDocuments.module.scss';
import * as CommonIcons from '../../CommonIcons';

import TableFull from '../CommonTable/TableFull';

export default function UserDocuments({ documentsBaseUrl, addNewDocumentUrl }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);  
  const [data, setData] = useState([]);  
  
  const columns = useMemo(
    () => [
      {

        header: t('docs.table.header.uploadDate'),
        className: styles.dateCell,
        maxWidth: '3rem',
        accessorKey: "uploadDate",
        cell: info => {
          return (
            <>
              {dateOnlyFormatter(info.getValue())}
            </>
          )
        }

      },
      {
        header: t('docs.table.header.fileDate'),
        className: styles.dateCell,
        maxWidth: '3rem',
        accessorKey: "fileDate",
        cell: info => {
          return (
            <>
              {dateOnlyFormatter(info.getValue())}
            </>
          )
        }

      },
      {
        header: t('docs.table.header.type'),
        accessorKey: "typeName",
      },
      {
        header: t('docs.table.header.description'),
        accessorKey: "description",
        allowWrap: true
      },
      {
        header: t('docs.table.header.download'),
        maxWidth: '3rem',
        className: `${styles.center}`,
        cell: info => {
          return (
            <>
              <button className={styles.btn} onClick={() => fileDownloadShow(`${documentsBaseUrl}/${info.row.original.uid}/download`)}><CommonIcons.DownloadButton /></button>
            </>
          )
        }
      }

    ],
    [t]
  );

  const loadRecords = () => {
    api
      .get(documentsBaseUrl)
      .then((response) => {        
        setData(response.data.files);       

        setLoading(false);
      });
  };

  useEffect(() => {
    loadRecords();
  }, [documentsBaseUrl]);


  if (loading)
    return <Loading />;

  return (
    <>

      {addNewDocumentUrl && (
        <div className={`${styles.addNewBtnContainer}`}>
          <Link className={`btn btn-primary ${styles.addNewBtn}`} to={`${addNewDocumentUrl}`}>{t('docs.addNew')} </Link>        
        </div>
      )}

      <TableFull styles={styles} data={data} columns={columns} />      
    </>
  );
}
