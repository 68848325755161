import { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import styles from '../SideMenu.module.scss'

import * as Icons from '../SideMenuIcons';
import { useTranslation } from 'react-i18next';
import { MenuList } from './MenuList';

export { MenuListAdmin };
function MenuListAdmin({ closeOffcanvasAction }) {
  
  const location = useLocation();
  const path = location.pathname;
  const { t } = useTranslation();

  useEffect(() => {
  });


  const expertLinkList = [    
    {
      link: `/admin/user-logins`,
      text: t('sidebar.admin.userList'),
      icon: <Icons.TwoPeople />,
      isActive: function () { return path.includes(this.link); },
      hasPermission: true
    },
    {
      link: `/admin/device-list`,
      text: t('sidebar.admin.deviceList'),
      icon: <Icons.ScreenStarMobile />,
      isActive: function () { return path.includes(this.link); },
      hasPermission: true
    }
  ];

  return (
    <>
      <div className={styles.header}>
        <Icons.Shield />
        {t('sidebar.admin.header')}
      </div>
      <MenuList itemsList={expertLinkList} closeOffcanvasAction={closeOffcanvasAction} />
    </>

  );

}