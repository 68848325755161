import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import UserDiary from '../../components/Shared/Diary/UserDiary'

import MainBlockContainer from '../MainBlockContainer';
import MainBlockHeader from '../MainBlockHeader'

import { useRecoilValue } from 'recoil';
import { selectedUserState } from '../../states/selectedUserState';
import UserPermissions from '../../services/user.permissions.service';

import { useTranslation } from 'react-i18next';

export default function Diary() {  
  let { userUid, recordUid } = useParams();
  const { t } = useTranslation();
  const user = useRecoilValue(selectedUserState);
  let permissions = new UserPermissions(user);

  //useEffect(() => {   
    
  //}, []);


  return (
    <>              
      <MainBlockHeader header={t('diary.header')} />

      <MainBlockContainer>
        <UserDiary apiDiaryBaseUrl={`/user/${userUid}/diary`} pageDiaryBaseUrl={permissions.hasDiaryEditPermission() ? `/user/${userUid}/diary` : undefined } />      
      </MainBlockContainer>
    </>
  );  
}

