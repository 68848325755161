import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';

import Highcharts from 'highcharts/highstock'
import AnnotationsFactory from "highcharts/modules/annotations";
import HighchartsReact from 'highcharts-react-official'
import { useTranslation } from 'react-i18next';
import mergeDeep from '../../../utils/mergeDeep';
import { highchartsLabelFormatterFromMs } from "../../../utils/dateFormatter";
import * as ChartConsts from '../../ChartSettings/ChartSettings'

// init the module
AnnotationsFactory(Highcharts);

Highcharts.SVGRenderer.prototype.symbols.rectangleDown = function (x, y, w, h) {
  let width = w / 2;
  let height = h * 3;

  return ['M', x + width / 4, y,
    'L', x + width / 4, y + height,
    'L', x + width * 3 / 4, y + height,
    'L', x + width * 3 / 4, y,
    'z'];
};
Highcharts.SVGRenderer.prototype.symbols.rectangleUp = function (x, y, w, h) {
  let width = w / 2;
  let height = - h * 3 + h;

  return ['M', x + width / 4, y + h,
    'L', x + width / 4, y + height,
    'L', x + width * 3 / 4, y + height,
    'L', x + width * 3 / 4, y + h,
    'z'];
};

const OnlineHighchartsForwardRef = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const chartComponentRef = useRef(null);
  const chartDataRef = useRef({});
  const viewModeRef = useRef(0);
  const currentChartSpeed = useRef(null);
  const previousOnlineExceededBorders = useRef(null);

  const currentTimeAdjustment = useRef(0);
  const latestReceivedTime = useRef(0);

  const TOTAL_CELLS_ON_Y = 30;

  const maxXAxisWidth = useRef(10000);
  const DEFAULT_SERIES_NAME = 'series-data';


  useImperativeHandle(ref, () => ({
    setInitialData(data, seriesSettings, markers) {
      chartDataRef.current[(seriesSettings && seriesSettings.seriesId) ?? DEFAULT_SERIES_NAME] = data;
      let chart = chartComponentRef.current.chart;
      let seriesId = (seriesSettings && seriesSettings.seriesId) ?? DEFAULT_SERIES_NAME;

      let newSeries =
      {
        id: seriesId,
        data: data,
        name: (seriesSettings && seriesSettings.name) ?? props.seriesName,
        color: (seriesSettings && seriesSettings.color) ?? 'blue',
        showInNavigator: true,
        yAxis: (seriesSettings && seriesSettings.yAxisId) ?? 0,
        zoneAxis: (seriesSettings && seriesSettings.zoneAxis) ?? undefined,
        zones: (seriesSettings && seriesSettings.zones) ?? undefined,
        type: (seriesSettings && seriesSettings.type) ?? undefined,
        lineWidth: 2
      };
      if (chart.get(seriesId))
        chart.get(seriesId).update(newSeries);
      else
        chart.addSeries(newSeries);

      if (markers) {
        console.log('drawing markers:', markers);
        // setTimeout(() => markPointsOnChart(chart, markers), 500);        
        markPointsOnChart(chart, markers);
      }
    },
    setDataViewMode(mode) {
      viewModeRef.current = mode;
      console.log('mode:', mode, '; maxXAxisWidth:', maxXAxisWidth.current);

      let chart = chartComponentRef.current.chart;

      if (mode == 0) { // accumulation mode
        setAccumulationMode();
        if (props.accumulationModeSetCallback)
          props.accumulationModeSetCallback(chart, currentTimeAdjustment.current);
      }
      if (mode == 1) { // refresh mode
        setRefreshMode();
        if (props.refreshModeSetCallback)
          props.refreshModeSetCallback(chart, currentTimeAdjustment.current);
      }
    },
    addNewDataToChart(data, seriesId) {
      addNewDataToChart(data, seriesId);
    },
    //getData() {
    //  return chartDataRef.current[DEFAULT_SERIES_NAME];
    //},
    getChart() {
      return chartComponentRef.current.chart;
    },
    setupYAxis(settings) {
      let chart = chartComponentRef.current.chart;

      settings.map((s, index) => {
        if (!s.id && index == 0)
          chart.yAxis[index].update(s);
        else {
          if (s.id && chart.get(s.id))
            chart.get(s.id).update(s);
          else
            chart.addAxis(s);
        }
      });
    },
    setChartExtremesX(cellTimeMs, preferedPointStart) {
      let chart = chartComponentRef.current.chart;

      setChartExtremesX(chart, cellTimeMs, preferedPointStart);

      if (viewModeRef.current == 1) //refresh
        clearDataOnChart();
    },
    setChartExtremesY(voltagePerCell) {
      let
        chart = chartComponentRef.current.chart,
        yAxis = chart.yAxis[0];

      yAxis.update({ minorTickInterval: voltagePerCell, tickInterval: voltagePerCell * 10 });
      yAxis.setExtremes(- TOTAL_CELLS_ON_Y * voltagePerCell, TOTAL_CELLS_ON_Y * voltagePerCell);
    },
    setChartExtremesYMinMax(min, max) {
      let
        chart = chartComponentRef.current.chart,
        yAxis = chart.yAxis[0];
      yAxis.setExtremes(min, max);
    },
    scrollToEnd() {
      setCurrentViewLimitMoveToEnd();
    },
    addAnnotation(newAnnotation) {
      chartComponentRef.current.chart.addAnnotation(newAnnotation);
    },
    setPlotLinesX(plotLinesX) {
      let chart = chartComponentRef.current.chart,
        xAxis = chart.xAxis[0];

      if (plotLinesX && plotLinesX.length > 0) {
        plotLinesX.map(line => xAxis.addPlotLine(line));
      };
    }
  }));

  useEffect(() => {
    //console.log('accumulation mode:', props.mode);    

  }, []);

  const markPointsOnChart = function (chart, points, attempt) {
    console.log('markPointsOnChart');
    if (!chart || !chart.get) return;

    let series = chart.get(DEFAULT_SERIES_NAME);
    if (points && points.length > 0) {
      points.map(point => {
        let index = series.xData.indexOf(point.x);
        if (index > 0) {
          let p = series.points[index];
          if (p) {
            let smb = point.isR ? 'rectangleUp' : 'rectangleDown';
            let clr = point.isR ? 'green' : 'purple';
            let label = {};
            if (point && point.title) {
              label.format = point.title;
              label.enabled = true;
            }

            p.update({
              marker: {
                symbol: smb,
                enabled: true,
                lineColor: clr,
                fillColor: clr,
                states: {
                  hover: {
                    enabled: false
                  }
                }
              },
              //name: point.title ?? undefined,
              dataLabels: label
            }, false);
          }
        }
      });

      chart.redraw();
    };
  };

  const setChartExtremesX = function (chart, cellTimeMs, preferedPointStart) {
    currentChartSpeed.current = cellTimeMs;

    let xAxis = chart.xAxis[0],
      pointStart = preferedPointStart ?? xAxis.min,
      visibleTimeOnXAxis = calcMaxXAxisVisibleTimeMs(chart, cellTimeMs),
      pointEnd = pointStart + visibleTimeOnXAxis;

    console.log('pointEnd', pointEnd);

    maxXAxisWidth.current = visibleTimeOnXAxis;

    if (xAxis.dataMax && pointEnd > xAxis.dataMax) {
      pointEnd = xAxis.dataMax;
      pointStart = pointEnd - visibleTimeOnXAxis;
      if (pointStart < xAxis.dataMin)
        pointStart = xAxis.dataMin;
    }

    if (!xAxis.dataMax && xAxis.userOptions && xAxis.userOptions.max)
      pointEnd = xAxis.userOptions.max;

    console.log('pointEnd after correction', pointEnd);

    //console.log('pointEnd - ', pointEnd);
    //console.log('min', xAxis.min, ' - ', xAxis.max);
    //console.log('dataMin', xAxis.dataMin, ' - ', xAxis.dataMax);
    //console.log('userOptions:', xAxis.userOptions, 'pointStart:', pointStart);

    if (xAxis.userOptions && xAxis.userOptions.softMin !== undefined && xAxis.userOptions.softMin < pointStart)
      pointStart = xAxis.userOptions.softMin;    

    if (xAxis.userOptions && xAxis.userOptions.softMax !== undefined && xAxis.userOptions.softMax > pointEnd)
      pointEnd = xAxis.userOptions.softMax;

    xAxis.setExtremes(pointStart, pointEnd);
    xAxis.update({ minorTickInterval: cellTimeMs, tickPositioner: function () { return getChartTickPositions(this, cellTimeMs) } });
    chart.reflow();
  };

  const getChartTickPositions = function (xAxis, cellTimeMs) {
    let positions = [],
      min = xAxis.min,
      max = xAxis.max,
      increment = cellTimeMs * 10,
      tick = Math.floor(min / increment) * increment;

    if (max !== null && min !== null) {
      for (tick; tick - increment <= max; tick += increment) {
        positions.push(tick);
      }
    }
    return positions;
  };

  let calcMaxXAxisVisibleTimeMs = function (chart, cellTimeMs) {
    let chartWidth = chart.xAxis[0].width; //chart.chartWidth
    let standardCellHeightInPx = chart.chartHeight / (TOTAL_CELLS_ON_Y * 2),
      visibleCellsX = chartWidth / standardCellHeightInPx,
      cellTime = cellTimeMs;

    return cellTime * visibleCellsX;
  }; 

  const clearDataOnChart = () => {
    //console.log('currentTimeAdjustment.current: ', currentTimeAdjustment.current);
    if (chartDataRef.current[DEFAULT_SERIES_NAME].length > 0) {
      //console.log(chartDataRef.current[DEFAULT_SERIES_NAME].slice(-1));
      //if (viewModeRef.current == 0) // now accumulation, but was continuous
      //  currentTimeAdjustment.current = currentTimeAdjustment.current + chartDataRef.current[DEFAULT_SERIES_NAME].slice(-1)[0][0] ?? 0;
      //if (viewModeRef.current == 1) // now continuous, but was accumulation
      //  currentTimeAdjustment.current = chartDataRef.current[DEFAULT_SERIES_NAME].slice(-1)[0][0] ?? 0;
      currentTimeAdjustment.current = latestReceivedTime.current;
    }
    //console.log('currentTimeAdjustment.current: ', currentTimeAdjustment.current);

    for (let keyName in chartDataRef.current) {
      chartDataRef.current[keyName] = [];
      let chart = chartComponentRef.current.chart;
      if (chart.get(keyName) && chart.xAxis[0]) {
        chart.get(keyName).setData(chartDataRef.current[keyName], true, false, false);
        chart.xAxis[0].setExtremes(0, maxXAxisWidth.current);
      }
    }    
  }

  const setAccumulationMode = () => {    
    clearDataOnChart();
  };

  const setRefreshMode = () => {
    clearDataOnChart();    
  };

  const checkDataLengthLessAllowedView = (series) => {
    if (props.defaultCellTimeXms || currentChartSpeed.current) {
      let speed = currentChartSpeed.current ?? props.defaultCellTimeXms;
      let maxPossibleLength = calcMaxXAxisVisibleTimeMs(chartComponentRef.current.chart, speed);
      let currentLength = series.xAxis.dataMax - series.xAxis.dataMin;

      //console.log('maxPossibleLength:', maxPossibleLength, '; currentLength: ', currentLength);

      return currentLength < maxPossibleLength;
    }
    return false;
  };

  const setCurrentViewLimitMoveToEnd = () => {  
    if (props.defaultCellTimeXms || currentChartSpeed.current) {
      let chart = chartComponentRef.current.chart,
        speed = currentChartSpeed.current ?? props.defaultCellTimeXms,
        xAxis = chart.xAxis[0];

      //console.log('setCurrentViewLimitMoveToEnd');
      //console.log(xAxis.dataMax);
      //console.log(calcMaxXAxisVisibleTimeMs(chart, speed));

      setChartExtremesX(chart, speed, xAxis.dataMax - calcMaxXAxisVisibleTimeMs(chart, speed));
    }
  };

  const addNewDataToChart = (data, seriesId) => {    
    let chart = chartComponentRef.current.chart;
    let workingSeriesId = seriesId ?? DEFAULT_SERIES_NAME;

    if (data.length > 0)
      latestReceivedTime.current = data.slice(-1)[0][0] ?? 0;

    switch (viewModeRef.current) {
      case 0: //accumulation mode
        {
          let series = chart.get(workingSeriesId);

          let chartOutsideBorders = checkDataLengthLessAllowedView(series);                  

          for (let i = 0; i < data.length; i++) {
            series.addPoint(data[i], false);
          }          

          chart.redraw({ duration: 110 });

          if (previousOnlineExceededBorders.current && chartOutsideBorders != previousOnlineExceededBorders.current)
            setCurrentViewLimitMoveToEnd();
          previousOnlineExceededBorders.current = chartOutsideBorders;

          break;
        }
      case 1: //refresh mode
        {
          let array_chunks = [];

          let i = 0;
          let tempTimeAdjustment = currentTimeAdjustment.current;
          while (i < data.length) {
            let newPortion = 0;
            let startTime = data[i][0] - tempTimeAdjustment;
            if (startTime >= maxXAxisWidth.current) { tempTimeAdjustment = data[i][0]; startTime = 0; }
            while (((i + newPortion) < data.length) && (data[i + newPortion][0] - tempTimeAdjustment < maxXAxisWidth.current))
              newPortion++;

            let chunk = data.slice(i, i + newPortion);
            array_chunks.push(chunk);
            i += newPortion;
            if (newPortion === 0) break;
          }

          array_chunks.map(chunk => {
            let start = chunk[0][0] - currentTimeAdjustment.current;
            if (start >= maxXAxisWidth.current) { currentTimeAdjustment.current = chunk[0][0]; start = 0; }

            let end = chunk[chunk.length - 1][0] - currentTimeAdjustment.current;
            //if (end <= maxXAxisWidth.current) {
            let currentData = chartDataRef.current[workingSeriesId];
            let cutStart = currentData.findIndex(x => x[0] >= start);
            if (cutStart < 0) cutStart = currentData.length;
            let cutEnd = currentData.findIndex(x => x[0] > end);
            if (cutEnd < 0) cutEnd = currentData.length;

            //console.log('currentTimeAdjustment.current: ', currentTimeAdjustment.current, 'cutStart: ', cutStart, 'cutEnd: ', cutEnd);

            let dataToInsert = [];
            chunk.map(x => dataToInsert.push([x[0] - currentTimeAdjustment.current, x[1]]));

            //console.log('dataToInsert: ', dataToInsert);

            chartDataRef.current[workingSeriesId].splice(cutStart, cutEnd - cutStart, ...dataToInsert);

            drawVerticalLine(chunk[chunk.length - 1][0] - currentTimeAdjustment.current);
            //}

          });

          chart.get(workingSeriesId).setData(chartDataRef.current[workingSeriesId], true, false, false);
          chart.redraw(false);
          break;
        }
      default:
        throw 'Not implemented yet';
    }    

  };

  const drawVerticalLine = (x) => {
    let chart = chartComponentRef.current.chart;    
    chart.xAxis[0].update({
      plotLines: [{
        color: '#FF0000', // Red
        width: 3,
        value: x // Position, you'll have to translate this to the values on your x axis
      }]
    });
  };

  const chartOptions = {
    chart: {
      panning: {
        type: 'xy'
      },
      events: {
        load: function (x) {
          if (props.onChartLoadEvent)
            props.onChartLoadEvent(this);

          setTimeout(() => {
            try {
              this.reflow();
            }
            catch { }
          }, 100);

          if (props.defaultCellTimeXms) {
            let xTimeMs = props.defaultCellTimeXms;

            setChartExtremesX(this, xTimeMs, 0);
            //this is required as otherwise the whole series is selected when panning on x axis.
            //Because of an extra series. Maybe add the extra series later ??
            //setTimeout(() => updateChart(40, 0), 2000); 
            setTimeout(() => {
              try {
                setChartExtremesX(this, xTimeMs, 0)
              } catch { }
            }, 200);
          }
        }
      },
    },
    xAxis: {

      maxRange: maxXAxisWidth.current + 1,

      tickPositioner: function () { return getChartTickPositions(this, props.defaultCellTimeXms) }     
    }
  };

  const getOptions = () => {
    let commonOptions = ChartConsts.regularChartOptions;
    let common = mergeDeep(commonOptions, chartOptions);

    if (props.specificChartOptions) {
      //return { ...common, ...props.specificChartOptions };
      return mergeDeep(common, props.specificChartOptions);
    }

    return common;
  }
  
  return (
    <>
      <HighchartsReact
        ref={chartComponentRef}
        highcharts={Highcharts}
        constructorType={(props.isStockChart === undefined || props.isStockChart) ? 'stockChart' : 'chart'}
        options={getOptions()}
      />

    </>
  )


});
export const OnlineHighcharts = React.memo(OnlineHighchartsForwardRef);