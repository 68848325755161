//sourse: https://stackoverflow.com/questions/74996068/trigger-toast-from-outside-react-component

import { Toast, ToastContainer } from 'react-bootstrap';
import { createContext, useContext, useEffect, useRef, useState } from "react";

// create context
const ToastContext = createContext(undefined);

// wrap context provider to add functionality
export const ToastContextProvider = ({ children }) => {
  const [show, setShow] = useState(false);
  const [toastSettings, setToastSettings] = useState({});

  const showToastMessage = (options) => {
    setToastSettings(options);
    setShow(true);
  };

  const showSuccessToastMessage = (message) => {
    setToastSettings({ message: message, variant :"success" });
    setShow(true);
  };

  const showErrorToastMessage = (message) => {
    setToastSettings({ message: message, variant: "danger" });
    setShow(true);
  };

  const toastStyles = {
    //backgroundColor: "white"
  };

  return (
    <ToastContext.Provider value={{ showToastMessage, showSuccessToastMessage, showErrorToastMessage }}>

      {children}
      <ToastContainer className="p-3" position="top-end" style={{ zIndex: 1 }}>
        <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide bg={toastSettings.variant} style={toastStyles}>
          <Toast.Header>           
            <strong className="me-auto"></strong>
            <small></small>
          </Toast.Header>
          <Toast.Body style={toastSettings.variant === "danger" || toastSettings.variant === "success" ? { color: "white" } : {}}>{toastSettings.message}</Toast.Body>
        </Toast>
      </ToastContainer>
    </ToastContext.Provider>
  );
};

export const useToastContext = () => {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error(
      "useToastContext have to be used within ToastContextProvider"
    );
  }

  return context;
};