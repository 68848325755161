import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';

import Slider from '@appigram/react-rangeslider'
import '@appigram/react-rangeslider/lib/index.css'
import { recordLengthFormatter } from '../../../utils/recordLengthFormatter';



export const AllProcessedDataSlider = forwardRef((props, ref) => {
  const [sliderValue, setSliderValue] = useState(props.maxTimeSec);
  const lastSliderValue = useRef(props.maxTimeSec);
  const maxSliderValue = useRef(props.maxTimeSec);
  const [sliderMaxValue, setSliderMaxValue] = useState(props.maxTimeSec);

  useImperativeHandle(ref, () => {
    return {
      setLastProcessedTime(lastProcessedTime) {
        setSliderMaxValue(lastProcessedTime);
        maxSliderValue.current = lastProcessedTime;
        console.log(lastProcessedTime);
      },
      getCurrentSliderTime() {
        return sliderValue;
      },
      isCurrendSliderMaxRight() {
        //console.log(lastSliderValue.current, maxSliderValue.current, lastSliderValue.current >= maxSliderValue.current)
        return lastSliderValue.current >= maxSliderValue.current;
      },
      setSliderValue(newValue) {
        setSliderValue(newValue);
        handleChangeComplete();
      }
    };
  }, []);


  useEffect(() => {    
    console.log('useEffect: slider');
  }, [sliderMaxValue]);


  const NUMBER_OF_TICKS = 7;

  const getHorizontalLabels = () => {
    let res = {};
    let step = (sliderMaxValue - props.minTimeSec) / NUMBER_OF_TICKS;
    for (let i = 0; i <= NUMBER_OF_TICKS; i++) {
      let time = i * step + props.minTimeSec;
      res[time] = recordLengthFormatter(time, true);
    }
    return res;
  }

  const formatValue = value => `${recordLengthFormatter(value, true)}`

  const onSliderChange = (value) => {
    //if (onTimeChangedCallback)
    //  onTimeChangedCallback(value);
    setSliderValue(value);
  };

  const handleChangeComplete = (event) => {
    lastSliderValue.current = sliderValue;
    if (props.onTimeChangedCallback)
      props.onTimeChangedCallback(sliderValue);
  };

  return (
    <>
      <Slider
        min={props.minTimeSec}
        max={sliderMaxValue}
        value={sliderValue}
        labels={getHorizontalLabels()}
        format={formatValue}
        //onChangeStart={() => { debugger; }}
        onChange={onSliderChange}
        onChangeComplete={handleChangeComplete}
      />
    </>
  )
});