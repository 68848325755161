import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import AddDiaryRecord from '../../components/Shared/Diary/AddDiaryRecord'

import MainBlockContainer from '../MainBlockContainer';
import MainBlockHeader from '../MainBlockHeader'
import { useTranslation } from 'react-i18next';

export default function DiaryAdd() {  
  let { userUid, recordUid, diaryRecordUid } = useParams();
  const { t } = useTranslation();

  //useEffect(() => {   
    
  //}, []);


  return (
    <>              
      <MainBlockHeader header={t('diary.header')} />

      <MainBlockContainer>
        <AddDiaryRecord apiDiaryBaseUrl={`/user/${userUid}/diary`} diaryListPageUrl={`/user/${userUid}/diary`} diaryRecordUid={diaryRecordUid} />
      </MainBlockContainer>
    </>
  );  
}

