import React, { useState, useEffect, useMemo} from 'react';
import api from "../../services/api";
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import TableFull from '../Shared/CommonTable/TableFull'

import ModalMessage from '../Shared/ModalMessage'

import { dateFormatter } from '../../utils/dateFormatter'
import Loading from '../Shared/Loading'

import styles from './ExpertActiveConsultations.module.scss'

export default function ExpertActiveConsultations() {
  const { t } = useTranslation();
  const [data, setData] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [modalTexts, setModalTexts] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {    
    loadConsultations();
  }, []);

  const loadConsultations = function () {
    api
      .get(`/consultation/expert/active/list`)
      .then((response) => {
        setData(response.data.items);
        setLoading(false);
      });
  }
  const onAcceptRequestClick = function (itemUid) {
    api
      .post(`/consultation/expert/active/accept`, { requestUid: itemUid })
      .then((response) => {
        setModalTexts({
          header: t('common.success'),
          body: response.data.message
        });
        setModalShow(true);
        loadConsultations();
      },
        (error) => {
          setModalTexts({
            header: t('common.error'),
            body: error.response.data.message
          });          
          setModalShow(true);
          loadConsultations();
        });    
  };

  const columns = useMemo(
    () =>
      [       
        {
          header: t('consultation.expert.list.userName'),
          accessorKey: "user.displayName",
          enableColumnFilter: false,
        },
        {
          header: t('consultation.expert.list.record'),
          accessorKey: "userRecord.displayName",
          enableColumnFilter: false,
        },
        {
          header: t('consultation.expert.list.requestDate'),
          accessorKey: "requestDate",
          enableColumnFilter: false,
          cell: props => <>{dateFormatter(props.getValue())}</>,
        },        
        {
          header: '',
          accessorKey: "uid",
          enableColumnFilter: false,
          cell: props => <><Button onClick={() => { onAcceptRequestClick(props.getValue()); }} variant="primary">{t('consultation.expert.list.acceptButton')}</Button></>
        },        
      ],
    [t]
  );  

  if (loading)
    return <Loading />;

  return (
    <>
      {modalShow &&
        <ModalMessage
          show={modalShow}
          onHide={() => { setModalShow(false); }}
          header={modalTexts.header}
          body={modalTexts.body}
          button={t('common.okButton')}
        />
      }

      <TableFull styles={styles} data={data} columns={columns} />
    </>
  );
}