import { React, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import { dateFormatter } from '../../utils/dateFormatter'
import Loading from '../Shared/Loading'
import api from "../../services/api";

import TableFull from '../Shared/CommonTable/TableFull';
import DeviceInfo from './Components/DeviceInfo';


import styles from './DeviceHeaderList.module.scss'

export default function DeviceHeaderList({ getDeviceHeaderListUrl, baseUrl }) {
  const { t } = useTranslation();
  const [data, setData] = useState();
  const [device, setDevice] = useState();

  const [loading, setLoading] = useState(true);
  let navigate = useNavigate();

  useEffect(() => {
    loadHeaderList();
  }, []);

  const loadHeaderList = function () {
    api
      .get(getDeviceHeaderListUrl)
      .then((response) => {
        setData(response.data.items);
        setDevice(response.data.device); 
        setLoading(false);
      });
  };

  const columns = useMemo(
    () =>
      [
        {
          header: t('metrology.recordList.date'),
          accessorKey: "dateCreatedUtc",
          enableColumnFilter: false,
          cell: props => <>{dateFormatter(props.getValue())}</>,
        },
        {
          header: t('metrology.recordList.metrologistName'),
          accessorKey: "metrologistName",
          enableColumnFilter: false,

        },
        {
          header: t('metrology.recordList.numberOfRecords'),
          accessorKey: "itemsCount",
          enableColumnFilter: false,          
        }
      ],
    [t]
  ); 

  const onRecordClickHandler = (record) => {
    navigate(`${baseUrl}/${device.uid}/records/${record.uid}/items`);
  };

  if (loading)
    return <Loading />;

  return (
    <>
      <DeviceInfo device={device} />

      <Row className="h4 mt-4">
        <Col>{t('metrology.recordList.recordList')}</Col>
      </Row>

      <TableFull styles={styles} data={data} columns={columns} onRowClick={onRecordClickHandler} />
    </>
  )
}