import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from "../../../services/api";
import TreeMenu from 'react-simple-tree-menu';
import 'react-simple-tree-menu/dist/main.css';
import { FormControl, ListGroup, Button, Stack } from 'react-bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';
import UsersGroupsListEditableLine from './UsersGroupsListEditableLine';

import CurrentUser from "../../../services/currentUser.service";

import styles from './UsersGroupsList.module.scss'
//https://github.com/iannbing/react-simple-tree-menu
//react-simple-tree-menu

export default function UsersGroupsList({ onUserItemClick, onGroupItemClick, userLoginUserListBaseUrl, blockEdit }) {
  //const user = useRecoilValue(selectedUserState);
  const { t } = useTranslation();  

  let [users, setUsers] = useState();  
  const [controlEditMode, setControlEditMode] = useState(false);  


  const loadUsers = function () {    
    api
      .get(userLoginUserListBaseUrl ?? `/settings/users`)
      .then((response) => {
        setUsers(response.data.userList);
      });
  };

  useEffect(() => {
    loadUsers();
    CurrentUser.forceUpdateUsersFromServer();
  }, []);

  const onItemClick = function (item) {
    if (!controlEditMode)
    { 
      if (onUserItemClick && !item.isGroup) {
        let user = findItemByUid(item.itemUid);
        onUserItemClick(user);
      }
      if (onGroupItemClick && item.isGroup) {
        let group = findGroup(item.itemUid);
        onGroupItemClick(group);
      }
    }    
  };

  function makeid(length) {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }

  const createGroupItem = function () {
    let key = makeid(10);
    return { key: key, itemUid: key, label: t('settings.users.createUserGroup'), isGroup: false, permissions: 0, isCreateGroupItem: true, focused: false, active: false };
  };
  const createUserItem = function () {
    let key = makeid(10);
    return { key: key, itemUid: key, label: t('settings.users.createUser'), isGroup: false, permissions: 0, isCreateUserItem: true, focused: false, active: false };
  };

  const addCreateNewItemsLines = (uList) => {
    uList.push(createGroupItem());
    uList.push(createUserItem());

    for (const g of uList.filter(x => x.isGroup && x.nodes)) {     
      g.nodes.push(createUserItem());
    }

    return uList;    
  };

  const onStartEditModeClick = function () {
    let newList = addCreateNewItemsLines(users);
    setUsers(newList);

    setControlEditMode(true);
  };

  const removeCreateNewItemsLines = (uList) => {
    let newUsers = uList.filter(x => !x.isCreateGroupItem && !x.isCreateUserItem);

    for (const g of newUsers.filter(x => x.isGroup && x.nodes)) {
      g.nodes = g.nodes.filter(x => !x.isCreateGroupItem && !x.isCreateUserItem);
    }

    return newUsers;
  };

  const onStopEditModeClick = function () {
    setControlEditMode(false);
    let newList = removeCreateNewItemsLines(users);
    setUsers(newList);
  };

  const findGroup = function (itemUid) {
    let g = users.find(x => x.itemUid == itemUid && x.isGroup);
    return g;
  };

  //Searches for user AND groups in the users list
  const findItemByUid = function (itemUid) {
    let u = users.find(x => x.itemUid == itemUid);
    if (u) return u;
    let found = null;

    for (const g of users.filter(x => x.isGroup)) {
      found = g.nodes.find(x => x.itemUid == itemUid);
      if (found) {        
        break;
      }
    }

    return found;
  };

  const updateItemLabelInList = function (itemUid, newLabel) {
    let u = findItemByUid(itemUid);
    u.label = newLabel;
  };

  const createNewItemInList = ({ itemUid, label, permissions, isGroup, parent }) => {
    let newItem = { key: itemUid, itemUid: itemUid, label: label, isGroup: isGroup, permissions: permissions, focused: false, active: false, nodes: [] };

    if (!parent || parent == '') {
      users.push(newItem);      
    } else {
      let g = findGroup(parent);
      g.nodes.push(newItem);
    }

    let list = users;
    list = removeCreateNewItemsLines(list);
    list = addCreateNewItemsLines(list);
    setUsers(list);
  };

  const MyListItem = ({ item }) => {
    const DEFAULT_PADDING = 1;
    const ICON_SIZE = 0.5;
    const LEVEL_SPACE = 3;

    let { itemUid, isGroup, permissions, isCreateUserItem, isCreateGroupItem,
      onClick,
      level = 0,
      hasNodes,
      isOpen,
      label,
      searchTerm,
      openNodes,
      toggleNode,
      matchSearch,
      focused,
      active,
      ...props
    } = item.item;

    const ToggleIcon = ({ on, onClick }) => (
      <div style={{ display: 'inline-block' }} onClick={onClick}>
        <span style={{ marginRight: 8 }}>{on ? '-' : '+'}</span>
      </div>
    );

    return (
      <ListGroup.Item
        {...props}
        className={`${active ? styles.activeLine : ''} ${styles.line} ${isGroup ? styles.isGroup : ''} `}
        onClick={onClick}
        style={{
          paddingLeft: `${DEFAULT_PADDING + ICON_SIZE + level * LEVEL_SPACE}rem`,
          boxShadow: focused ? '0px 0px 5px 0px #222' : 'none',
          //zIndex: focused ? 999 : 'unset',
          //position: 'relative',
          //backgroundColor: active ? "#0dcaf0" : 'default'
        }}
        variant={isGroup ? "info" : (isCreateUserItem || isCreateGroupItem) ? "warning" : "light"}
      >
        {!isCreateUserItem && !isCreateGroupItem &&
          <>
          <Stack direction="horizontal" >
              {hasNodes && <ToggleIcon on={isOpen} onClick={toggleNode} />}
              <UsersGroupsListEditableLine
                itemUid={itemUid}
                label={label}
                isGroup={isGroup}
                toggleNode={toggleNode}
                updateLabelCallback={updateItemLabelInList}
                isControlInEditMode={controlEditMode}
                parent={props.parent}
                />
          </Stack>
          </>
        }
                                            
        {(isCreateGroupItem || isCreateUserItem) &&
          <>             
            <UsersGroupsListEditableLine
              itemUid={itemUid}
              label={label}
              isGroup={(isCreateGroupItem) ? isCreateGroupItem : false}
              isNew={true}
              isControlInEditMode={controlEditMode}
              parent={props.parent}
              createNewItemCallback={createNewItemInList}
            />           
          </>
        }       

      </ListGroup.Item>
    )
  };

  return (
    <>    
      {!blockEdit && !controlEditMode && <Button variant="primary" onClick={onStartEditModeClick} className={styles.editButton}>{t('settings.users.editList')}</Button>}
      {!blockEdit && controlEditMode && <Button variant="primary" onClick={onStopEditModeClick} className={styles.editButton}>{t('settings.users.saveList')}</Button>}

        <TreeMenu
          data={users}
          debounceTime={125}
          disableKeyboard={false}
          hasSearch
          onClickItem={onItemClick}
        >
          {({ search, items }) => (
            <>
              <FormControl onChange={e => search(e.target.value)} placeholder={t('settings.users.typeAndSearchPlaceholder')} />
              <ListGroup>
                {items.map((item) => (
                  <MyListItem key={item.key} item={{ item: item }} />
                ))}
              </ListGroup>
            </>
          )}
        </TreeMenu>          
    </>
  )
}
