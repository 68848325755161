import React, { useEffect, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AuthService from "./services/auth.service";
import { useNavigate } from "react-router-dom";

import { Layout } from './components/Layouts/Layout';
import { LayoutAuth } from './components/Layouts/LayoutAuth';
import { LayoutAnonymous } from './components/Layouts/LayoutAnonymous';
import { Login } from './components/Auth/Login';
import { Register }  from './components/Auth/Register';
import { ForgotPasswordRequest } from './components/Auth/ForgotPasswordRequest'
import { SetNewPassword } from './components/Auth/SetNewPassword'

import { MenuTypes } from './components/SideMenu/SideMenu';

import Upload from './components/Records/Upload';

import {
  UserProfile, UserDiary, UserDiaryAdd, UserRecordsListOnly, ChangeUser,
  UserDocuments, UserDocumentAdd, UserRecordView, UserConsultations, UserDevices, UserEcg12, UserRecordEdit
} from './pages/User';

import * as Expert from './pages/Expert'
import * as Metrologist from './pages/Metrologist'
import * as Operator from './pages/Operator'

import * as AdminPages from './pages/Admin'
import * as Settings from './pages/Settings'
import * as StaticPages from './pages/Static'
import * as Admin from './components/Admin/index';

//import { UserLoginConsultations } from './components/Consultation/index';

import './custom.css'

function App(props) {
  let navigate = useNavigate();


  useEffect(() => {
    if (AuthService.shouldRedirectToLogin()) {
      navigate("/login");
    }
  });
  

  return (
    <Suspense fallback="loading...">
      {/*<Layout>*/}
      {/*<Route path='/'>*/}
      <Routes>
        <Route element={<LayoutAnonymous />}>
          <Route exact path='/contact-us/account-delete' element={<StaticPages.AccountDelete />} />
          <Route exact path='/privacy-policy' element={<StaticPages.Privacy />} />
        </Route>

        <Route element={<LayoutAuth />}>
          <Route exact path='/login' element={<Login />} />
          <Route exact path='/register' element={<Register />} />
          <Route exact path='/forgot-password' element={<ForgotPasswordRequest />} />
          <Route exact path='/set-password/:token' element={<SetNewPassword />} />
        </Route>

        <Route element={<Layout menuType={MenuTypes.Settings} />}>
          <Route exact path='/settings/' element={<Settings.Profile />} />
          <Route exact path='/settings/profile' element={<Settings.Profile />} />
          <Route exact path='/settings/personal' element={<Settings.Personal />} />
          <Route exact path='/settings/my-users' element={<Settings.MyUsers />} />
          <Route exact path='/settings/notifications' element={<Settings.Notifications />} />          
        </Route>

        <Route element={<Layout menuType={MenuTypes.About} />}>
          <Route exact path='/settings/about' element={<Settings.About />} />
        </Route>

        <Route element={<Layout menuType={MenuTypes.Main} />}>
          <Route exact path='/' element={<UserRecordsListOnly />} />
          <Route exact path='/user/:userUid' element={<UserRecordsListOnly />} />
          <Route exact path='/user/:userUid/profile' element={<UserProfile />} />
          <Route exact path='/user/:userUid/change' element={<ChangeUser />} />
          <Route exact path='/user/:userUid/records' element={<UserRecordsListOnly />} />
          <Route exact path='/user/:userUid/diary' element={<UserDiary />} />
          <Route exact path='/user/:userUid/diary/add' element={<UserDiaryAdd />} />
          <Route exact path='/user/:userUid/diary/edit/:diaryRecordUid' element={<UserDiaryAdd />} />
          <Route exact path='/user/:userUid/documents' element={<UserDocuments />} />
          <Route exact path='/user/:userUid/documents/add' element={<UserDocumentAdd />} />
          <Route exact path='/user/:userUid/records/:recordUid' element={<UserRecordView {...props} />} />
          <Route exact path='/user/:userUid/records/:recordUid/edit' element={<UserRecordEdit {...props} />} />
          <Route exact path='/user/:userUid/consultations' element={<UserConsultations />} />
          <Route exact path='/user/:userUid/devices' element={<UserDevices />} />
          <Route exact path='/user/:userUid/ecg12' element={<UserEcg12 />} />

          <Route exact path='/user/:userUid/upload' element={<Upload />} />


          <Route exact path='/metrology/devices' element={<Metrologist.Devices />} />
          <Route exact path='/metrology/devices/:deviceUid/records' element={<Metrologist.DevicesHeaders {...props} />} />
          <Route exact path='/metrology/devices/:deviceUid/records/:recordUid/items' element={<Metrologist.DevicesRecordItems {...props} />} />
          <Route exact path='/metrology/devices/:deviceUid/records/:recordUid/items/:itemUid/record' element={<Metrologist.DevicesRecordItemView {...props} />} />
          <Route exact path='/metrology/profile' element={<Metrologist.Profile />} />

          {/*<Route exact path='/consultations/user/list' element={<UserLoginConsultations {...props} />} />*/}

          <Route exact path='/consultations/expert/profile' element={<Expert.ExpertProfile {...props} />} />
          <Route exact path='/consultations/expert/active/list' element={<Expert.ExpertActiveConsultations {...props} />} />
          <Route exact path='/consultations/expert/accepted/list' element={<Expert.ExpertAcceptedConsultations {...props} />} />

          <Route exact path='/consultations/expert/accepted/:consultationUid/user/profile' element={<Expert.ConsultationUserProfile {...props} />} />
          <Route exact path='/consultations/expert/accepted/:consultationUid/user/diary' element={<Expert.ConsultationUserDiary {...props} />} />
          <Route exact path='/consultations/expert/accepted/:consultationUid/user/documents' element={<Expert.ConsultationUserDocuments {...props} />} />
          <Route exact path='/consultations/expert/accepted/:consultationUid/user/records' element={<Expert.ConsultationUserRecords {...props} />} />
          <Route exact path='/consultations/expert/accepted/:consultationUid/user/records/:recordUid' element={<Expert.ConsultationUserRecordView {...props} />} />
          <Route exact path='/consultations/expert/accepted/:consultationUid/current_record/:recordUid' element={<Expert.ConsultationUserRecordViewConsultation {...props} />} />
          <Route exact path='/consultations/expert/accepted/:consultationUid/user/devices' element={<Expert.ConsultationUserDevices {...props} />} />

          <Route exact path='/consultations/expert/completed/list' element={<Expert.ExpertCompletedConsultations {...props} />} />
          <Route exact path='/consultations/expert/completed/:consultationUid/user/profile' element={<Expert.ConsultationCompletedUserProfile {...props} />} />
          <Route exact path='/consultations/expert/completed/:consultationUid/current_record/:recordUid' element={<Expert.ConsultationCompletedUserRecordViewConsultation {...props} />} />

          <Route exact path='/operator/profile' element={<Operator.Profile />} />
          <Route exact path='/operator/device-list' element={<Operator.Devices />} />
          <Route exact path='/operator/device-list/add' element={<Operator.DeviceAddPage {...props} />} />
          <Route exact path='/operator/device-list/:deviceUid/edit' element={<Operator.DeviceEditPage {...props} />} />
          <Route exact path='/operator/device-list/add' element={<Operator.DeviceAddPage {...props} />} />

          <Route exact path='/operator/device-list/:deviceUid/records' element={<Operator.DevicesRecordHeaders {...props} />} />
          <Route exact path='/operator/device-list/:deviceUid/records/:recordUid/items' element={<Operator.DevicesRecordItems {...props} />} />
          <Route exact path='/operator/device-list/:deviceUid/records/:recordUid/items/:itemUid/record' element={<Operator.DevicesRecordItemView {...props} />} />


          <Route exact path='/admin/user-logins' element={<Admin.AdminUserLoginList />} />
          <Route exact path='/admin/user-login/:userLoginUid/settings/users' element={<Admin.AdminUserLoginUsers {...props} />} />

          <Route exact path='/admin/device-list' element={<AdminPages.Devices />} />
          <Route exact path='/admin/device-list/add' element={<AdminPages.DeviceAddPage {...props} />} />
          <Route exact path='/admin/device-list/:deviceUid/edit' element={<AdminPages.DeviceEditPage {...props} />} />

          <Route exact path='/admin/device-list/:deviceUid/records' element={<AdminPages.DevicesRecordHeaders {...props} />} />
          <Route exact path='/admin/device-list/:deviceUid/records/:recordUid/items' element={<AdminPages.DevicesRecordItems {...props} />} />
          <Route exact path='/admin/device-list/:deviceUid/records/:recordUid/items/:itemUid/record' element={<AdminPages.DevicesRecordItemView {...props} />} />

          {/*<Route path='/swagger' element={<Link to="/swagger" target="_self">...</Link>} />*/}

          {/*<Navigate from="*" to="/register" />*/}
          <Route path="*" element={<Navigate to="/login" />} />
        </Route>
      </Routes>
      {/*</Route>*/}
      {/*</Layout>*/}
    </Suspense>
  );

}

export default App