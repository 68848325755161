import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import UserViewRecords from '../../../components/Shared/RecordsList/UserViewRecords'

import MainBlockHeader from '../../MainBlockHeader'
import MainBlockContainer from '../../MainBlockContainer';
import { useTranslation } from 'react-i18next';

export default function Records() {  
  let { consultationUid } = useParams();
  const { t } = useTranslation();

  return (
    <>              
      <MainBlockHeader header={t('recordList.header')} >
      </MainBlockHeader>

      <MainBlockContainer>      
        <UserViewRecords getRecordListUrl={`/consultation/expert/accepted/${consultationUid}/user/records`} getPageUserRecordUrlTemplate={(userUid, recordUid) => `/consultations/expert/accepted/${consultationUid}/user/records/${recordUid}`} allowMoveRecords={false} />      
      </MainBlockContainer>
    </>
  );  
}

