import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from "../../../services/api";
import 'react-simple-tree-menu/dist/main.css';
import { Button, Form, Stack } from 'react-bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Formik } from "formik";
import * as Yup from "yup";
import styles from './UsersGroupsList.module.scss'


export default function UsersGroupsListEditableLine ({ itemUid, label, isGroup, isNew, toggleNode, updateLabelCallback, createNewItemCallback, isControlInEditMode, parent }){
  const { t } = useTranslation();  

  const [isEditMode, setEditMode] = useState(false);
  const [itemName, setItemName] = useState(label);

  useEffect(() => {
    //console.log('Remove re-render for ', itemUid);
  }, []);

  const handleFormSubmit = async (data, form) => {
    onSaveItemClick(data, form);
  };

  const onSaveItemClick = async (data, form) => {
    let url = '';
    let model = {};
    if (isGroup) {
      url = isNew ? `/users/group/add` : `/users/group/edit/${itemUid}`;
      model = { displayName: data.displayName };
    } else {
      url = isNew ? `/users/user/add` : `/users/user/edit/${itemUid}`;
      model = { displayName: data.displayName };
      if (parent && parent != '')
        model.parentUid = parent;
    }

    api.post(url, model, { formik: form, specificErrorField: 'displayName' })
      .then(response => {
        if (!isNew && updateLabelCallback)
          updateLabelCallback(itemUid, data.displayName);

        if (isNew && createNewItemCallback) {
          createNewItemCallback({
            itemUid: response.data.uid,
            label: response.data.displayName,
            permissions: response.data.permissions,
            isGroup: isGroup,
            parent: parent
          });
        }

        setItemName(data.displayName);
        setEditMode(false);
      },
        error => {
          //console.log(error.response.data.message);
          //form.setFieldError('displayName', error.response.data.message);
          //form.setErrors({ displayName: 'sdf' });
        });
  };

  const onLabelItemClick = () => {
    if (toggleNode) toggleNode();
    if(isNew)
      setEditMode(true);
  };

  const Circle = () => {
    return (
      <svg width="1.5rem" height="1.5rem" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="20" cy="20" r="20" fill="#012B54" />
      </svg>
    )
  }
  return (
    <>
      {!isEditMode ?
        <>
          <span onClick={onLabelItemClick} className={styles.lineItem}><Circle /><div className={styles.name}>{itemName}</div></span>
          {isControlInEditMode &&
            <Button variant="link" style={{ float: 'right' }} onClick={(e) => { setEditMode(true); }} className="p-0 border-0">
              <i className="bi bi-pencil-square" style={{ 'fontSize': '1rem', 'color': 'red' }}></i>
            </Button>}
        </> :
        <>
          <Formik            
            initialValues={{
              displayName: !isNew ? itemName : "",
            }}
            validationSchema={Yup.object({
              displayName: Yup.string()
                 .required(t("validation.required")),
            })}
            onSubmit={handleFormSubmit}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
              isSubmitting
            }) => (
              <Form noValidate onSubmit={handleSubmit} className="w-100">
                <Stack direction="horizontal" gap={0}>

                  <Stack direction="vertical" gap={2} className="me-auto mt-1">
                    <Form.Control
                      className="col-12 mb-2"
                      type="text"
                      name="displayName"
                      value={values.displayName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      isInvalid={touched.displayName && !!errors.displayName}
                      placeholder={isGroup ? t('settings.users.enterUserGroupNamePlaceholder') : t('settings.users.enterUserNamePlaceholder')}
                      onClick={(e) => { e.stopPropagation(); }}
                    />
                    <Form.Control.Feedback type="invalid" className="mb-4">{errors.displayName}</Form.Control.Feedback>
                  </Stack>

                  {/*  <div className="me-auto">*/}
                  {/*{(message || errors.displayName) && (*/}
                  {/*  <Alert key="danger" variant="danger" className="mt-3">*/}
                  {/*    {errors.displayName}*/}
                  {/*    {message}*/}
                  {/*  </Alert>*/}
                  {/*)}*/}
                  {/*  </div>*/}
                  <Button variant="link" type="submit" style={{ alignSelf: 'baseline' }} disabled={isSubmitting} >
                    <i className="bi bi-check-circle-fill" style={{ 'fontSize': '1.5rem', 'color': 'green' }}></i>
                  </Button>
                  <div className="vr" style={{ alignSelf: 'baseline' }} />
                  <Button variant="link" style={{ alignSelf: 'baseline' }} onClick={() => { setEditMode(false); }} disabled={isSubmitting}>
                    <i className="bi bi-x-circle-fill" style={{ 'fontSize': '1.5rem', 'color': 'orange' }}></i>
                  </Button>
                </Stack>

                {/*{message && (*/}
                {/*  <Alert key="danger" variant="danger" className="mt-3">*/}
                {/*    {message}*/}
                {/*  </Alert>*/}
                {/*)}              */}

              </Form>
            )}
          </Formik>
        </>
      }
    </>
  );
}