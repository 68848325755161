import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import api from "../../../services/api";
import Loading from '../Loading'
import { dateFormatter } from '../../../utils/dateFormatter'

import fileDownloadShow from "../../../services/fileDownloadShow";

import styles from './UserDevices.module.scss';
import * as CommonIcons from '../../CommonIcons';

import TableFull from '../CommonTable/TableFull';

export default function UserDevices({ apiDevicesBaseUrl }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const downloadOrShowFile = (e, target, row) => {    
    fileDownloadShow(`${apiDevicesBaseUrl}/${row.uid}/protocol/latest/download`);
  };

  const columns = useMemo(
    () => [
      {
        header: t('devices.table.header.modelName'),
        className: styles.dateCell,
        maxWidth: '3rem',
        accessorKey: "modelName"
      },
      {
        header: t('devices.table.header.serialNumber'),
        className: styles.dateCell,
        maxWidth: '3rem',
        accessorKey: "serialNumber"
      },
      {
        header: t('devices.table.header.bluetoothAddress'),
        className: styles.dateCell,
        maxWidth: '3rem',
        accessorKey: "bluetoothAddress"        
      },
      {
        id: 'lastProtocolDate-date',
        header: t('devices.table.header.metrologyDate'),
        className: styles.dateCell,
        maxWidth: '3rem',
        accessorKey: "lastProtocolDate",
        cell: info => {
          return (
            <>
              {dateFormatter(info.getValue())}
            </>
          )
        }
      },      
      {
        id: 'lastProtocolDate-download',
        header: t('devices.table.header.download'),
        maxWidth: '3rem',
        className: `${styles.center}`,
        accessorKey: "lastProtocolDate",        
        cell: info => {
          return (
            <>
              {info.getValue() && <button className={`${styles.btn}`} onClick={(e, target) => downloadOrShowFile(e, target, info.row.original)}><CommonIcons.DownloadButton /></button>}
            </>
          )
        }
      }

    ],
    [t]
  );


  useEffect(() => {
    const loadRecords = () => {
      api
        .get(apiDevicesBaseUrl)
        .then((response) => {
          setData(response.data.devices);

          setLoading(false);
        });
    };

    loadRecords();
  }, [apiDevicesBaseUrl]);


  if (loading)
    return <Loading />;

  return (
    <>    
      <TableFull styles={styles} data={data} columns={columns} />

    </>
  );
}
