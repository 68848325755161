import React, { useState, useEffect, useRef } from 'react';
import api from "../../../services/api";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CommonHighchartsLine } from '../Ecg/Line/CommonHighchartsLine'


export function HRSpecterChart({ displayName, data, chartName }) {
  const { t } = useTranslation();


  const chartRef = useRef(null);
  
  //const yAxisSettings = [{ title: { text: 'HR, bpm' }, labels: { format: '{value} bpm' }, opposite: false }];

  const chartOptions = {
    chart: {
      zoomType: 'x'
    },
    title: {
      text: t('recordView.processedData.charts.hrSpecter.header')
    },
    xAxis: {
      maxRange: undefined,
      title: {
        text: 'Hz'
      }
    },
    yAxis: {
      title: {
        //text: 'PSD, ms&#0178'
        text: ''
      }
    },
    legend: {
      enabled: false
    },
    tooltip: {
      valueDecimals: 2,
      split: true,
      shared: false,
      xDateFormat: '%M:%S.%L',
      formatter: function () {
        return '<b>' + Math.round(this.x * 1000) / 1000 + 'Hz</b>, PSD: <b>' + Math.round(this.y * 1000) / 1000 + 'ms&#0178</b>';
      }
    }
  };
  
  useEffect(() => {
    //console.log(displayName);

    //let data = [[1, 29.9], [2, 71.5], [3, 106.4], [4, 129.2], [5, 144.0], [6, 176.0],
    //  [7, 135.6], [8, 148.5], [9, 216.4], [10, 194.1], [11, 95.6], [12, 54.4]];
    let colors = ['#7cb5ec', '#434348', '#90ed7d', '#f7a35c', '#8085e9', '#ffb5ec'];

    let seriesSettings = {
      //type: 'area',
      //zoneAxis: 'x',
      //zones: [{
      //  value: 1,
      //  color: colors[2]
      //}, {
      //  value: 6,
      //  color: colors[5]
      //}, {
      //  value: 8,
      //  color: colors[2]
      //}, {
      //  color: colors[5]
      //}]
    }

    if (data) {

      
      //let min = Math.min(...data.map(x => x[1]));
      //let max = Math.max(...data.map(x => x[1]));

      //chartRef.current.setupYAxis(yAxisSettings);
      chartRef.current.setInitialData(data, seriesSettings);
      //chartRef.current.setChartExtremesX(data[data.length - 1][0], 0);
      //chartRef.current.setChartExtremesYMinMax(min, max);
    }
  }, []);


 

  return (
    <>
      <h3>{displayName}</h3>
      <CommonHighchartsLine ref={chartRef} seriesName={'PSD'} chartOptions={chartOptions} />            
    </>
  );
}

