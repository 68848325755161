import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';

import Highcharts from 'highcharts/highstock'
import AnnotationsFactory from "highcharts/modules/annotations";
import HighchartsReact from 'highcharts-react-official'
import mergeDeep from '../../../../utils/mergeDeep';
import * as ChartConsts from '../../../ChartSettings/ChartSettings'


// init the module
AnnotationsFactory(Highcharts);
//bellCurve(Highcharts);

const OnlineHighchartsHistogramForwardRef = forwardRef((props, ref) => {

  const chartComponentRef = useRef(null);
  const chartDataRef = useRef({});

  const DEFAULT_SERIES_NAME = 'series-data';


  useImperativeHandle(ref, () => ({
    setInitialData(data, seriesSettings) {
      chartDataRef.current[(seriesSettings && seriesSettings.seriesId) ?? DEFAULT_SERIES_NAME] = data;
      let chart = chartComponentRef.current.chart;
      let seriesId = (seriesSettings && seriesSettings.seriesId) ?? DEFAULT_SERIES_NAME;    

      let newSeries =
      {
        id: seriesId,
        data: data,
        name: (seriesSettings && seriesSettings.name) ?? props.seriesName,
        //color: (seriesSettings && seriesSettings.color) ?? 'blue',        
        yAxis: (seriesSettings && seriesSettings.yAxisId) ?? 0
      };

      if (chart.get(seriesId))
        chart.get(seriesId).update(newSeries);
      else
        chart.addSeries(newSeries);
    },
    
  }));

  useEffect(() => {
    //console.log('accumulation mode:', props.mode);    

  }, []);

  const newOptions = {
    chart: {
      type: 'column',
      zooming: {
        mouseWheel: {
          enabled: false
        }
      }
    },
    plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 0,
        groupPadding: 0.01,
        shadow: false
      }
    },
    xAxis: {
      type: 'category',
    },
    yAxis: {
      min: 0,
      title: {
        text: ''
      }
    },
  };

  const getOptions = () => {
    let base = ChartConsts.baseChartOptions;
    
    let common = mergeDeep(base, newOptions);

    if (props.specificChartOptions) {
      //return { ...common, ...props.specificChartOptions };
      return mergeDeep(common, props.specificChartOptions);
    }

    return common;
  }
  
  return (
    <>
      <HighchartsReact
        ref={chartComponentRef}
        highcharts={Highcharts}
        constructorType={'chart'}
        options={getOptions()}
      />

    </>
  )


});
export const OnlineHighchartsHistogram = React.memo(OnlineHighchartsHistogramForwardRef);