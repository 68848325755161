import { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import styles from '../SideMenu.module.scss'

import * as Icons from '../SideMenuIcons';
import { useTranslation } from 'react-i18next';
import { MenuList } from './MenuList';
import AuthService from "../../../services/auth.service";

export { MenuListMetrology };
function MenuListMetrology({ closeOffcanvasAction }) {
 
  const location = useLocation();
  const path = location.pathname;
  const { t } = useTranslation();

  useEffect(() => {
  });


  const expertLinkList = [    
    {
      link: `/metrology/devices`,
      text: t('sidebar.myDevices'),
      icon: <Icons.Devices />,
      isActive: function () { return path.includes(this.link); },
      hasPermission: true
    },
    {
      link: `/metrology/profile`,
      text: t('sidebar.metrology.profile'),
      icon: <Icons.PaperWithIcon />,
      hasPermission: true
    },
    {
      link: `/metrology/documents`,
      text: t('sidebar.metrology.documents'),
      icon: <Icons.File />,
      //isActive: function () { return path.includes(this.link); },
      hasPermission: true,
      isDisabled: true
    }
  ];

  return (
    <>
      <div className={styles.header}>
        <Icons.Factory />
        {/*{t('sidebar.metrology.header')}*/}
        {AuthService.getUserRoles()?.roles?.metrologyst ?? AuthService.getLoggedInUser().displayName }
      </div>
      <MenuList itemsList={expertLinkList} closeOffcanvasAction={closeOffcanvasAction} />
    </>

  );

}