import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { Col, Row } from 'react-bootstrap';
import { OnlineHighchartsAsync } from './OnlineHighchartsAsync'
import { useTranslation } from 'react-i18next';
import DropdownChartSpeedBreathing from './DropdownChartSpeedBreathing';

const TemperatureHighchartsAsyncForwardRef = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const chartComponentRef = useRef(null);  

  useImperativeHandle(ref, () => ({
    setInitialData(data, seriesSettings) {
      chartComponentRef.current.setInitialData(data, seriesSettings);
    },
    setInitialDataForLongSeries(longData, asyncDataLoadUrl, seriesSettings) {
      chartComponentRef.current.setInitialDataForLongSeries(longData, asyncDataLoadUrl, seriesSettings);
    },
    setDataViewMode(mode) {
      chartComponentRef.current.setDataViewMode(mode);
    },
    addNewDataToChart(data, seriesId) {
      chartComponentRef.current.addNewDataToChart(data, seriesId);
    },
    addNewDataToChartLongSeries(data) {
      chartComponentRef.current.addNewDataToChartLongSeries(data)
    },
    setupYAxis(settings) {
      chartComponentRef.current.setupYAxis(settings);    
    },
    scrollToEnd() {
      chartComponentRef.current.scrollToEnd();
    }
  }));

  useEffect(() => {    
    
  }, []);

  const otherChartOptions = {
    chart: {
    },
    xAxis: {
      maxRange: undefined
    },
    yAxis: {
      min: 32,
      max: 40
    },
    plotOptions: {
      series: {
        //getExtremesFromAll: true,
        point: {
          events: {
            //mouseOver: function () { setHoverData(this.category); }
          }
        },
        dataGrouping: {
          enabled: false,          
        }        
      },
      //line: {
      //  dataGrouping: {
      //    dateTimeLabelFormats: {
      //      millisecond:  ['%Mm %S.%Ls', '%Mm %S.%Ls', '-%Mm %S.%Ls'],
      //      second:       ['%Mm %S.%Ls', '%Mm %S.%Ls', '-%Mm %S.%Ls'],
      //      minute:       ['%Mm %S.%Ls', '%Mm %S.%Ls', '-%Mm %S.%Ls'],
      //      hour:         ['%Mm %S.%Ls', '%Mm %S.%Ls', '-%Mm %S.%Ls'],
      //    }
      //  }
      //}
    },
    navigator: {
      handles: {
        enabled: false
      }
    },
    title: {
      text: ''
    }
  };


  return (
    <>
      <Row>
        <Col sm={10}>
          <DropdownChartSpeedBreathing onSelectionChangedCallback={(value) => chartComponentRef.current.setChartExtremesX(value)} /><span className="px-3"></span>          
        </Col>
        <Col>
        </Col>
      </Row>
      <Row>
        <Col className="px-0">
          <OnlineHighchartsAsync ref={chartComponentRef} specificChartOptions={otherChartOptions} seriesName={props.seriesName}
            defaultCellTimeXms={400} />
        </Col>
      </Row>
    </>
  )


});
export const TemperatureHighchartsAsync = React.memo(TemperatureHighchartsAsyncForwardRef);