import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import styles from '../SideMenu.module.scss'

import api from "../../../services/api";

import * as Icons from '../SideMenuIcons';
import { useRecoilValue } from 'recoil';
import { selectedUserState } from '../../../states/selectedUserState';
import { useTranslation } from 'react-i18next';
import { MenuList } from './MenuList';
import AuthService from "../../../services/auth.service";

export { MenuListExpert };
function MenuListExpert({ closeOffcanvasAction, isExpertViewMode }) {
  const user = useRecoilValue(selectedUserState);
  const location = useLocation();
  const path = location.pathname;
  const { t } = useTranslation();
  let { consultationUid } = useParams();

  const [consultationDetails, setConsultationDetails] = useState(null);

  const CONSULTATION_STATE_ACCEPTED = 20;
  const CONSULTATION_STATE_COMPLETED = 30;

  useEffect(() => {
    const loadConsultationDetails = () => {
      api
        .get(`/consultation/expert/${consultationUid}/details`)
        .then((response) => {
          setConsultationDetails(response.data);
        });
    };

    if (consultationUid)
      loadConsultationDetails();
    else 
      setConsultationDetails(null);

  }, [consultationUid]);


  const consultationLoaded = () => consultationDetails && consultationDetails.user; 

  const expertLinkList = [
    {
      link: `/consultations/expert/active/list`,
      text: t('sidebar.expert.activeList'),
      icon: <Icons.BellRinging />,
      isActive: function () { return path.endsWith(this.link); }
    },
    {
      link: `/consultations/expert/accepted/list`,
      text: t('sidebar.expert.acceptedList'),
      icon: <Icons.BellWithPencil />,
      //isActive: function () { return path.includes(`/consultations/expert/accepted/`); }
      isActive: function () { return path.endsWith(this.link); }
    },
    {
      link: `/consultations/expert/completed/list`,
      text: t('sidebar.expert.completedList'),
      icon: <Icons.Bell />,
      //isActive: function () { return path.includes(`/consultations/expert/completed/`); }
      isActive: function () { return path.endsWith(this.link); }
    },
    {
      link: `/consultations/expert/profile`,
      text: t('sidebar.expert.profile'),
      icon: <Icons.PaperWithIcon />,
      hasPermission: true      
    },
    {
      link: `/consultations/expert/documents`,
      text: t('sidebar.expert.documents'),
      icon: <Icons.File />,
      //isActive: function () { return path.includes(this.link); },
      hasPermission: true,
      isDisabled: true
    }
  ];

  const activeConsultationUserLinkList = [
    {
      link: `/consultations/expert/accepted/${consultationUid}/current_record/${consultationDetails?.record?.uid}`,
      text: t('sidebar.expert.currentConsultation'),
      icon: <Icons.PaperWithIcon />,
    },
    {
      link: `/consultations/expert/accepted/${consultationUid}/user/profile`,
      text: t('sidebar.profile'),
      icon: <Icons.PaperWithIcon />,
    },
    {
      link: `/consultations/expert/accepted/${consultationUid}/user/documents`,
      text: t('sidebar.documents'),
      icon: <Icons.File />,
      isActive: function () { return path.includes(this.link); },
    },
    {
      link: `/consultations/expert/accepted/${consultationUid}/user/diary`,
      text: t('sidebar.diary'),
      icon: <Icons.Paper />,
      isActive: function () { return path.includes(this.link); }
    },
    {
      link: `/consultations/expert/accepted/${consultationUid}/user/records`,
      text: t('sidebar.recordList'),
      icon: <Icons.Copy />,
      isActive: function () { return path.includes(this.link); },
    },
    {
      link: `/user/${user?.uid}/ecg12`,
      text: t('sidebar.ecg12'),
      icon: <Icons.Ecg12 />,
      isDisabled: true
    },
    {
      link: `/consultations/expert/accepted/${consultationUid}/user/devices`,
      text: t('sidebar.userDevices'),
      icon: <Icons.Devices />      
    }
  ];

  const completedConsultationUserLinkList = [
    {
      link: `/consultations/expert/completed/${consultationUid}/current_record/${consultationDetails?.record?.uid}`,
      text: t('sidebar.expert.currentConsultation'),
      icon: <Icons.PaperWithIcon />,
    },
    {
      link: `/consultations/expert/completed/${consultationUid}/user/profile`,
      text: t('sidebar.profile'),
      icon: <Icons.PaperWithIcon />,
    }
  ];

  return (
    <>
      <div className={styles.header}>
        <Icons.SheetWithPerson />
        {/*{t('sidebar.expert.header')}*/}
        {AuthService.getUserRoles()?.roles?.expert ?? AuthService.getLoggedInUser().displayName}
      </div>
      <MenuList itemsList={[expertLinkList[0], expertLinkList[1]]} />

      {isExpertViewMode && consultationLoaded() && consultationDetails.state === CONSULTATION_STATE_ACCEPTED &&
        <div className={styles.subexpert}>
          <div className={`${styles.header} ${styles.userNameWithChange}`}>
            <Icons.User />
            <div className={styles.userName}>{consultationDetails.user.displayName}</div>
          </div>
          <MenuList itemsList={activeConsultationUserLinkList} closeOffcanvasAction={closeOffcanvasAction} />
        </div>
      }

      <MenuList itemsList={[expertLinkList[2]]} />

      {isExpertViewMode && consultationLoaded() && consultationDetails.state === CONSULTATION_STATE_COMPLETED &&
        <div className={styles.subexpert}>
          <div className={`${styles.header} ${styles.userNameWithChange}`}>
            <Icons.User />
            <div className={styles.userName}>{consultationDetails.user.displayName}</div>
          </div>
          <MenuList itemsList={completedConsultationUserLinkList} closeOffcanvasAction={closeOffcanvasAction} />
        </div>
      }

      <MenuList itemsList={[expertLinkList[3]]} />
      <MenuList itemsList={[expertLinkList[4]]} />
    </>

  );

}