import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import UserProfile from '../../../components/Shared/Profile/UserProfile'

import MainBlockContainer from '../../MainBlockContainer';
import MainBlockHeader from '../../MainBlockHeader'

import { useTranslation } from 'react-i18next';

export default function Profile() {  
  let { consultationUid } = useParams();
  const { t } = useTranslation();

  //useEffect(() => {   
    
  //}, []);


  return (
    <>              
      <MainBlockHeader header={t('profile.header')} >
      </MainBlockHeader>

      <MainBlockContainer>
        <UserProfile profileBaseUrl={`/consultation/expert/completed/${consultationUid}/user/profile`} canEdit={false} />        
      </MainBlockContainer>
    </>
  );  
}

