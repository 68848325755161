import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { dateFormatter } from '../../../utils/dateFormatter'

function DeviceItemInfo({ item }) {
  const { t } = useTranslation();

  return (
    <>
      { item &&
        <>
          <Row className="h4 mt-2">
            <Col>{t('metrology.itemInfo.header')}</Col>
          </Row>
          <Row className="mt-3">
            <Col>{t('metrology.itemInfo.date')} : {dateFormatter(item.dateCreatedUtc)}</Col>
            <Col>{t('metrology.itemInfo.recordType')} : {item.metrologyRecordTypeName}</Col>
          </Row>
          <Row className="mt-3">
            <Col>{t('metrology.itemInfo.sectionName')} : {item.metrologySectionName}</Col>
            <Col>{t('metrology.itemInfo.operationType')} : {item.metrologyOperationName}</Col>
          </Row>
        </>
      }
    </>
  );
}

export default DeviceItemInfo;
