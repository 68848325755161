import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import UserViewOneRecord from '../../components/Shared/User/UserViewOneRecord'

import MainBlockHeader from '../MainBlockHeader'
import MainBlockContainer from '../MainBlockContainer';

import { useRecoilValue } from 'recoil';
import { selectedUserState } from '../../states/selectedUserState';
import UserPermissions from '../../services/user.permissions.service';

import { useTranslation } from 'react-i18next';

export default function RecordView() {  
  let { userUid, recordUid } = useParams();
  const { t } = useTranslation();
  const user = useRecoilValue(selectedUserState);
  let permissions = new UserPermissions(user);

  return (
    <>              
      <MainBlockHeader header={t('recordList.header')} >
      </MainBlockHeader>

      <MainBlockContainer>      
        <UserViewOneRecord
          userUid={userUid}
          recordUid={recordUid}
          baseOneRecordUrl={`/user/records`}
          allowRequestConsultation={permissions.hasConsultationsRequestPermission()}
          allowViewConsultation={permissions.hasConsultationsReadPermission() }
          getPageUserUrlTemplate={(userUid) => `/user/${userUid}/records`}
        />

      </MainBlockContainer>
    </>
  );  
}

