import { React, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UserLoginUsersWithPermissions from '../Shared/User/UserLoginUsersWithPermissions'
import api from "../../services/api";
import { Breadcrumb } from 'react-bootstrap';

import Loading from '../Shared/Loading'

export default AdminUserLoginUsers ;

function AdminUserLoginUsers() {
  const { t } = useTranslation();

  let { userLoginUid } = useParams();

  const [loading, setLoading] = useState(true);
  const [userLogin, setUserLogin] = useState([]);

  useEffect(() => {
    function getUserLoginDetails() {
      api
        .get(`settings/admin/user-login/${userLoginUid}`)
        .then((response) => {
          setUserLogin(response.data);
          setLoading(false);
        });
    };

    getUserLoginDetails();

  }, [userLoginUid]);

  

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin/user-logins" }} >{t('sidebar.admin.userList')}</Breadcrumb.Item>        
        <Breadcrumb.Item active>{userLogin.firstName} {userLogin.lastName}</Breadcrumb.Item>
      </Breadcrumb>

      <h2>{t('admin.userLoginList.users.usersFor')} '{userLogin.firstName} {userLogin.lastName}'</h2>
      {loading ? <Loading /> :
        <UserLoginUsersWithPermissions userLoginUserListBaseUrl={`/settings/admin/user-login/${userLoginUid}/users`} basePermissionsUrl={`/settings/admin/user-login/${userLoginUid}/users/shared_access`} blockEditUserList={true} />
      }      
    </>
  )

}
