import { mergeDeep } from '../../utils/mergeDeep';
import { highchartsLabelFormatterFromMs, highchartsLabelFormatterWithoutMsFromMs } from '../../utils/dateFormatter';

export const baseChartOptions = {
  accessibility: { enabled: false },
  subtitle: {
    text: ''
  },
  credits: {
    enabled: false
  }
};

const navigatorOptions = {
  series: {
    type: 'line',
  },
  xAxis: {
    ordinal: false,
    dateTimeLabelFormats: { //was '%M:%S.%L'
      millisecond: '%M:%S',
      second: '%M:%S',
      minute: '%M:%S',
      hour: '%M:%S',
      day: '%M:%S',
      week: '%M:%S',
      month: '%M:%S',
      year: '%M:%S'
    },
    labels: {
      formatter() {
        return highchartsLabelFormatterWithoutMsFromMs(this.value);
      },      
      allowOverlap: false
    }
  },
  //adaptToUpdatedData: false
  stickToMax: false,
  maskFill: 'rgba(102, 133, 194, 0.7)',
  handles: {
    //symbols: ['doublearrow', 'doublearrow'],
    lineWidth: 2,
    width: 7,
    height: 25,
    borderColor: 'darkblue'
  }
};

export const navigationChartOptions = {
  accessibility: { enabled: false },
  chart: {
    height: '100px',
    marginLeft: 75,
    panning: {
      enabled: true,
      type: 'xy'
    },
    panKey: 'shift',
    resetZoomButton: {
      theme: {
        style: {
          display: 'none'
        }
      }
    },    
    zooming: {
      mouseWheel: {
        enabled: false
      }
    }
  },
  navigator: navigatorOptions,
  //https://stackoverflow.com/questions/71499554/is-it-possible-to-independently-use-highcharts-navigator-without-displaying-the
  yAxis: {
    visible: false
  },
  xAxis: {
    visible: false,    
  },
  rangeSelector: {
    enabled: false
  },
  scrollbar: {
    liveRedraw: false,
    barBackgroundColor: '#9b2d30',//'red',
    height: 15
  },
  legend: {
    enabled: false
  },
  credits: {
    enabled: false
  }
};

export const regularChartOptions = {
  accessibility: { enabled: false },
  chart: {
    height: '400px',
    panning: {
      enabled: true,
      type: 'y'
    },
    panKey: 'shift',
    resetZoomButton: {
      theme: {
        style: {
          display: 'none'
        }
      }
    },
    zooming: {
      mouseWheel: {
        enabled: false
      }
    }
  },
  navigator: navigatorOptions,
  xAxis: {
    type: 'datetime',
    tickInterval: 200,
    minorTicks: true,
    minorTickInterval: 40,
    gridLineWidth: 1,
    gridLineColor: '#7cb5ec',
    //gridLineColor: 'red',

    dateTimeLabelFormats: {
      millisecond: '%M:%S.%L',
      second: '%M:%S.%L',
      minute: '%M:%S.%L',
      hour: '%M:%S.%L',
      day: '%M:%S.%L',
      week: '%M:%S.%L',
      month: '%M:%S.%L',
      year: '%Y'
    },
    allowDecimals: true,
    labels: {
      formatter() {
        return highchartsLabelFormatterFromMs(this.value);
      },
      allowOverlap: false
    },
  },
  rangeSelector: {
    enabled: false
  },
  tooltip: {
    valueDecimals: 2,
    split: true,
    shared: false,
    xDateFormat: '%M:%S.%L'
  },
  scrollbar: {
    liveRedraw: false,
    barBackgroundColor: '#9b2d30',//'red',
    height: 15
  },
  plotOptions: {
    series: {
      point: {
        events: {
          //mouseOver: function () { setHoverData(this.category); }
        }
      },
      dataGrouping: {
        enabled: false
      },
      states: {
        inactive: {
          opacity: 1
        }
      }
    }
  },
  legend: {
    enabled: false
  },
  credits: {
    enabled: false
  }
};

