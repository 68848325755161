import React from 'react';

import { default as SettingsProfile } from '../../components/Settings/Profile'

import MainBlockHeader from '../MainBlockHeader'
import MainBlockContainer from '../MainBlockContainer';
import { useTranslation } from 'react-i18next';

export default function Profile() {
  //let { userUid } = useParams();
  const { t } = useTranslation();

  return (
    <>
      <MainBlockHeader header={t('settingsProfile.pageTitile')} >
      </MainBlockHeader>

      <MainBlockContainer>
        <SettingsProfile />
      </MainBlockContainer>
    </>
  );
}

