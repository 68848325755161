import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import DeviceHeaderList from '../../components/Metrology/DeviceHeaderList'

import MainBlockHeader from '../MainBlockHeader'
import MainBlockContainer from '../MainBlockContainer';
import { useTranslation } from 'react-i18next';

export default function DevicesRecordHeaders() {  
  let { deviceUid } = useParams();

  const { t } = useTranslation();

  return (
    <>              
      <MainBlockHeader header={t('metrology.recordList.pageTitle')} >
      </MainBlockHeader>

      <MainBlockContainer>        
        <DeviceHeaderList
          getDeviceHeaderListUrl={`/metrology/devices/${deviceUid}/records`}
          baseUrl='/metrology/devices' /> 
      </MainBlockContainer>
    </>
  );  
}

