import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import DropdownChartSpeed from './DropdownChartSpeed';
import DropdownChartAmp from './DropdownChartAmp';
import { OnlineHighchartsAsync } from './OnlineHighchartsAsync'
import { useTranslation } from 'react-i18next';
import mergeDeep from '../../../utils/mergeDeep';

import ButtonChartMarkers from './ButtonChartMarkers';

const EcgHighchartsAsyncForwardRef = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const chartComponentRef = useRef(null);  

  //const [chartLength, setChartLength] = useState(150);

  const EXMAPLE_SERIES_ID = 'mv-ms-test';

  useImperativeHandle(ref, () => ({
    setInitialData(data, seriesSettings) {
      chartComponentRef.current.setInitialData(data, seriesSettings);
    },
    setInitialDataForLongSeries(longData, asyncDataLoadUrl, seriesSettings) {
      chartComponentRef.current.setInitialDataForLongSeries(longData, asyncDataLoadUrl, seriesSettings);
    },
    setDataViewMode(mode) {
      chartComponentRef.current.setDataViewMode(mode);
    },
    addNewDataToChart(data, seriesId) {
      chartComponentRef.current.addNewDataToChart(data, seriesId);
    },
    addNewDataToChartLongSeries(data) {
      chartComponentRef.current.addNewDataToChartLongSeries(data)
    },
    setupYAxis(settings) {
      chartComponentRef.current.setupYAxis(settings);
    },
    scrollToEnd() {
      chartComponentRef.current.scrollToEnd();
    },
    addAnnotation(newAnnotation) {
      chartComponentRef.current.addAnnotation(newAnnotation);
    },
    setPlotLinesX(plotLinesX) {
      chartComponentRef.current.setPlotLinesX(plotLinesX);
    }
  }));

  useEffect(() => {
  }, []);

  const exampleSeries = (offsetNull) => {
    let offsetX = offsetNull ?? 0;
    let offsetY = -0.5;
    let heightY = 1;

    return {
      id: EXMAPLE_SERIES_ID,
      name: '1mV, 2.5Hz',
      type: 'line',
      data: [
        [offsetX + 0, offsetY],
        [offsetX + 80, offsetY],
        [offsetX + 80, offsetY + heightY],
        [offsetX + 280, offsetY + heightY],
        [offsetX + 280, offsetY],
        [offsetX + 480, offsetY],
        [offsetX + 480, offsetY + heightY],
        [offsetX + 680, offsetY + heightY],
        [offsetX + 680, offsetY],
        [offsetX + 760, offsetY],
      ],
      color: 'red',
      lineWidth: 4,
      showInNavigator: false
    }
  };

  const runOnChartLoadEvent = function (chart) {
    setTimeout(() => { try { removeAndAddExampleSeries(chart) } catch (error) { } }, 2000);
  };

  const removeAndAddExampleSeries = (chart, startPoint) => {
    if (props.hideCalibrationSeries === undefined || (props.hideCalibrationSeries !== undefined && !props.hideCalibrationSeries)) {
      if (chart.get(EXMAPLE_SERIES_ID))
        chart.get(EXMAPLE_SERIES_ID).update(exampleSeries(startPoint ?? 0));
      else
        chart.addSeries(exampleSeries());
    }
  };

  const accumulationModeSetCallback = (chart, startTime) => {
    setTimeout(() => removeAndAddExampleSeries(chart, startTime), 500);
  }

  const refreshModeSetCallback = (chart, startTime) => {
    setTimeout(() => removeAndAddExampleSeries(chart, 0), 500);
  }

  const ecgChartOptions = {
    // To avoid unnecessary update keep all options in the state.
    chart: {
      height: '400px',
    },
    yAxis: {
      minorTicks: true,
      minorTickInterval: 0.1,
      minorGridLineColor: 'red',
      minorGridLineWidth: 0.3,

      tickInterval: 1,
      gridLineWidth: 1.5,
      gridLineColor: 'red',

      min: -3,
      max: 3,
      scrollbar: {
        enabled: true
      },
      zoomEnabled: false,
    },
    xAxis: {
      minorTicks: true,
      minorTickInterval: 40,
      minorGridLineColor: 'red',
      minorGridLineWidth: 0.3,

      tickInterval: 400,
      gridLineWidth: 1.5,
      gridLineColor: 'red',

      zoomEnabled: false,

      maxRange: undefined,

      dateTimeLabelFormats: {
        millisecond: '%M:%S.%L',
        second: '%M:%S.%L',
        minute: '%M:%S.%L',
        hour: '%M:%S.%L',
        day: '%M:%S.%L',
        week: '%M:%S.%L',
        month: '%M:%S.%L',
        year: '%Y'
      },
      allowDecimals: true,
      //  function () {
      //  var positions = [],
      //    tick = Math.floor(this.dataMin),
      //    increment = 400;

      //  if (this.dataMax !== null && this.dataMin !== null) {
      //    for (tick; tick - increment <= this.dataMax; tick += increment) {
      //      positions.push(tick);
      //    }
      //  }
      //  return positions;
      //}    
    },
    rangeSelector: {
      enabled: false
    },
    series: [
    ],
    tooltip: {
      valueDecimals: 3,
      valueSuffix: 'mV',
      split: true,
      shared: false,

      xDateFormat: '%M:%S.%L',
      //formatter() {
      //  let res = '';        
      //  //let date = new Date(this.x)
      //  //if (date.getMinutes() > 0)
      //  //  res = `${date.getMinutes()}m `;
      //  //res += `${ date.getSeconds() }.${ Math.round(date.getMilliseconds() / 10)}s`;

      //  //res = `${res} - ${Math.round(this.y * 100) / 100}mV`;

      //  res = `<b>${Math.round(this.y * 100) / 100}mV</b>`;

      //  return res;
      //}  
    },
    plotOptions: {
      series: {
        //getExtremesFromAll: true,
        point: {
          events: {
            //mouseOver: function () { setHoverData(this.category); }
          }
        }
      },
      line: {
        dataGrouping: {
          dateTimeLabelFormats: {
            millisecond: [
              '%M:%S.%L', '%M:%S.%L', ''
            ],
            second: ['%M:%S.%L', '%M:%S.%L', ''],
            minute: ['%M:%S.%L', '%M:%S.%L', ''],
            hour: ['%M:%S.%L', '%M:%S.%L', ''],
            day: ['%M:%S.%L', '%M:%S.%L', ''],
            week: ['%M:%S.%L', '%M:%S.%L', ''],
            month: ['%M:%S.%L', '%M:%S.%L', ''],
            year: ['%M:%S.%L', '%M:%S.%L', ''],
          }
        }
      }
    },
    title: {
      text: ''
    }
  };

  const ecgNavigatorChartOptions = {
    navigator: {
      xAxis: {
        type: 'datetime'
      },
      handles: {
        enabled: false
      },
      series: {
        dataGrouping: {
          enabled: false
        }
      }
    },
    title: {
      text: ''
    }
  };

  const getOptions = () => {
    let common = ecgChartOptions;
    if (props.specificChartOptions) {
      return mergeDeep(common, props.specificChartOptions);
    }

    return common;
  }

  const getNavigatorOptions = () => {
    let common = ecgNavigatorChartOptions;

    return common;
  }
  return (
    <>
      {(props.isHideControls === undefined || props.isHideControls === false) &&
        <Row>
          <Col lg className="mt-2">
            <DropdownChartAmp onSelectionChangedCallback={(value) => chartComponentRef.current.setChartExtremesY(value)} />
          </Col>
          <Col lg className="mt-2">
            <DropdownChartSpeed onSelectionChangedCallback={(value) => chartComponentRef.current.setChartExtremesX(value)} />
          </Col>
          <Col lg className="mt-2">
            <ButtonChartMarkers onSelectionChangedCallback={(value) => chartComponentRef.current.setMarkersVisibility(value)} />            
          </Col>
          <Col lg>
            {/*<label>Record length: {chartLength}</label>*/}

          </Col>
        </Row>
      }
      <Row>
        <Col className="px-0">
          <OnlineHighchartsAsync ref={chartComponentRef} specificChartOptions={getOptions()} specificNavigatorOptions={getNavigatorOptions()} seriesName={t('recordView.multiChart.seriesName.ecg')}
            onChartLoadEvent={runOnChartLoadEvent} defaultCellTimeXms={40} accumulationModeSetCallback={accumulationModeSetCallback}
            refreshModeSetCallback={refreshModeSetCallback} isStockChart={props.isStockChart} />
        </Col>
      </Row>
    </>
  )


});
export const EcgHighchartsAsync = React.memo(EcgHighchartsAsyncForwardRef);