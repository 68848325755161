import React, { useState, useEffect, useMemo } from 'react';
import { Form, Button, Modal, Alert, Col, Row, Stack, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ProfileFieldBase from './ProfileFieldBase'

import styles from './UserProfile.module.scss'

export default function ProfileFieldNumber({ profileLine, isEditMode, setValue }) {
  const { t } = useTranslation();

  const [text, onSetText] = useState(profileLine.value);

  useEffect(() => {
  }, []);

  const handleChange = (event) => {
    onSetText(event.target.value);

    if (setValue)
      setValue(event.target.value);
  };

  return (
    <>
      <ProfileFieldBase profileLine={profileLine} isEditMode={isEditMode}>
        <Form.Control className={styles.input} type="number" onChange={handleChange} value={text} />
        {/*  <Form.Text className="text-muted">*/}
        {/*    We'll never share your email with anyone else.*/}
        {/*  </Form.Text>*/}
      </ProfileFieldBase>
    </>
  );
}
