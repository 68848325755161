import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-date-picker'
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

import api from "../../../services/api";

import { Button, Modal, Alert, Col, Row, Stack, Input } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';

import styles from './AddDocument.module.scss';
import * as CommonIcons from '../../CommonIcons';
import * as Icons from './UserDocumentIcons';

export default function AddDocument({ documentsBaseUrl, documentListUrl }) {
  const { t } = useTranslation();
  let navigate = useNavigate();

  const [fileDate, setFileDate] = useState(new Date());
  const [fileText, setFileText] = useState('');
  const [fileTypeSelection, setFileTypeSelection] = useState();
  
  const [selectedFile, setSelectedFile] = useState();

  const [loading, setLoading] = useState(true);  
  const [isSending, setIsSending] = useState(false);
  const [data, setData] = useState([]); 
  
  useEffect(() => {
    const loadSettings = () => {
      api
        .get(`/settings/files/file_types`)
        .then((response) => {
          setData(response.data.fileTypes);
          setLoading(false);      
        });
    };

    loadSettings();

  }, []);

 
  const onFileTypeSelectionTypeChanged = (newUid) => {
    setFileTypeSelection(newUid);
  };

  const onFileSelected = (newFile) => {
    setSelectedFile(newFile);
  };

  const onDiaryInputValueChanged = (value) => {
    setFileText(value);    
  };

  const uploadFile = (recordUid) => {
    let formData = new FormData();

    formData.append(
      "file",
      selectedFile,
      selectedFile.name
    );    

    api.post(`${documentsBaseUrl}/${recordUid}/upload`, formData)
      .then(
        () => { if (documentListUrl) navigate(documentListUrl); }
    );
  };

  const onSendRecord = () => {
    console.log(fileDate, ' - ', fileTypeSelection, ' - ', fileText);
    setIsSending(true);

    api.post(documentsBaseUrl, {
      date: fileDate,
      typeUid: fileTypeSelection,
      description: fileText
    }).then(response => {
      setIsSending(false);
      setFileText('');
      
      uploadFile(response.data.uid);     
    },
      (error) => { setLoading(false); });

  };


  if (loading) return <></>;

  return (
    <>      
      <div className="d-flex flex-column align-items-center">
        <div className={styles.header}>
          {t('docs.add.uploadHeader')}
        </div>        
        <Form.Group className={styles.fileControl}>
          <Form.Label>{t('docs.add.selectFile')}</Form.Label>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Control type="file" onChange={e => onFileSelected(e.target.files[0])} />
            
          </Form.Group>
        </Form.Group>


        <Form.Group className={styles.fileType}>
          <Form.Label>{t('docs.add.selectTypeHeader')}</Form.Label>
          <Form.Select value={fileTypeSelection} onChange={e => onFileTypeSelectionTypeChanged(e.target.value)} defaultValue="">   
            <option value="" disabled hidden>{t('docs.add.selectType')}</option>
            {data.map(opt => (
              <option value={opt.uid} key={opt.uid}>{opt.displayName}</option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group className={styles.dateControl}>
          <Form.Label>{t('docs.add.selectDateHeader')}</Form.Label>
          <DatePicker onChange={setFileDate} value={fileDate} format="dd-MM-yyyy" showLeadingZeros={false} className="form-control" />
        </Form.Group>

        <Form.Group className={styles.description}>
          <Form.Label>{t('docs.add.selectDescriptionHeader')}</Form.Label>
          <Form.Control type="text" value={fileText} onChange={e => onDiaryInputValueChanged(e.target.value)} placeholder={t('docs.add.selectDescription')} />
        </Form.Group>
       
        <div className={styles.button}>
          <Button disabled={!(fileTypeSelection && selectedFile)} variant="primary" className="btn-block" onClick={onSendRecord}>{isSending && (<span className="spinner-border spinner-border-sm"></span>)}{t('common.addButton')}</Button>
        </div>
      </div>      
    </>
  );
}