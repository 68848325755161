import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import UserDocuments from '../../components/Shared/Documents/UserDocuments'

import MainBlockContainer from '../MainBlockContainer';
import MainBlockHeader from '../MainBlockHeader'

import { useRecoilValue } from 'recoil';
import { selectedUserState } from '../../states/selectedUserState';
import UserPermissions from '../../services/user.permissions.service';

import { useTranslation } from 'react-i18next';

export default function Documents() {  
  let { userUid, recordUid } = useParams();
  const { t } = useTranslation();
  const user = useRecoilValue(selectedUserState);
  let permissions = new UserPermissions(user);

  //useEffect(() => {   
    
  //}, []);


  return (
    <>              
      <MainBlockHeader header={t('docs.header')} >
      </MainBlockHeader>

      <MainBlockContainer>
        <UserDocuments documentsBaseUrl={`/user/${userUid}/files`} addNewDocumentUrl={permissions.hasDocumentsEditPermission() ? `/user/${userUid}/documents/add` : null}  />
      </MainBlockContainer>
    </>
  );  
}

