import React from 'react';

import MainBlockHeader from '../MainBlockHeader'
import MainBlockContainer from '../MainBlockContainer';
import { useTranslation } from 'react-i18next';

export default function AccountDelete() {
  //let { userUid } = useParams();
  const { t } = useTranslation();

  return (
    <>
      <MainBlockHeader header='Support' >
      </MainBlockHeader>

      <MainBlockContainer>
        <div>
          <p>
            If you want to request your account and associated data to be deleted, please contact us via email at <a href="mailto:support-account@delfihealth.com">support-account@delfihealth.com</a> and specify your email address.
          </p>
          <p>
            We will contact you via email to confirm which data you want to be deleted. You can request the deletion of your account and all associated data or only partial deletion of your data.
          </p>
          <p>
            After you request is completed, we will delete data that you have requested. This may include your personal data, your health data, or your uploaded files. 
          </p>
        </div>

      </MainBlockContainer>
    </>
  );
}

