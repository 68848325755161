import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import UserProfile from '../../components/Shared/Profile/UserProfile'

import MainBlockContainer from '../MainBlockContainer';
import MainBlockHeader from '../MainBlockHeader'

import { useRecoilValue } from 'recoil';
import { selectedUserState } from '../../states/selectedUserState';
import UserPermissions from '../../services/user.permissions.service';

import { useTranslation } from 'react-i18next';

export default function Profile() {  
  let { userUid, recordUid } = useParams();
  const { t } = useTranslation();
  const user = useRecoilValue(selectedUserState);
  let permissions = new UserPermissions(user);

  //useEffect(() => {   
    
  //}, []);


  return (
    <>              
      <MainBlockHeader header={t('profile.header')} >
      </MainBlockHeader>

      <MainBlockContainer>
        <UserProfile profileBaseUrl={`/user/${userUid}/profile`} canEdit={permissions.hasProfileEditPermission()} />        
      </MainBlockContainer>
    </>
  );  
}

