import { React, useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';


import { useTranslation } from 'react-i18next';
import api from "../../services/api";
import { Breadcrumb } from 'react-bootstrap';

import { EcgHighcharts } from '../Shared/Ecg/EcgHighcharts'

import { dateFormatter } from '../../utils/dateFormatter'

import Loading from '../Shared/Loading'
import DeviceInfo from './Components/DeviceInfo';
import DeviceHeaderInfo from './Components/DeviceHeaderInfo';
import DeviceItemInfo from './Components/DeviceItemInfo';
import DeviceItemCalculated from './Components/DeviceItemCalculated';

export default function DeviceItemView({ getItemRecordApiUrl }) {
  const { t, i18n } = useTranslation();

  const chartEcgRef = useRef(null);

  const [data, setData] = useState();
  const [isLoading, setLoading] = useState(true);

  const yAxisSettings = [{ title: { text: t('recordView.multiChart.seriesName.ecg') }, labels: { format: '{value} mV' }, opposite: false }];

  useEffect(() => {
    function loadData() {
      //CurrentUserService.setCurrentUser(userUid);
      api
        .get(getItemRecordApiUrl)
        .then((response) => {
          setData(response.data);

          chartEcgRef.current.setupYAxis(yAxisSettings);
          chartEcgRef.current.setInitialData(response.data.data);

          setLoading(false);
        });
    };

    loadData();
  }, []);

  return (
    <>
      {isLoading && <Loading />}

      <DeviceInfo device={data?.device} />

      <Row className="mt-4">
      </Row>

      <DeviceHeaderInfo record={data?.record} />

      <Row className="mt-4">
      </Row>

      <DeviceItemInfo item={data?.item} />

      <Row className="mt-5"></Row>

      <DeviceItemCalculated calculated={data?.metrologyResult} />

      <Row className="mt-5">
      </Row>

      <EcgHighcharts ref={chartEcgRef} />
    </>
  )
}