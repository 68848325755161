import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';

//https://github.com/iannbing/react-simple-tree-menu
//react-simple-tree-menu

export default DropdownChartSpeed;

function DropdownChartSpeed({ onSelectionChangedCallback }) {
  const { t } = useTranslation();

  const [value, setValue] = useState(25);
  const [speedPerMm, setSpeedPerMm] = useState(1000 / 25);

  const onSelectionChanged = function (eventKey) {
    setValue(eventKey);
    let speed = 1000 / eventKey;
    setSpeedPerMm(speed);

    if (onSelectionChangedCallback)
      onSelectionChangedCallback(speed);
  }

  return (   
    <>
      <div className="text-nowrap">
        <label>{t('recordView.multiChart.ecg.speed')}:</label>
        <Dropdown onSelect={(eventKey) => { onSelectionChanged(eventKey) }} className="d-inline px-2">
          <Dropdown.Toggle variant="secondary" id="dropdown-basic">{value} mm/s</Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item eventKey="10">10 mm/s</Dropdown.Item>
            <Dropdown.Item eventKey="25">25 mm/s</Dropdown.Item>
            <Dropdown.Item eventKey="50">50 mm/s</Dropdown.Item>
            <Dropdown.Item eventKey="100">100 mm/s</Dropdown.Item>
            <Dropdown.Item eventKey="200">200 mm/s</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <label>1mm = {speedPerMm}ms</label>
      </div>
    </>
    );
}
