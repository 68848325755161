import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Button, Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import api from "../../../services/api";
import Loading from '../Loading'
import { dateAsNumbersWithoutSeconds } from '../../../utils/dateFormatter'

import TableFull from '../CommonTable/TableFull';

import { DiaryTrendsChart } from './DiaryTrendsChart'

import * as CommonIcons from '../../CommonIcons';

import styles from './UserDiary.module.scss';


export default function UserDiary({ apiDiaryBaseUrl, pageDiaryBaseUrl }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState([]);
  const [selectedTrend, setSelectedTrend] = useState(null);
  const [trendParameters, setTrendParameters] = useState([]);
  const [chartData, setChartData] = useState([]);

  const [canEditRecords] = useState(pageDiaryBaseUrl !== undefined);

  const columnArray =
    [{
      header: "No.",
      maxWidth: '0.5rem',
      enableColumnFilter: false,
      hideOnMobile: true,
      cell: ({ row }) => (
        <div>
          {row.index + 1}
        </div>
      )
    },
    {
      header: t('diary.table.header.date'),
      className: styles.dateCell,
      maxWidth: '3rem',
      accessorKey: "date",
      cell: info => {
        return (
          <>
            {dateAsNumbersWithoutSeconds(info.getValue())}
          </>
        )
      }

    },
    {
      header: t('diary.table.header.type'),
      accessorKey: "typeName",
    },
    {
      header: t('diary.table.header.value'),
      accessorKey: "value",
      allowWrap: true
    }    
    ];

  if (canEditRecords)
    columnArray.push({
      header: '',
      accessorKey: "uid",
      hideOnMobile: true,
      cell: info => {
        return (
          <div className={styles.editIcon}>
            <Link to={`${pageDiaryBaseUrl}/edit/${info.getValue()}`}><CommonIcons.PensilEdit /></Link>
          </div>
        )
      }
    });

  const columns = useMemo(
    () => columnArray,
    [t]
  );


  const loadRecords = () => {
    api
      .get(apiDiaryBaseUrl)
      .then((response) => {
        setAllData(response.data.diaryRecords);
        setData(response.data.diaryRecords);
        setTrendParameters(response.data.trendValues);
        setLoading(false);
      });
  };

  useEffect(() => {
    loadRecords();
  }, [apiDiaryBaseUrl]);


  if (loading)
    return <Loading />;


  const dropDownMenuActions = [{
    as: Link,
    getLinkTo: row => `${pageDiaryBaseUrl}/edit/${row.uid}`,    
    text: t('common.editButton')
  }];  

  const onTrendParameterClick = (item) => {    
    setSelectedTrend(item);
    if (item && item !== null) {
      setData(allData.filter(x => x.typeUid === item.uid));
      let newArray = allData
        .filter(x => x.typeUid === item.uid)
        .map(x => [Date.parse(x.date), parseFloat(x.value)]);

      newArray.sort((a, b) => a[0] - b[0]);

      setChartData(newArray);
    }
    else {
      setData(allData);
      setChartData([]);
    }
  };

  return (
    <>      
      <div className={`${styles.addNewBtnContainer}`}>
        {selectedTrend && 
          <Button className={`btn btn-primary ${styles.addNewBtn} me-3`} onClick={() => onTrendParameterClick(null)}>{t('diary.trends.clear')}</Button>
        }
        
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic" className={`btn btn-primary ${styles.addNewBtn} me-3`}>
            {t('diary.trends.chooseParameter')}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {selectedTrend &&
              <>
                <Dropdown.Item onClick={() => onTrendParameterClick(null)} >{t('diary.trends.clear')}</Dropdown.Item>
                <Dropdown.Divider />
              </>
            }

            {trendParameters.map((item, index) => (
              <Dropdown.Item key={item.uid} onClick={() => onTrendParameterClick(item)} >{item.displayName}</Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>

      {pageDiaryBaseUrl && (
          <Link className={`btn btn-primary ${styles.addNewBtn}`} to={`${pageDiaryBaseUrl}/add`}>{t('diary.addNew')}</Link>
      )}
        </div>

      {selectedTrend &&
        <DiaryTrendsChart title={selectedTrend.displayName} data={chartData} />
      }

      <TableFull data={data}
        columns={columns}
        styles={styles}
        dropDownMenuActions={dropDownMenuActions}
        mainTableClassName={canEditRecords ? styles.table : styles.tableNoEdit}
      />     
    </>
  );
}
