import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'react-bootstrap';
import { enable } from 'workbox-navigation-preload';

//https://github.com/iannbing/react-simple-tree-menu
//react-simple-tree-menu

export default ButtonChartMarkers;

function ButtonChartMarkers({ onSelectionChangedCallback }) {
  const { t } = useTranslation();

  const [isEnabled, setIsEnabled] = useState(false);

  const onSelectionChanged = function (eventKey) {
    console.log(eventKey.target.checked);
    if (onSelectionChangedCallback)
      onSelectionChangedCallback(!isEnabled);

    setIsEnabled(!isEnabled);
  }

  //const switchStyles = {
  //  cursor: 'pointer',
  //  input:{
  //    cursor: 'pointer',
  //  }
  //};

  return (   
    <>     
      <Form.Check // prettier-ignore
        inline
        type="switch"
        id="custom-switch"
        label={t('recordView.multiChart.ecg.markers')}
        className="ms-3 text-nowrap"
        checked={isEnabled}
        onChange={(eventKey) => { onSelectionChanged(eventKey) }}      
      />
    </>
    );
}
