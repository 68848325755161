import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import AddDocument from '../../components/Shared/Documents/AddDocument'

import MainBlockContainer from '../MainBlockContainer';
import MainBlockHeader from '../MainBlockHeader'
import { useTranslation } from 'react-i18next';

export default function DocumentAdd() {  
  let { userUid, recordUid } = useParams();
  const { t } = useTranslation();

  //useEffect(() => {   
    
  //}, []);


  return (
    <>              
      <MainBlockHeader header={t('docs.header')} >
      </MainBlockHeader>

      <MainBlockContainer>
        <AddDocument documentsBaseUrl={`/user/${userUid}/files`} documentListUrl={`/user/${userUid}/documents`} />        
      </MainBlockContainer>
    </>
  );  
}

