import { useEffect, useState } from 'react';
import { Button, Modal, Alert, Col, Row, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import UsersGroupsList from '../User/UsersGroupsList';
import api from "../../../services/api";

export default function MoveRecordsModal({ show, onHide, records, onSuccessCallback }) {
  const { t } = useTranslation();

  const [canMove, setCanMove] = useState(false);
  const [selectedUserUid, setSelectedUserUid] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [isMoving, setMoving] = useState(false);

  useEffect(() => {
    console.log(records);
  }, []);


  const onUserClick = function (item) {
    setCanMove(true);
    setSelectedUserUid(item.itemUid);
    setErrorMessage('');
  };

  const onGroupClick = function (item) {
    setCanMove(false);
    setSelectedUserUid(null);    
    setErrorMessage('');
  };

  const onMoveClick = function () {
    setMoving(true);
    api.post(`user/${selectedUserUid}/records/move`, { selectedUids: records })
      .then(response => {
        if (onSuccessCallback) onSuccessCallback();
        onHide();
        setMoving(false);
      },
        error => {
          setErrorMessage(error.response.data.message);
          setMoving(false);
        });  
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t('recordList.moving.header')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{t('recordList.moving.subHeader')}</h4>        
        <UsersGroupsList onUserItemClick={onUserClick} onGroupItemClick={onGroupClick} userLoginUserListBaseUrl={`/settings/users/edit_records_permission`} />
      </Modal.Body>
      <Modal.Footer>        
        <Col>
          <Row>
          {errorMessage &&
            <Alert variant="danger" className="me-auto">
              {errorMessage}
            </Alert>
            }
          </Row>          
          <Row>
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              <Button className="me-md-2" variant="warning" onClick={onMoveClick} disabled={!canMove}>{isMoving && (
                <span className="spinner-border spinner-border-sm"></span>
              )} {t('common.moveButton')}</Button>
              <Button variant="primary" onClick={onHide}>{t('common.closeButton')}</Button>
            </div>
          </Row>
        </Col>
      </Modal.Footer>
    </Modal>
  );
}
