import { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';

//https://github.com/iannbing/react-simple-tree-menu
//react-simple-tree-menu

export default CheckBox;

function CheckBox({ id, name, value, isDisabled, onChange, editIcon, viewIcon, allowWrap }) {
  const [checked, setChecked] = useState(value);

  useEffect(() => {
    //console.log('CheckBox useEffect', value, checked);
    if (value !== checked)
      setChecked(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleCheck = (e) => {
    let newValue = !checked;
    onChange(newValue);
    setChecked(newValue);
  };

  return (
    <Form.Check className={allowWrap !== undefined ? `` : `text-nowrap`}>
      <Form.Check.Input disabled={isDisabled} id={id} type='checkbox' onChange={handleCheck} checked={checked} />
      {viewIcon === undefined && editIcon === undefined && <Form.Check.Label htmlFor={id}>{name}</Form.Check.Label>}
      {viewIcon !== undefined && <i className='bi-eye-fill ps-2' title={name}></i>}
      {editIcon !== undefined && <i className='bi-pencil-fill ps-2' title={name}></i>}
    </Form.Check>
  );
}
