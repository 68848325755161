import axios from "axios";
import TokenService from "./token.service";
import { ConcurrentActionHandler } from './concurrentActionHandler';

const _concurrencyHandler = new ConcurrentActionHandler();

const instance = axios.create({
  //baseURL: "http://localhost:8080/api",
  baseURL: "/api/v1/front/",
  headers: {
    "Content-Type": "application/json",
  },
});
instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
     // config.headers["x-access-token"] = token; // for Node.js Express back-end
    }
    
    config.headers["Accept-Language"] = TokenService.getCookie("i18next-lng");

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) =>  {
    const originalConfig = err.config;
    if (originalConfig && originalConfig.url !== "/auth/signin" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        console.log('Got status 401, waiting for a new token for request.. ', originalConfig.url);

        try {
          await _concurrencyHandler.execute(async () => {            
            const rs = await instance.post("/auth/refresh-token", {
              //If a temporary refresh token exists, it will override the one in the cookies
              refreshToken: TokenService.getTemporaryRefreshToken(),
            });
            const { jwtToken } = rs.data;
            TokenService.updateLocalAccessToken(jwtToken);
          });

          console.log('Repeating request.. ', originalConfig.url);
          return instance(originalConfig);          
        } catch (_error) {
          console.log('caught api error:', _error);
          if (_error.response.status === 400)
            window.location.href = "/login";

          return Promise.reject(_error);
        }
      }
    }

    //if (err.response.status === 422 && err.response.data && err.response.data.errorCode === 101) {
    //  console.log("Validation error");
    //  console.log(err.response.data);
    //  let customError = new Error(err.response.data);
    //  //response.original_status = response.status
    //  //response.status = response.data.errors[0].extensions.code
    //  //customError.response = response;
    //  return Promise.reject(customError);
    //}

    if (err.response && err.response.data && err.response.status === 422 && (err.config.formik || err.config.setErrorMessage)) {
      let errorData = err.response.data;
      if (errorData && errorData.errorCode === 101)
        if (err.config.formik)
          err.config.formik.setErrors(errorData.errors);
        else {
          if (err.config.setErrorMessage && typeof err.config.setErrorMessage === 'function') {
            let resMessage =
              (errorData && errorData.message) ||
              err.message ||
              err.toString();
            err.config.setErrorMessage(resMessage);
          }
        }
      if (errorData && (errorData.errorCode === 102 || errorData.errorCode === 103)) {        
        if (err.config.setErrorMessage && typeof err.config.setErrorMessage === 'function') {          
          let resMessage =
            (errorData && errorData.message) ||
            err.message ||
            err.toString();
          err.config.setErrorMessage(resMessage);
        }
        if (err.config.formik && err.config.specificErrorField) {
          let resMessage =
            (errorData && errorData.message) ||
            err.message ||
            err.toString();
          console.log(resMessage);
          err.config.formik.setFieldError(err.config.specificErrorField, resMessage);
        }
      }
    }

    return Promise.reject(err);
  }
);


export default instance;