import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ModalConfirm({ show, onHide, header, body, buttonConfirmText, buttonCancelText, onConfirmClick }) {

  const handleClose = () => onHide(); 

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {header}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>      
        <p>
          {body}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="warning" onClick={onConfirmClick}>
          {buttonConfirmText}
        </Button>
        <Button variant="primary" onClick={handleClose}>
          {buttonCancelText}
        </Button>        
      </Modal.Footer>
    </Modal>
  );
}

export default ModalConfirm;