import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import DeviceEdit from '../../components/Metrology/DeviceEdit'

import MainBlockHeader from '../MainBlockHeader'
import MainBlockContainer from '../MainBlockContainer';
import { useTranslation } from 'react-i18next';

export default function DeviceEditPage() {  
  let { deviceUid } = useParams();

  const { t } = useTranslation();

  return (
    <>              
      <MainBlockHeader header={t('metrology.edit.editPageTitle')} >
      </MainBlockHeader>

      <MainBlockContainer>        
        <DeviceEdit
          getDeviceEditUrl={`/operator/device-list/${deviceUid}/edit`}
          baseUrl='/operator/device-list' /> 
      </MainBlockContainer>
    </>
  );  
}

