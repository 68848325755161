import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function DeviceInfo({ device }) {
  const { t } = useTranslation();

  return (
    <>
      {
        device &&
        <>
          <Row className="h4 mt-2">
            <Col>{t('metrology.deviceInfo.header')}</Col>
          </Row>
          <Row className="mt-3">
            <Col>{t('metrology.deviceInfo.deviceType')} : {device.deviceName}</Col>
            <Col>{t('metrology.deviceInfo.modelName')} : {device.modelName}</Col>
          </Row>
          <Row>
            <Col>{t('metrology.deviceInfo.serialNumber')} : {device.serialNumber}</Col>
            <Col>{t('metrology.deviceInfo.bluetoothAddress')} : {device.bluetoothAddress}</Col>
          </Row>
        </>
      }
    </>
  );
}

export default DeviceInfo;
