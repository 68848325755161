import React, { useState, useEffect } from 'react';

import * as CommonIcons from '../../CommonIcons';
import Dropdown from 'react-bootstrap/Dropdown';
import { useParams, Link } from 'react-router-dom';

import TableHeader from './TableHeader'
import TableRows from './TableRows'
import TablePaging from './TablePaging'

import { useNavigate, useLocation } from 'react-router-dom';

import {
  Column,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Table,
  useReactTable,
} from "@tanstack/react-table";

export default function TableFull({ data, columns, styles, onRowClick, dropDownMenuActions, resetSelection, initialStateSettings, mainTableClassName, allowColumnHeaderSort, allowGlobalFilter, globalFilterText }) {

  const [rowSelection, setRowSelection] = React.useState({})
  const [resetRowSelection, setResetRowSelection] = React.useState(0);

  const [sorting, setSorting] = React.useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  const useQuery = () => {
    return new URLSearchParams(location.search);
  };

  const QUERY_PARAM_PAGE_INDEX = 'page';
  const QUERY_PARAM_PAGE_SIZE = 'size';

  const query = useQuery();
  const currentPage = query.get(QUERY_PARAM_PAGE_INDEX) ? parseInt(query.get(QUERY_PARAM_PAGE_INDEX), 10) - 1 : 0;
  const currentPageSize = query.get(QUERY_PARAM_PAGE_SIZE) ? parseInt(query.get(QUERY_PARAM_PAGE_SIZE), 10) : 10;

  const setPageAndSize = (pageNumber, pageSize) => {
    if (currentPage !== pageNumber)
      query.set(QUERY_PARAM_PAGE_INDEX, pageNumber + 1);

    if (currentPageSize !== pageSize)
      query.set(QUERY_PARAM_PAGE_SIZE, pageSize);

    if (currentPage !== pageNumber || currentPageSize !== pageSize)
    navigate({ search: query.toString() });
  };

  const [pagination, setPagination] = useState({
    pageIndex: currentPage, //initial page index
    pageSize: currentPageSize, //default page size
  });

  const table = useReactTable({
    data,
    columns,
    state: {
      rowSelection,
      pagination,
      sorting,
      globalFilter: globalFilterText,
    },
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),    
    getFilteredRowModel: getFilteredRowModel(),
    //onGlobalFilterChange: setGlobalFilter,
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: (updater) => {
      // make sure updater is callable (to avoid typescript warning)
      if (typeof updater !== "function") return;

      const newPageInfo = updater(table.getState().pagination);
      console.log(table.getState().pagination);
      //output: {pageIndex: 1, pageSize: 30}
      setPagination(newPageInfo);
      setPageAndSize(newPageInfo.pageIndex, newPageInfo.pageSize);      
    },
    debugTable: true,
    autoResetPageIndex: false,
    initialState: initialStateSettings,
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    globalFilterFn: allowGlobalFilter ? 'includesString' : undefined,
  });

  useEffect(() => {
    // Bubble up the selection to the parent component        
    if (resetSelection !== resetRowSelection) {
      table.toggleAllRowsSelected(false);
      setResetRowSelection(resetSelection);
    }
    console.log(currentPage);
    //setPagination({ pageIndex: currentPage });
    table.setPagination({ pageIndex: currentPage, pageSize: currentPageSize });
    //table.setPageSize(currentPageSize);
    //setHiddenColumns(CurrentUserService.hasDeleteRecordPerimssion() ? '' : 'selection')
  }, [rowSelection, resetSelection, location]);

  return (
    <>
      <div className={`${mainTableClassName ?? styles.table}`}>

        <TableHeader table={table} styles={styles} allowColumnHeaderSort={allowColumnHeaderSort} />

        <TableRows table={table} styles={styles} isRowHover={true} onRowClick={onRowClick} dropDownMenuActions={dropDownMenuActions} />

      </div>

      <TablePaging table={table} />
    </>
  )
}