import i18n from 'i18next'

export function dateFormatter(date) {
  if(date)
    return new Date(date).toLocaleString(i18n.resolvedLanguage, { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' });
  return '';
}

export function dateAsNumbersWithoutSeconds(date) {
  if (date)
    return new Date(date).toLocaleString(i18n.resolvedLanguage, { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' });
  return '';
}

export function dateOnlyFormatter(date) {
  if (date)
    return new Date(date).toLocaleString(i18n.resolvedLanguage, { year: 'numeric', month: 'long', day: 'numeric'});
  return '';
}

export function dateOnlyAsNumbers(date) {
  if (date)
    return new Date(date).toLocaleString(i18n.resolvedLanguage, { year: 'numeric', month: 'numeric', day: 'numeric' });
  return '';
}

export function timeOnlyFormatter(date) {
  if (date)
    return new Date(date).toLocaleString(i18n.resolvedLanguage, { hour: 'numeric', minute: 'numeric' });
  return '';
}

export function highchartsLabelFormatterFromMs(value, hideMs) {
  let date = new Date(value);
  let msPostfix = hideMs ? '`' : `.${Math.round(date.getUTCMilliseconds() / 10)}''`;

  if (date.getUTCFullYear() < 1980) {
    //we assume that the date is after 2000 as UTC starts after 1980
    //So, if getUTCFullYear() less than 1980, then we consider the time starts from 0
    //and we show values only if they are more than 0
    if (date.getUTCDate() > 1)
      return `${date.getUTCDate() - 1}d ${date.getUTCHours()}h ${date.getUTCMinutes()}' ${date.getUTCSeconds()}${msPostfix}`;
    else {
      if (date.getUTCHours() > 0)
        return `${date.getUTCHours()}h ${date.getUTCMinutes()}' ${date.getUTCSeconds()}${msPostfix}`;
      else {
        if (date.getMinutes() > 0)
          return `${date.getUTCMinutes()}' ${date.getUTCSeconds()}${msPostfix}`;
        else
          return `${date.getUTCSeconds()}${msPostfix}`
      }
    }
  } else {
    //return `${date.getUTCHours().toString().padStart(2, '0')}:${date.getUTCMinutes().toString().padStart(2, '0')}:${date.getUTCSeconds().toString().padStart(2, '0') }.${Math.round(date.getUTCMilliseconds() / 10)}''`;
    let time = new Date(date).toLocaleString(i18n.resolvedLanguage, { hour: 'numeric', minute: 'numeric', second: 'numeric' });
    time = time + `${msPostfix}`;
    return time;
  }
}

export function highchartsLabelFormatterWithoutMsFromMs(value) {
  return highchartsLabelFormatterFromMs(value, true);
}