import React, { useState, useEffect } from 'react';
import api from "../../../services/api";
import { useTranslation } from 'react-i18next';
import { Button, Alert } from 'react-bootstrap';
import { dateFormatter } from '../../../utils/dateFormatter'
import {recordLengthFormatter} from '../../../utils/recordLengthFormatter'
import { useNavigate } from 'react-router-dom';


import Loading from '../Loading'

import { FormEditLine, SeparateRowForButtonOnly } from '../CommonForm/CommonForm';
import Form from 'react-bootstrap/Form';
import { useToastContext } from "../../../toasts/toast-context";
import { Formik } from "formik";
import * as Yup from "yup";


export default function UserEditRecord({ userUid, recordUid, baseEditRecordUrl }) {
  const { t } = useTranslation();
  let navigate = useNavigate();  

  const [loading, setLoading] = useState(true);

  const [receivedData, setReceivedData] = useState();
  const { showSuccessToastMessage, showErrorToastMessage } = useToastContext();
  const [isSending, setIsSending] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    console.log(baseEditRecordUrl);
    function loadRecordData() {
      api
        .get(`${baseEditRecordUrl}/${recordUid}/edit`)
        .then((response) => {          
          setReceivedData(response.data);

          setLoading(false);
        }, (error) => {
          setLoading(false);
        });
    };

    loadRecordData();

  }, [recordUid, userUid]);

  const handleSubmit = async (data, form) => {
    setIsSending(true);
    setMessage("");

    api
      .post(`${baseEditRecordUrl}/${recordUid}/edit`, data, { formik: form, setErrorMessage: setMessage })
      .then((response) => {
        showSuccessToastMessage(t('recordEdit.messages.success'));
        navigate(-1);
      },
        (error) => {
          setLoading(false);
          setIsSending(false);
          showErrorToastMessage(t('recordEdit.messages.error'));
        });
  }

  if (loading)
    return <Loading />;


  return (
    <>
      <Formik
        initialValues={{
          uid: receivedData.uid,
          description: receivedData.description,
          branch: receivedData.branch,
          recordName: receivedData.recordName,
          place: receivedData.place,
          recordTypeName: receivedData.recordTypeName,
          date: receivedData.date,
          serialNumber: receivedData.serialNumber,
          recordLengthSeconds: receivedData.recordLengthSeconds,
        }}
        validationSchema={Yup.object({
          description: Yup.string()
            .required(t("validation.required"))
            .max(1000, t("validation.maxLength1000")),
          branch: Yup.string(),
          recordName: Yup.string(),
          place: Yup.string(),
          recordTypeName: Yup.string(),
          date: Yup.string(),
          serialNumber: Yup.string(),
          recordLengthSeconds: Yup.string()
        })}
        onSubmit={handleSubmit}>
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>           
            <FormEditLine xxlWide displayName={t('recordView.details.branch')}>
              <Form.Group controlId="validationFormik02">
                <Form.Control
                  type="text"
                  name="branch"
                  value={values.branch}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isInvalid={touched.branch && !!errors.branch}
                  disabled
                />
                <Form.Control.Feedback type="invalid" className="mb-4">{errors.branch}</Form.Control.Feedback>
              </Form.Group>
            </FormEditLine>   

            <FormEditLine xxlWide displayName={t('recordView.details.recordName')}>
              <Form.Group controlId="validationFormik02">
                <Form.Control
                  type="text"
                  name="recordName"
                  value={values.recordName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isInvalid={touched.recordName && !!errors.recordName}
                  disabled
                />
                <Form.Control.Feedback type="invalid" className="mb-4">{errors.recordName}</Form.Control.Feedback>
              </Form.Group>
            </FormEditLine>   

            <FormEditLine xxlWide displayName={t('recordView.details.recordPlace')}>
              <Form.Group controlId="validationFormik02">
                <Form.Control
                  type="text"
                  name="place"
                  value={values.place}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isInvalid={touched.place && !!errors.place}
                  disabled
                />
                <Form.Control.Feedback type="invalid" className="mb-4">{errors.place}</Form.Control.Feedback>
              </Form.Group>
            </FormEditLine>   

            <FormEditLine xxlWide displayName={t('recordView.details.recordType')}>
              <Form.Group controlId="validationFormik02">
                <Form.Control
                  type="text"
                  name="place"
                  value={values.recordTypeName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isInvalid={touched.recordTypeName && !!errors.recordTypeName}
                  disabled
                />
                <Form.Control.Feedback type="invalid" className="mb-4">{errors.recordTypeName}</Form.Control.Feedback>
              </Form.Group>
            </FormEditLine>   

            <FormEditLine xxlWide displayName={t('recordView.details.date')}>
              <Form.Group controlId="validationFormik02">
                <Form.Control
                  type="text"
                  name="place"
                  value={dateFormatter(values.date)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isInvalid={touched.date && !!errors.date}
                  disabled
                />
                <Form.Control.Feedback type="invalid" className="mb-4">{errors.date}</Form.Control.Feedback>
              </Form.Group>
            </FormEditLine>  

            <FormEditLine xxlWide displayName={t('recordView.details.length')}>
              <Form.Group controlId="validationFormik02">
                <Form.Control
                  type="text"
                  name="place"
                  value={recordLengthFormatter(values.recordLengthSeconds)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isInvalid={touched.recordLengthSeconds && !!errors.recordLengthSeconds}
                  disabled
                />
                <Form.Control.Feedback type="invalid" className="mb-4">{errors.recordLengthSeconds}</Form.Control.Feedback>
              </Form.Group>
            </FormEditLine>  

            <FormEditLine xxlWide displayName={t('recordView.details.description')}>
              <Form.Group controlId="validationFormik01">
                <Form.Control
                  as="textarea"
                  rows={4}
                  name="description"
                  value={values.description}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isInvalid={touched.description && !!errors.description}
                //className={styles.input}
                />                
                <Form.Control.Feedback type="invalid" className="mb-4">{errors.description}</Form.Control.Feedback>
              </Form.Group>
            </FormEditLine>   

            <SeparateRowForButtonOnly>
              <Button variant="outline-primary" className="me-3" disabled={isSending} onClick={()=>navigate(-1)}>
                <span> {t('common.cancelButton')}</span>
              </Button>

              <Button variant="primary" className="" type="submit" disabled={isSending}>
                {isSending && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span> {t('common.saveChanges')}</span>
              </Button>
            </SeparateRowForButtonOnly>

            {message && (
              <Alert key="danger" variant="danger" className="mt-3">
                {message}
              </Alert>
            )}
          </Form>
        )}

      </Formik>     
      
    </>
  );
}

