import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { dateFormatter } from '../../../utils/dateFormatter'

function DeviceHeaderInfo({ record }) {
  const { t } = useTranslation();

  return (
    <>
      { record &&
        <>
          <Row className="h4 mt-2">
            <Col>{t('metrology.recordInfo.header')}</Col>
          </Row>
          <Row className="mt-3">
            <Col>{t('metrology.recordInfo.date')} : {dateFormatter(record.dateCreatedUtc)}</Col>
            <Col>{t('metrology.recordInfo.metrologistName')} : {record.metrologistName}</Col>
          </Row>
        </>
      }
    </>
  );
}

export default DeviceHeaderInfo;
