import React, { useState, useEffect } from 'react';

//import { useParams } from 'react-router-dom';

import ExpertActiveConsultations from '../../components/Consultation/ExpertActiveConsultations'

import MainBlockHeader from '../MainBlockHeader'
import MainBlockContainer from '../MainBlockContainer';
import { useTranslation } from 'react-i18next';

export default function ActiveConsultations() {  
  //let { userUid } = useParams();
  const { t } = useTranslation();

  return (
    <>              
      <MainBlockHeader header={t('consultation.expert.activeList.header')} >
      </MainBlockHeader>

      <MainBlockContainer>      
        <ExpertActiveConsultations />
      </MainBlockContainer>
    </>
  );  
}

