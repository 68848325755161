import React, { useState, useEffect, useRef } from 'react';
import api from "../../../services/api";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CommonHighchartsHistogram } from '../Ecg/Histogram/CommonHighchartsHistogram'

export function HRHistogramChart({ displayName, data, chartName }) {
  const { t } = useTranslation();

  const [dataTotal, setDataTotal] = useState(0);
  const chartRef = useRef(null);
  
  //const yAxisSettings = [{ title: { text: 'HR, bpm' }, labels: { format: '{value} bpm' }, opposite: false }];

  const chartOptions = {
    title: {
      text: t('recordView.processedData.charts.hrHistogram.header')
    },

    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key} bpm</span>: ',
      pointFormat: '<b>{point.y:.0f}</b>',
      footerFormat: '',
      shared: true,
      useHTML: true,
      visible: true
    },
    legend: {
      enabled: false
    },   
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
          formatter: function () {
            if (dataTotal !== 0) {
              var pcnt = (this.y / dataTotal) * 100;
              return pcnt.toFixed(1) + '%';              
            }
            return '';
          }
        }
      }
    }
  };

  useEffect(() => {
    //console.log(displayName);

    if (data) {      
      let total = 0;
      data.forEach(item => total += item.y);
      setDataTotal(total);

      chartRef.current.setInitialData(data);
    }
  }, []);

  return (
    <>
      <h3>{displayName}</h3>
      <CommonHighchartsHistogram ref={chartRef} seriesName={t('recordView.processedData.charts.hrHistogram.axisName')} chartOptions={chartOptions} />
    </>
  );
}

