import React, { useState, useEffect } from "react";

import AuthService from "../../services/auth.service";
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';

import { Form, Button, Alert, InputGroup, FloatingLabel } from "react-bootstrap"
import { Formik } from "formik";
import * as Yup from "yup";

import styles from './Auth.module.scss';

const Login = (props) => {
  const { t } = useTranslation();
  
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  const [showPass, setShowPass] = useState(false);

  //const [username, setUsername] = useState("");
  //const [password, setPassword] = useState("");

  const handleLogin = async (data, form) =>  {
    //console.log(data); console.log(form);
    
    setMessage("");
    setLoading(true);

    AuthService.login(data.username, data.password)
      .then( () => {
        navigate("/");
        window.location.reload();
      },
        (error) => {

          if (error.response.data && error.response.data.errorCode === 101)
            form.setErrors(error.response.data.errors);
          else {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            setMessage(resMessage);

            console.log(error.response);
          }

        //console.log("Processing validation")
        //if (error.response.data.errorCode == 101)
        //  form.setErrors(error.response.data.errors);

        setLoading(false);
      }
    )
      .catch(err => {
      //  console.log("caught");
      //console.log(err);
      });    
  };

  useEffect(() => {

  }, []);

  const showPassClickHandler = (e) => {
    setShowPass(!showPass);
  };

  return (
    <>
      <h1 className={`mb-3 text-center ${styles.header}`}>{t('auth.signin')}</h1>
      <Formik
        initialValues={{
          username: "",
          password: "",
        }}
        validationSchema={Yup.object({
          username: Yup.string()
            .email(t("validation.invalidEmail"))      
            .required(t("validation.required")),
          password: Yup.string()            
            .required(t("validation.required")),
        })}
        onSubmit={handleLogin}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>

            <Form.Group className={`form-floating ${styles.inputWrapper}`}>
              <Form.Control
                type="text"
                name="username"
                placeholder="name@example.com"
                value={values.username}
                onBlur={handleBlur}
                onChange={handleChange}
                isInvalid={touched.username && !!errors.username}
                className={styles.input}
              />
              <Form.Label>{t('auth.emailaddress')}</Form.Label>
              <Form.Control.Feedback type="invalid" className="mb-4">{errors.username}</Form.Control.Feedback>
            </Form.Group>                     

            <InputGroup className="mb-3">              
              <FloatingLabel label={t('auth.password')}>
                <Form.Control
                  type={showPass ? "text" : "password"}
                  name="password"
                  placeholder="Password"
                  value={values.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isInvalid={touched.password && !!errors.password}
                  className={styles.input}
                />                
                {/*<Form.Control.Feedback type="invalid" className="mb-4">{errors.password}</Form.Control.Feedback>*/}
              </FloatingLabel>
              <InputGroup.Text id="basic-addon1" onClick={showPassClickHandler} className={styles.showPassIcon}>
                <i  className={showPass ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
              </InputGroup.Text>
            </InputGroup>
            

            <p className="mt-3">
              <a href="/forgot-password">{t('auth.forgotPassword')}</a>
            </p>

            <Button className={`w-100 btn-lg ${styles.submitButton}`} type="submit" disabled={loading}>
              {loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              <span> {t('auth.login')}</span>
            </Button>
            {message && (
              <Alert key="danger" variant="danger" className="mt-3">
                {message}
              </Alert>
            )}
        </Form>
      )}
    </Formik>
     
      <p className="mt-3">
        {t("auth.notYetRegistered")}<br />
        <a href="/register">{t("auth.registerHere")}</a>
      </p>
    </>    
  );
}

export { Login };