import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import api from "../../../services/api";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BreathingHighcharts } from '../Ecg/BreathingHighcharts'
import { BreathingChart } from './BreathingChart'

import { Col, Row } from 'react-bootstrap';

import { EcgChart } from './EcgChart'
import { CommonLineChart } from './CommonLineChart'
import { HRChart } from './HRChart'
import { RRChart } from './RRChart'
import { SIChart } from './SIChart'
import { HRHistogramChart } from './HRHistogramChart'
import { RRHistogramChart } from './RRHistogramChart'
import { ScatterChart } from './ScatterChart'
import { HRSpecterChart } from './HRSpecterChart'
import { RRSpecterChart } from './RRSpecterChart'
import { AverageIntervalChart } from './AverageIntervalChart'

import { AllProcessedDataValues } from './AllProcessedDataValues'

import Loading from '../Loading'

import styles from './AllProcessedData.module.scss'
import { AllProcessedDataSmallCharts } from './AllProcessedDataSmallCharts';
export const AllProcessedData = forwardRef((props, ref) => {  

  useImperativeHandle(ref, () => {
    return {
      setLastProcessedTime(lastProcessedTime) {
        //setUpdatedMaxLengthSeconds(lastProcessedTime);
        if (lastProcessedTime !== lastProcessedTimeValue.current) {
          processedDataSmallChartsRef.current?.setLastProcessedTime(lastProcessedTime);
          lastProcessedTimeValue.current = lastProcessedTime;
        }
      }
    };
  }, []);

  const lastProcessedTimeValue = useRef(0);

  const { t } = useTranslation();

  const [data, setData] = useState([]);
  const [valuesAll, setValuesAll] = useState({});

  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(null);
  const [initialMaxLengthSeconds, setInitialMaxLengthSeconds] = useState(0);

  const processedDataSmallChartsRef = useRef(null);
  //const [isProcessing, setIsProcessing] = useState(false);
  //const [processingCompleteness, setProcessingCompleteness] = useState(0);
  //const [isRealTime, setIsRealTime] = useState(false);  

  const ChartDataTypes = {
    //EcgWithRrIntervals: 10,
    HR: 20,
    //HRHistogram: 30,
    RR: 40,
    //RRHistogram: 50,
    SI: 60,
    //Scatter: 70,
    //HRSpecter: 80,
    //RRSpecter: 90,
    //EcgAverageInterval: 100
  }


  useEffect(() => {

    if (props.requestUrl)
      loadProcessedData();
  }, [props.requestUrl]);

  function loadProcessedData() {
    api
      .get(`${props.requestUrl}`)
      .then((response) => {
        if (response.data.processingState?.isStillProcessing && !response.data.processingState?.isOnlineProcessing) {
          setProcessing(response.data.processingState);
          setLoading(false);
          setTimeout(loadProcessedData, 3000);
        } else {
          setData(response.data.chartList);
          setValuesAll(response.data.values);
          setInitialMaxLengthSeconds(response.data.values.totalRecordLengthSeconds);
          setProcessing(null);
          setLoading(false);
        }
      }, (error) => {
        setLoading(false);
      });
  };


  if (loading)
    return <Loading />;

  const getDataByType = (chartType) => data.find(x => x.chartType === chartType);

  //const EcgComponent = () => {
  //  let x = getDataByType(ChartDataTypes.EcgWithRrIntervals);
  //  console.log(x);
  //  return (
  //    <>
  //      {x && <EcgChart data={x.data} longRecord={x.longRecord} displayName={x.displayName} plotLinesX={x.plotLinesX} asyncDataLoadUrl={`${requestUrl}/partial?type=${ChartDataTypes.EcgWithRrIntervals}`} />}
  //    </>
  //  );
  //}

  const HRComponent = () => {
    let x = getDataByType(ChartDataTypes.HR);
    return (
      <>
        {x && <HRChart data={x} displayName={x.displayName} plotLinesX={x.plotLinesX} requestUrl={`${props.requestUrl}/partial?type=20`} />}
      </>
    );
  }

  const RRComponent = () => {
    let x = getDataByType(ChartDataTypes.RR);
    return (
      <>
        {x && <RRChart data={x} displayName={x.displayName} plotLinesX={x.plotLinesX} requestUrl={`${props.requestUrl}/partial?type=40`} />}
      </>
    );
  }

  const SIComponent = () => {
    let x = getDataByType(ChartDataTypes.SI);
    return (
      <>
        {x && <SIChart data={x} displayName={x.displayName} plotLinesX={x.plotLinesX} requestUrl={`${props.requestUrl}/partial?type=60`} />}
      </>
    );
  }

  return (
    <>
      <h2 className="h2">{t('recordView.processedData.headerAll')}</h2>

      {processing?.isStillProcessing && !processing?.isOnlineProcessing && <p><em>{t('common.stillProcessing')} {processing?.completedPercentage}% {t('common.stillProcessingCompleted')}</em></p>}
      {!data && <p><em>{t('common.isRealTime')}</em></p>}
      {data &&
        <>
          {/*<AllProcessedDataValues values={valuesAll} />*/}

          {/*<EcgComponent />*/}
          <Row className="py-3">
            <Col className={styles.oneChart}>
              <HRComponent />
            </Col>
            <Col className={styles.oneChart}>
              <RRComponent />
            </Col>
            <Col className={styles.oneChart}>
              <SIComponent />
            </Col>
          </Row>

        <AllProcessedDataSmallCharts ref={processedDataSmallChartsRef} requestUrl={props.requestUrl} totalRecordLengthSeconds={initialMaxLengthSeconds} />
        </>
      }
    </>
  );
});

