import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import FileUpload from '../Helpers/FileUpload'
import api from "../../services/api";
import CurrentUserService from '../../services/currentUser.service';

function Upload() {

  let { userUid } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    CurrentUserService.setCurrentUser(userUid);
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    //logic to create a new user...
    console.log(newUserInfo);

    console.log(event);
    const formData = new FormData();
    //formData.append("files[]", newUserInfo.profileImages);
    newUserInfo.profileImages.map((file) => (
      formData.append("files", file, file.name)
    ));

    api
      .post(`/user/${userUid}/records`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        navigate(`/user/${userUid}`);
      })
      .catch((err) => {
        console.log(err);
      });

  };

  const [newUserInfo, setNewUserInfo] = useState({
    profileImages: []
  });

  const updateUploadedFiles = (files) =>
    setNewUserInfo({ ...newUserInfo, profileImages: files });


  return (    
    <>
      <form onSubmit={handleSubmit}>
        <FileUpload
          //accept=".jpg,.png,.jpeg"
          accept=".json"
          label="File(s)"
          multiple
          updateFilesCb={updateUploadedFiles}
        />
        <button type="submit">Upload</button>
      </form>
      </>
  );
};


export default Upload