import React, { useState, useEffect, useMemo } from 'react';
import { Form, Button, Modal, Alert, Col, Row, Stack, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-date-picker'
import ProfileFieldBase from './ProfileFieldBase'

import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

import { dateOnlyFormatter } from '../../../utils/dateFormatter'

import styles from './UserProfile.module.scss'

export default function ProfileFieldDate({ profileLine, isEditMode, setValue }) {
  const { t } = useTranslation();

  const [date, setDate] = useState(profileLine.value ?? new Date());

  useEffect(() => {    
  }, []);

  const onSetDate = (newDate) => {    
    setDate(newDate);

    if (setValue)
      setValue(newDate);
  };

  return (
    <>
      <ProfileFieldBase profileLine={profileLine} isEditMode={isEditMode} value={dateOnlyFormatter(profileLine.value)}>
        <DatePicker format="dd-MM-yyyy" showLeadingZeros={false} onChange={onSetDate} value={date} className={`form-control ${styles.calendarInput}`} />
      </ProfileFieldBase>
    </>
  );
}