import styles from './MainBlockHeader.module.scss'

export default function MainBlockHeader (props) {
  return (
    <div className={styles.header}>
      {/*<div className={styles.item}></div>*/}
      <div className={styles.pageName}>{props.header}</div>
      {/*<div className={styles.item}>{props.children}</div>*/}
    </div>
  )
}