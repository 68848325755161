import React, { useState, useEffect, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { recordLengthFormatter } from '../../../utils/recordLengthFormatter';

export function AllProcessedDataValues({ values, isDetailed }) {
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <Col className="px-3">{t('recordView.processedData.values.averageHR')}: {values.averageHR}</Col>
        <Col className="px-3">{t('recordView.processedData.values.totalBeats')}: {values.totalBeats}</Col>
        <Col className="px-3">{t('recordView.processedData.values.si')}: {values.stressIndex}</Col>
      </Row>
      {isDetailed &&
        <Row>
          <Col className="px-3">{t('recordView.processedData.values.timeRange')}: {recordLengthFormatter(values.startSecond, true)} - {recordLengthFormatter(values.endSecond)}</Col>
          <Col className="px-3"></Col>
          <Col className="px-3"></Col>
        </Row>
      }
    </>
  )
}