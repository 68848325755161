import React, { useMemo, useState } from "react";
import { dateOnlyAsNumbers, timeOnlyFormatter } from '../../../utils/dateFormatter'
import { recordLengthFormatter } from '../../../utils/recordLengthFormatter'


import TableFull from '../CommonTable/TableFull'


import styles from './RecordsListTable.module.scss';
import { Button } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

import * as CommonIcons from '../../CommonIcons';

function Filter({
  column,
  table,
}) {
  const { t } = useTranslation();

  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id)

  return typeof firstValue === 'number' ? (
    <div className="d-flex space-x-2">
      <input
        type="number"
        value={column.getFilterValue()}
        onChange={e =>
          column.setFilterValue((old) => [e.target.value, old?.[1]])
        }
        placeholder={`Min`}
        className="w-24 border shadow rounded"
      />
      <input
        type="number"
        value={((column.getFilterValue())?.[1] ?? '')}
        onChange={e =>
          column.setFilterValue((old) => [old?.[0], e.target.value])
        }
        placeholder={`Max`}
        className="w-24 border shadow rounded"
      />
    </div>
  ) : (
    <input
      type="text"
      value={(column.getFilterValue() ?? '')}
      onChange={e => column.setFilterValue(e.target.value)}
      placeholder={`${t('common.table.search.prompt')}...`}
      className="w-36 border shadow rounded"
    />
  )
}

//Working example: https://tanstack.com/table/v8/docs/examples/react/row-selection

export default function RecordsListTable({ data, onRecordClick, resetSelection, onEditRecordClick, onMoveRecordClick, onDeleteRecordClick, onEditEcg12 }) {
  const { t } = useTranslation();

  
  //const [globalFilter, setGlobalFilter] = React.useState('')
  
  const [showOperationsColumn] = React.useState(onMoveRecordClick !== null || onDeleteRecordClick !== null || onEditEcg12 !== null);

   //const [searchParams, setSearchParams] = useSearchParams();
  //let [page, setPage] = React.useState(
  //  searchParams.get("page")
  //);

  // Use the useTable Hook to send the columns and data to build the table
  //const user = useRecoilValue(selectedUserState);

  const onRecordClickHandler = (record) => {
    console.log(record);
    onRecordClick(record);
  //  navigate(`/user/${userUid}/record/${record.uid}`);
  };

  const columnArray =
    [
      //{
      //  header: "#",
      //  className: styles.checkCell,
      //  enableColumnFilter: false,
      //  cell: ({ row }) => (
      //    <div>
      //      {row.index+1}
      //    </div>
      //  )
      //},
      {
        header: t('recordList.table.nameName'),
        accessorKey: "displayName",
        cell: info => {
          return (
            <>
              {info.row.original.realTimeState === 10 && <i className={`bi bi-circle-fill ${styles.waitingDataCircle}`}></i>}
              {info.row.original.realTimeState === 20 && <i className={`bi bi-circle-fill ${styles.liveDataCircle}`}></i>}
              <span className="ps-3">{info.getValue()}</span>
              {/* <Button variant="link" onClick={() => onRecordClickHandler(info.row.original)}>{info.getValue()}</Button> */}
            </>
          )
        },
        allowWrap: true,
        meta: {
          getCellContextInner: (context) => {            
            //console.log(context);            
              return {
                style: { textWrap: 'nowrap'}
              }            
          }
        }
      },
      {
        id: 'date-date',
        header: t('recordList.table.date'),
        accessorKey: "date",
        enableColumnFilter: false,
        cell: props => <>{dateOnlyAsNumbers(props.getValue())}</>
      },
      {
        id: 'date-time',
        header: t('recordList.table.time'),
        accessorKey: "date",
        enableColumnFilter: false,
        cell: props => <>{timeOnlyFormatter(props.getValue())}</>
      },
      {
        header: t('recordList.table.length'),
        accessorKey: "recordLengthSeconds",
        enableColumnFilter: false,
        cell: props => <span>{recordLengthFormatter(props.getValue())}</span>,
      },
      {
        header: t('recordList.table.recordType'),
        accessorKey: "recordTypeName",
        enableColumnFilter: false
      },
      {
        header: t('recordList.table.branch'),
        accessorKey: "branch",
        enableColumnFilter: false
      },
      {
        header: t('recordList.table.recordName'),
        accessorKey: "recordName",
      },
      {
        header: t('recordList.table.recordPlace'),
        accessorKey: "place",
      },
      {
        header: t('recordList.table.description'),
        accessorKey: "description",
        allowWrap: true
      }      
    ];


  const ShowInEcg12Button = ({ row }) => {
    const [value, setValue] = useState(row.isShowInEcg12);
    const [canBeChanged] = useState(row.canBeUsedForEcg12);

    const onClick = (isShow) => {
      setValue(isShow);
      row.isShowInEcg12 = isShow;      

      onEditEcg12(row, callbackResult);
    };

    const callbackResult = (data) => {      
      if (!data)
        setValue(false);
    };

    return (
      <>
        <div className={styles.actionButton}>
          {canBeChanged && <>
            {
              !value &&
              <Button className={styles.btnHoverable} variant="" onClick={(e) => { e.stopPropagation(); onClick(true); }} title={t('recordList.actions.addToEcg12')}>
                <span className={styles.btnNoHover}><CommonIcons.AddToEcg12 className={styles.icon} /></span><span className={styles.btnHover}><CommonIcons.AddToEcg12Hover className={styles.icon} /></span>
              </Button>
            }
            {
              value &&
              <Button className={styles.btnHoverable} variant="" onClick={(e) => { e.stopPropagation(); onClick(false); }} title={t('recordList.actions.removeFromEcg12')} >
                <span className={styles.btnNoHover}><CommonIcons.RemoveFromEcg12 className={styles.icon} /></span><span className={styles.btnHover}><CommonIcons.RemoveFromEcg12Hover className={styles.icon} /></span>
              </Button>
            }
          </>
          }
          {!canBeChanged &&
            <Button variant="" onClick={(e) => { e.stopPropagation() }} title={t('recordList.actions.cannotAddToEcg12')}>
              <CommonIcons.AddToEcg12Disabled className={styles.icon} />
            </Button>
          }
        </div>        
      </>
    )
  };

  if (showOperationsColumn)
    columnArray.push(
      {
        id: 'operations',
        header: t('recordList.table.action'),
        hideOnMobile: true,
        accessorKey: "uid",
        show: false,
        cell: ({ row }) => (
          <div className={styles.actionButtons}>
            {onEditEcg12 &&
              <ShowInEcg12Button row={row.original} />
            }
            {onEditRecordClick &&
              <div className={styles.actionButton}>
                <Button variant="" onClick={(e) => { e.stopPropagation(); onEditRecordClick(row.original); }} title={t('recordList.actions.edit')} >
                  <CommonIcons.PensilEdit className={styles.icon} />
                </Button>
              </div>
            }
            {onMoveRecordClick &&
              <div className={styles.actionButton}>
                <Button variant="" onClick={(e) => { e.stopPropagation(); onMoveRecordClick(row.original); }} title={t('recordList.actions.move')} >
                  <CommonIcons.MoveRecord className={styles.icon} />
                </Button>
              </div>
            }
            {onDeleteRecordClick &&
              <div className={styles.actionButton}>
                <Button variant="" onClick={(e) => { e.stopPropagation(); onDeleteRecordClick(row.original); }} title={t('recordList.actions.delete')}>
                  <CommonIcons.TrashCan className={styles.icon} />
                </Button>
              </div>
            }
          </div>
        )
      }
    );

  const columns = useMemo(
    () => columnArray,
    [t]
  );

  const initialStateSettings = {
    hiddenColumns: ['operations']

  };



  const dropDownMenuActions = [];
  if (onMoveRecordClick)
  {
    dropDownMenuActions.push({
      as: "button",
      onClick: row => onMoveRecordClick(row),
      text: t('recordList.actions.move')
    });
  };
  if (onDeleteRecordClick) {
    dropDownMenuActions.push({
      as: "button",
      onClick: row => onDeleteRecordClick(row),
      text: t('recordList.actions.delete')
    });
  }; 

  return (
    <>
      <TableFull data={data}
        columns={columns}
        styles={styles}
        onRowClick={onRecordClickHandler}
        dropDownMenuActions={dropDownMenuActions}
        resetSelection={resetSelection}
        initialStateSettings={initialStateSettings}
        mainTableClassName={showOperationsColumn ? styles.table : styles.tableNoOperations}
      />

    </>
  );
}


 //<p>Selected Rows: {Object.keys(selectedRowIds).length}</p>
 //     <pre>
 //       <code>
 //         {JSON.stringify(
 //           {
 //             selectedRowIds: selectedRowIds,
 //             'selectedFlatRows[].original': selectedFlatRows.map(
 //               d => d.original
 //             ),
 //           },
 //           null,
 //           2
 //         )}
 //       </code>
 //     </pre>