import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import UserDiary from '../../../components/Shared/Diary/UserDiary'

import MainBlockContainer from '../../MainBlockContainer';
import MainBlockHeader from '../../MainBlockHeader'
import { useTranslation } from 'react-i18next';

export default function Diary() {  
  let { consultationUid } = useParams();
  const { t } = useTranslation();

  //useEffect(() => {   
    
  //}, []);


  return (
    <>              
      <MainBlockHeader header={t('diary.header')} />

      <MainBlockContainer>
        <UserDiary apiDiaryBaseUrl={`/consultation/expert/accepted/${consultationUid}/user/diary`} />
      </MainBlockContainer>
    </>
  );  
}

