import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import UserEcg12 from '../../components/Shared/Ecg12/UserEcg12'

import MainBlockContainer from '../MainBlockContainer';
import MainBlockHeader from '../MainBlockHeader'

import { useRecoilValue } from 'recoil';
import { selectedUserState } from '../../states/selectedUserState';
import UserPermissions from '../../services/user.permissions.service';

import { useTranslation } from 'react-i18next';

export default function Diary() {  
  let { userUid, recordUid } = useParams();
  const { t } = useTranslation();
  const user = useRecoilValue(selectedUserState);

  //useEffect(() => {   
    
  //}, []);


  return (
    <>              
      <MainBlockHeader header={t('ecg12.header')} />

      <MainBlockContainer>
        <UserEcg12 apiEcg12BaseUrl={`/user/${userUid}/ecg12`}/>      
      </MainBlockContainer>
    </>
  );  
}

