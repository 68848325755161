import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import UsersConsultationList from '../../components/Shared/Consultation/UsersConsultationList'

import MainBlockHeader from '../MainBlockHeader'
import MainBlockContainer from '../MainBlockContainer';
import { useTranslation } from 'react-i18next';

export default function Consultations() {  
  let { userUid } = useParams();
  const { t } = useTranslation();

  return (
    <>              
      <MainBlockHeader header={t('consultation.user.header')} >
      </MainBlockHeader>

      <MainBlockContainer>      
        <UsersConsultationList
          consultationListBaseUrl={`/user/${userUid}/consultations`}
          getPageUserRecordUrlTemplate={(userUid, recordUid) => `/user/${userUid}/records/${recordUid}`} 
        />
      </MainBlockContainer>
    </>
  );  
}

