import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import DeviceItemView from '../../components/Metrology/DeviceItemView'

import MainBlockHeader from '../MainBlockHeader'
import MainBlockContainer from '../MainBlockContainer';
import { useTranslation } from 'react-i18next';

export default function DevicesRecordItemView() {    
  let { deviceUid, recordUid, itemUid } = useParams();

  const { t } = useTranslation();

  return (
    <>              
      <MainBlockHeader header={t('metrology.itemView.pageTitle')} >
      </MainBlockHeader>

      <MainBlockContainer>        
        <DeviceItemView getItemRecordApiUrl={`/settings/admin/device-list/${deviceUid}/records/${recordUid}/items/${itemUid}/record`} /> 
      </MainBlockContainer>
    </>
  );  
}

