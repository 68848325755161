import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import DeviceEdit from '../../components/Metrology/DeviceEdit'

import MainBlockHeader from '../MainBlockHeader'
import MainBlockContainer from '../MainBlockContainer';
import { useTranslation } from 'react-i18next';

export default function DeviceAddPage() {  
  let { deviceUid } = useParams();

  const { t } = useTranslation();

  return (
    <>              
      <MainBlockHeader header={t('metrology.edit.addPageTitle')} >
      </MainBlockHeader>

      <MainBlockContainer>        
        <DeviceEdit
          getDeviceEditUrl={`/operator/device-list/save`}
          baseUrl='/operator/device-list'
          isNew={true}
        /> 
      </MainBlockContainer>
    </>
  );  
}

