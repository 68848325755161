import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';

//https://github.com/iannbing/react-simple-tree-menu
//react-simple-tree-menu

export default DropdownDisplayTime;

function DropdownDisplayTime({ onSelectionChangedCallback }) {
  const { t } = useTranslation();

  const [dropDownValue, setDropDownValue] = useState(t('recordView.multiChart.displayTime.fromZero'));

  const onSelectionChanged = function (eventKey) {
    //setValue(eventKey);

    if (onSelectionChangedCallback) {
      let mode = eventKey === "10" ? 0 : 1;
      onSelectionChangedCallback(mode);
    }
  }

  return (   
    <>
      <div className="text-nowrap">
        <label>{t('recordView.multiChart.displayTime.header')}:</label>
        <Dropdown onSelect={(eventKey) => { onSelectionChanged(eventKey) }} className="d-inline px-2">
          <Dropdown.Toggle variant="secondary" id="dropdown-basic">{dropDownValue}</Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item eventKey="10"><div onClick={(e) => setDropDownValue(e.target.textContent)}>{t('recordView.multiChart.displayTime.fromZero')}</div></Dropdown.Item>
            <Dropdown.Item eventKey="20"><div onClick={(e) => setDropDownValue(e.target.textContent)}>{t('recordView.multiChart.displayTime.actual')}</div></Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>        
      </div>
    </>
  );
}
