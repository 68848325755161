import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Row, Col, Container, Alert } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from "yup";
import CheckBox from '../CheckBox'
import OneUserLoginPermissions from './OneUserLoginPermissions'


//https://github.com/iannbing/react-simple-tree-menu
//react-simple-tree-menu

export default MyUsersPermissionsAdd;


function MyUsersPermissionsAdd({ isGroup, onSavePersmissions }) {
  //const user = useRecoilValue(selectedUserState);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
  }, []);

  const handleSubmit = function (data, form) {
    setLoading(true);
    onSavePersmissions(data).then(() => {
      setLoading(false);
      setMessage(null);
    }, (error) => {
      console.log('cant save: ', error);
      setLoading(false);
      setMessage(t('settings.userPermissions.notExistingUserError'));
    });
  }

  const allPermissions = ['isUserOwner', 'isGroupOwner', 'canViewUser', 'canEditUser', 'canViewData', 'canEditData', 'canViewConsultations', 'canRequestConsultations', 'canViewFiles', 'canEditFiles', 'canViewDiary', 'canEditDiary', 'canViewUserDevices'];
  const initialValues = {
    email: '',
    permissions: {}
  };
  allPermissions.forEach(p => initialValues.permissions[p] = false);
  initialValues.permissions.canViewUser = true;


  return (
    <>
      <div className="p-2  w-100 flex-fill bd-highlight">
        <Container>
          {isGroup && <Row><Col><b>{t('settings.userPermissions.group.add')}</b></Col></Row>}
          {!isGroup && <Row><Col><b>{t('settings.userPermissions.user.add')}</b></Col></Row>}
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object({
              email: Yup.string()
                .email(t("validation.invalidEmail"))
                .required(t("validation.required")),
            })}
            onSubmit={handleSubmit}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Row>
                  <input
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    className="form-control"
                  />
                  {errors.email && touched.email && errors.email}
                </Row>
                <Row>
                  {/*{!isGroup && <Col><CheckBox id={`1-add`} onChange={(value) => { values.isOwner = value; }} name={t('settings.userPermissions.user.isOwner')} value={values.isOwner} /></Col>}*/}
                  {/*{isGroup && <Col><CheckBox id={`2-add`} onChange={(value) => { values.isOwner = value; }} name={t('settings.userPermissions.group.isOwner')} value={values.isOwner} /></Col>}*/}
                  {/*<Col><CheckBox id={`3-add`} onChange={(value) => { values.canViewUser = value; }} name={t('settings.userPermissions.canViewUser')} value={values.canViewUser} /></Col>*/}
                  {/*<Col><CheckBox id={`4-add`} onChange={(value) => { values.canEditUser = value; }} name={t('settings.userPermissions.canEditUser')} value={values.canEditUser} /></Col>*/}
                  {/*<Col><CheckBox id={`5-add`} onChange={(value) => { values.canViewData = value; }} name={t('settings.userPermissions.canViewData')} value={values.canViewData} /></Col>*/}
                  {/*<Col><CheckBox id={`6-add`} onChange={(value) => { values.canEditData = value; }} name={t('settings.userPermissions.canEditData')} value={values.canEditData} /></Col>*/}
                  {/*<Col><CheckBox id={`7-add`} onChange={(value) => { values.canViewConsultations = value; }} name={t('settings.userPermissions.canViewConsultations')} value={values.canViewConsultations} /></Col>*/}
                  {/*<Col><CheckBox id={`8-add`} onChange={(value) => { values.canRequestConsultations = value; }} name={t('settings.userPermissions.canRequestConsultations')} value={values.canRequestConsultations} /></Col>*/}
                  {/*<Col><CheckBox id={`9-add`} onChange={(value) => { values.canViewFiles = value; }} name={t('settings.userPermissions.canViewFiles')} value={values.canViewFiles} /></Col>*/}
                  {/*<Col><CheckBox id={`10-add`} onChange={(value) => { values.canEditFiles = value; }} name={t('settings.userPermissions.canEditFiles')} value={values.canEditFiles} /></Col>*/}
                  {/*<Col><CheckBox id={`11-add`} onChange={(value) => { values.canViewDiary = value; }} name={t('settings.userPermissions.canViewDiary')} value={values.canViewDiary} /></Col>*/}
                  {/*<Col><CheckBox id={`12-add`} onChange={(value) => { values.canEditDiary = value; }} name={t('settings.userPermissions.canEditDiary')} value={values.canEditDiary} /></Col>*/}

                  <OneUserLoginPermissions
                    userLoginUid={"add"}
                    permissions={values.permissions}
                    isDisabled={false}
                    onPermissionUpdate={(perms => { allPermissions.forEach(p => values.permissions[p] = perms[p]); console.log(values); })}
                    isGroup={isGroup}
                  />

                </Row>
                {message && <Row>
                  <Alert key="danger" variant="danger" className="mt-3">
                    {message}
                  </Alert>
                </Row>}
                <Row>
                  <Col></Col>
                  <Col>
                    <Button className="w-100" type="submit" disabled={loading}>
                      {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      <span> {t('settings.userPermissions.addButton')}</span>
                    </Button>                    
                  </Col>
                </Row>
              </form>
            )}
          </Formik>
        </Container>
      </div>
    </>
  )
}
