import React, { useState, useEffect, useRef } from 'react';
import api from "../../../services/api";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CommonHighchartsScatter } from '../Ecg/Scatter/CommonHighchartsScatter'


export function ScatterChart({ displayName, data, chartName }) {
  const { t } = useTranslation();


  const chartRef = useRef(null);
  
  const yAxisSettings = [{ title: { text: 'ScatterChart' }, labels: { format: '{value} ms' }, opposite: false }];

  const scatterCellWidth = 0.3;
  const scatterMax = 2.1;
  const scatterMin = 0

  const chartOptions = {
    title: {
      text: t('recordView.processedData.charts.scatter.header')
    },
    yAxis: {
      min: 0,
      max: 2,
      title: {
        text: ''
      },
      tickInterval: scatterCellWidth,
      gridLineWidth: 1,
      gridLineColor: '#827574',
      gridLineDashStyle: 'ShortDash'
    },
    xAxis: {
      min: 0,
      max: scatterMax,
      title: {
        text:''
      },
      tickInterval: scatterCellWidth,      
      gridLineWidth: 1,
      gridLineColor: '#827574',
      gridLineDashStyle: 'ShortDash'
    },
    legend: {
      enabled: false
		},

		annotations: [{
			draggable: false,						
			shapes: [{       
        points: [{ x: 0, y: 0, xAxis: 0, yAxis: 0 },
            { x: scatterMax, y: 0, xAxis: 0, yAxis: 0 },
            { x: scatterMax, y: scatterMax, xAxis: 0, yAxis: 0 },
            { x: 0, y: scatterMax, xAxis: 0, yAxis: 0 }],          
				  type: 'path',
          fill: '#FDDBDB',
          strokeWidth: 0,
          zIndex: 1
        },
        {
          points: [{ x: scatterCellWidth, y: scatterCellWidth, xAxis: 0, yAxis: 0 },
            { x: scatterMax - scatterCellWidth, y: scatterCellWidth, xAxis: 0, yAxis: 0 },
            { x: scatterMax - scatterCellWidth, y: scatterMax - scatterCellWidth, xAxis: 0, yAxis: 0 },
            { x: scatterCellWidth, y: scatterMax - scatterCellWidth, xAxis: 0, yAxis: 0 }],
          type: 'path',
          fill: '#FFFFD7',
          strokeWidth: 0,
          zIndex:2
        },
        {
        points: [{ x: scatterCellWidth * 2, y: scatterCellWidth * 2, xAxis: 0, yAxis: 0 },
          { x: scatterMax - scatterCellWidth * 2, y: scatterCellWidth * 2, xAxis: 0, yAxis: 0 },
          { x: scatterMax - scatterCellWidth * 2, y: scatterMax - scatterCellWidth * 2, xAxis: 0, yAxis: 0 },
          { x: scatterCellWidth * 2, y: scatterMax - scatterCellWidth * 2, xAxis: 0, yAxis: 0 }],
        type: 'path',
        fill: '#DEFFE7',
        strokeWidth: 0,
        zIndex: 3
        },
        //{
        //  type: 'path',
        //  points: [{ x: 0.2, y: 0.25, xAxis: 0, yAxis: 0 },
        //    { x: 1, y: 1.3, xAxis: 0, yAxis: 0 },
        //    { x: 1.3, y: 1.7, xAxis: 0, yAxis: 0 },
        //    { x: 1.5, y: 1.9, xAxis: 0, yAxis: 0 },
        //    { x: 1.8, y: 1.8, xAxis: 0, yAxis: 0 },
        //    { x: 1.9, y: 1.5, xAxis: 0, yAxis: 0 },
        //    { x: 1.7, y: 1.3, xAxis: 0, yAxis: 0 },
        //    { x: 1.3, y: 1, xAxis: 0, yAxis: 0 },
        //    { x: 0.25, y: 0.2, xAxis: 0, yAxis: 0 },
        //    { x: 0.2, y: 0.25, xAxis: 0, yAxis: 0 }],
        //  strokeWidth: 2,
        //  zIndex: 4,
        //  fill: 'red',
        //}
      ],
			zIndex: 0
		}],
    series: [
      {
        type: 'spline',
        data: [[0.2, 0.2], [0.23, 0.26], [0.28, 0.33], [1, 1.33], [1.33, 1.66], [1.61, 1.8], 
          [1.8, 1.61], [1.66, 1.33], [1.33, 1], [0.33, 0.28], [0.26, 0.23], [0.2, 0.2]       
        ],
        enableMouseTracking: false
      }
    ]
  };

  useEffect(() => {
   // console.log(displayName);

    if (data) {

      let min = Math.min(...data.map(x => x[1]));
      let max = Math.max(...data.map(x => x[1]));

      //chartRef.current.setupYAxis(yAxisSettings);
      chartRef.current.setInitialData(data);
      //chartRef.current.setChartExtremesX(data[data.length - 1][0], 0);
      //chartRef.current.setChartExtremesYMinMax(min, max);
    }
  }, []);


 

  return (
    <>
      <h3>{displayName}</h3>
      <CommonHighchartsScatter ref={chartRef} seriesName={'HR'} chartOptions={chartOptions} />            
    </>
  );
}

