import styles from './OffcanvasMenu.module.scss';

import { useLocation} from 'react-router-dom';
import { SideMenu } from './SideMenu';

export { OffcanvasMenu };

function OffcanvasMenu({ menuType }) {

  return (
    <>      
      <div className={`offcanvas offcanvas-start ${styles.main}`} tabIndex="-1" id="navbarOffcanvas" aria-labelledby="navbarOffcanvasLabel">
        <div className="offcanvas-header">
          <a className="offcanvas-title text-decoration-none" id="navbarOffcanvasLabel" href="/"><h2><b>Delfihealth Telemed</b></h2></a>
          <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" id="closeOffcanvasButton"></button>
        </div>
        <div className="offcanvas-body">
          <SideMenu closeOffcanvas={true} menuType={menuType} />
        </div>
      </div>

    </>
    )

}
