import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Button, Alert } from 'react-bootstrap'
import api from "../../services/api";
import { useNavigate } from "react-router-dom";

import Loading from '../Shared/Loading'

import { Formik } from "formik";
import * as Yup from "yup";


export default function AddReport({ consultationUid, baseUrl, listUrl, readOnly }) {
  const { t } = useTranslation();
  const [message, setMessage] = useState("");
  const [initialLoaded, setInitialLoaded] = useState(false);
  const [initialReport, setInitialReport] = useState({});

  const [loading, setLoading] = useState(false);
  const formikRef = useRef();
  let navigate = useNavigate();

  const getUrl = () => `${baseUrl}/${consultationUid}`;

  useEffect(() => {
    api
      .get(`${baseUrl}/${consultationUid}/report`)
      .then((response) => {
        setInitialReport(response.data);
        setInitialLoaded(true);
      });

  }, [baseUrl, consultationUid]);

  const handleSaveOnly = () => {
    if (formikRef.current) {
      submitAction = "save";
      formikRef.current.handleSubmit();
    }
  }

  const handleSaveAndSend = () => {
    if (formikRef.current) {
      submitAction = "complete";
      formikRef.current.handleSubmit();
    }
  }

  let submitAction = undefined;

  const handleAddReport = async (data, form) => {
    setMessage("");
    let url = "";
    if (submitAction === "save") {
      url = `${getUrl()}/report/save`
    }
    if (submitAction === "complete") {
      url = `${getUrl()}/report/complete`
    }
    setLoading(true);
    api.post(url, { reportText: data.reportText },
      { formik: form, setErrorMessage: setMessage }
    ).then(response => {
      setLoading(false);
      navigate(`${listUrl}`);
    },
      (error) => { setLoading(false); });


  };

  if (!initialLoaded)
    return <Loading />;

  return (
    <>
      <Formik
        initialValues={{
          reportText: initialReport.reportText ,
        }}
        validationSchema={Yup.object({          
          reportText: Yup.string()
            .required(t("validation.required")),
        })}
        onSubmit={handleAddReport}
        innerRef={formikRef}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>

            <Form.Label>{readOnly ? t('consultation.expert.viewReport.header') : t('consultation.expert.addReport.header')}</Form.Label>
            <Form.Group controlId="validationFormik01">
              <Form.Control
                as="textarea"
                rows={10}
                name="reportText"
                //placeholder="name@example.com"
                value={values.reportText}
                onBlur={handleBlur}
                onChange={handleChange}
                isInvalid={touched.reportText && !!errors.reportText}
                disabled={readOnly}
              />              
              <Form.Control.Feedback type="invalid" className="mb-4">{errors.reportText}</Form.Control.Feedback>
            </Form.Group>           

            {!readOnly &&
              <>
                <Button variant="primary" className="" onClick={handleSaveOnly} disabled={loading} name="submitSaveOnly">
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span> {t('consultation.expert.addReport.saveOnly')}</span>
                </Button>

                <Button variant="success" className="" onClick={handleSaveAndSend} disabled={loading} name="submitSaveAndSend">
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span> {t('consultation.expert.addReport.saveAndSend')}</span>
                </Button>
              </>
            }
            {message && (
              <Alert key="danger" variant="danger" className="mt-3">
                {message}
              </Alert>
            )}
          </Form>
        )}
      </Formik>     
    </>
  );
}