import { Link, useLocation } from 'react-router-dom';
import styles from '../SideMenu.module.scss'

import * as Icons from '../SideMenuIcons';
import { useRecoilValue } from 'recoil';
import { selectedUserState } from '../../../states/selectedUserState';
import { useTranslation } from 'react-i18next';
import { MenuList } from './MenuList';
import { useEffect } from 'react';

import UserPermissions from '../../../services/user.permissions.service';

export { MenuListUser };
function MenuListUser({ closeOffcanvasAction, isExpertViewMode }) {
  const user = useRecoilValue(selectedUserState);
  const location = useLocation();
  const path = location.pathname;
  const { t } = useTranslation();
  let permissions = new UserPermissions(user);

  useEffect(() => {
    //console.log(user);
  });

  const userLinkList = [
    {
      link: `/user/${user?.uid}/profile`,
      text: t('sidebar.profile'),
      icon: <Icons.PaperWithIcon />,
      hasPermission: permissions.hasProfileReadPermission()
    },
    {
      link: `/user/${user?.uid}/ecg12`,
      text: t('sidebar.ecg12'),
      icon: <Icons.Ecg12 />,
      isDisabled: false,
      hasPermission: permissions.hasRecordsReadPermission()
    },
    {
      link: `/user/${user?.uid}/records`,
      text: t('sidebar.recordList'),
      icon: <Icons.Copy />,
      isActive: function () { return path.includes(this.link); },
      hasPermission: permissions.hasRecordsReadPermission()
    },
    {
      link: `/user/${user?.uid}/diary`,
      text: t('sidebar.diary'),
      icon: <Icons.Paper />,
      isActive: function () { return path.includes(this.link); },
      hasPermission: permissions.hasDiaryReadPermission()
    },
    {
      link: `/user/${user?.uid}/documents`,
      text: t('sidebar.documents'),
      icon: <Icons.File />,
      isActive: function () { return path.includes(this.link); },
      hasPermission: permissions.hasDocumentsReadPermission()
    },          
    {
      link: `/user/${user?.uid}/consultations`,
      text: t('sidebar.consultationRequests'),
      icon: <Icons.TasksWithCheckbox />,      
      hasPermission: permissions.hasConsultationsReadPermission()
    },
    {
      link: `/user/${user?.uid}/devices`,
      text: t('sidebar.userDevices'),
      icon: <Icons.Devices />,
      hasPermission: permissions.hasUserDevicesReadPermission()
    },
    {
      link: `/user/${user?.uid}/chat`,
      text: t('sidebar.chat'),
      icon: <Icons.Chat />,
      isDisabled: true,
      hasPermission: true
    }
  ];

  const userLinkListConsultationMode = [
    {
      link: `/user/${user?.uid}/profile`,
      text: t('sidebar.returnToUsers'),
      icon: <Icons.ThreePeople />,      
    }
  ];

  return (
    <>
      {!isExpertViewMode &&
        <>
          <div className={`${styles.header} ${styles.userNameWithChange}`}>
            <Icons.User />
            <div className={`${styles.userName}`}>{user.displayName}</div>

            <Link to={`/user/${user?.uid}/change`} className={`${styles.changeUser} nav-link`} aria-current="page" onClick={closeOffcanvasAction}>
              <Icons.ChangeUser />
            </Link>
          </div>
          <MenuList itemsList={userLinkList} closeOffcanvasAction={closeOffcanvasAction} />
        </>
    }

      {isExpertViewMode &&
      <>
        <div className={`${styles.header} ${styles.first}`}>
          <Icons.User />
            <div className={`${styles.userName}`}>{user.displayName}</div>
        </div>
        <MenuList itemsList={userLinkListConsultationMode} closeOffcanvasAction={closeOffcanvasAction} />
      </>
    }
</>
  );

}