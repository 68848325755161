import { Navbar, Container, Nav, } from 'react-bootstrap';
import { NavLngDropdown } from './NavLngDropdown'

import styles from './Nav.module.scss';

export { NavMenuSimple };

function NavMenuSimple() {
  return (
    <Navbar className={styles.navSimple}>
      <Container fluid>
        <Navbar.Brand className={styles.navBrandTitle} href="#home">Delfihealth Telemed</Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Nav>
            <div>
              <NavLngDropdown includeTextInHeader={true} />
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    );  
}
