import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { Col, Row } from 'react-bootstrap';
import DropdownChartSpeed from './DropdownChartSpeed';
import DropdownChartAmp from './DropdownChartAmp';
import { OnlineHighcharts } from './OnlineHighcharts'
import { useTranslation } from 'react-i18next';
import mergeDeep from '../../../utils/mergeDeep';

const EcgHighchartsForwardRef = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const chartComponentRef = useRef(null);  

  //const [chartLength, setChartLength] = useState(150);

  const EXMAPLE_SERIES_ID = 'mv-ms-test';

  useImperativeHandle(ref, () => ({
    setInitialData(data, seriesSettings, markers) {
      chartComponentRef.current.setInitialData(data, seriesSettings, markers);
    },
    setDataViewMode(mode) {
      chartComponentRef.current.setDataViewMode(mode);
    },
    addNewDataToChart(data, seriesId) {
      chartComponentRef.current.addNewDataToChart(data, seriesId);
    },
    setupYAxis(settings) {
      chartComponentRef.current.setupYAxis(settings);
    },
    scrollToEnd() {
      chartComponentRef.current.scrollToEnd();
    },
    addAnnotation(newAnnotation) {
      chartComponentRef.current.addAnnotation(newAnnotation);
    },
    setPlotLinesX(plotLinesX) {
      chartComponentRef.current.setPlotLinesX(plotLinesX);
    }
  }));

  useEffect(() => {
  }, []);

  const exampleSeries = (offsetNull) => {
    let offsetX = offsetNull ?? 0;
    let offsetY = -0.5;
    let heightY = 1;

    return {
      id: EXMAPLE_SERIES_ID,
      name: '1 mV, 2.5 Hz',
      type: 'line',
      data: [
        [offsetX + 0, offsetY],
        [offsetX + 80, offsetY],
        [offsetX + 80, offsetY + heightY],
        [offsetX + 280, offsetY + heightY],
        [offsetX + 280, offsetY],
        [offsetX + 480, offsetY],
        [offsetX + 480, offsetY + heightY],
        [offsetX + 680, offsetY + heightY],
        [offsetX + 680, offsetY],
        [offsetX + 760, offsetY],
      ],
      color: 'red',
      lineWidth: 4,
      showInNavigator: false
    }
  };

  const exampleSeriesSmall = (offsetNull) => {
    let offsetX = offsetNull ?? 0;
    let offsetY = 0;
    let heightY = 1;

    return {
      id: EXMAPLE_SERIES_ID,
      name: '1mV',
      type: 'line',
      data: [
        [offsetX + 0, offsetY],
        [offsetX + 40, offsetY],
        [offsetX + 40, offsetY + heightY],
        [offsetX + 120, offsetY + heightY],
        [offsetX + 120, offsetY],
        [offsetX + 160, offsetY]
      ],
      color: 'red',
      lineWidth: 4,
      showInNavigator: false
    }
  };

  const runOnChartLoadEvent = function (chart) {
    setTimeout(() => { try { removeAndAddExampleSeries(chart) } catch (error) { } }, 2000);
  };

  const removeAndAddExampleSeries = (chart, startPoint) => {
    if (props.hideCalibrationSeries === undefined || (props.hideCalibrationSeries !== undefined && !props.hideCalibrationSeries)) {
      if (chart.get(EXMAPLE_SERIES_ID))
        chart.get(EXMAPLE_SERIES_ID).update(exampleSeries(startPoint ?? 0));
      else {
        let s = props.calibrationSeriesType && props.calibrationSeriesType === 'small' ? exampleSeriesSmall() : exampleSeries();
        chart.addSeries(s);
      }
    }
  };

  const accumulationModeSetCallback = (chart, startTime) => {
    setTimeout(() => removeAndAddExampleSeries(chart, startTime), 500);
  }

  const refreshModeSetCallback = (chart, startTime) => {
    setTimeout(() => removeAndAddExampleSeries(chart, 0), 500);
  }

  const ecgChartOptions = {
    // To avoid unnecessary update keep all options in the state.
    chart: {
      height: '400px',
      
      //events: {
      //  load: function (x) {
      //    console.log(this);
      //    //this.xAxis[0].setExtremes(0, 10000); //show first 10 seconds
      //    chartComponentRef.current.setChartExtremesX(this, 40, 0);
      //    //this is required as otherwise the whole series is selected when panning on x axis.
      //    //Because of an extra series. Maybe add the extra series later ??
      //    //setTimeout(() => updateChart(40, 0), 2000); 
          

      //    setTimeout(() => chartComponentRef.current.setChartExtremesX(this, 40, 0), 200);
      //  }        
      //},            
      //events: {
      //  render: function () {

      //    var axes = this.axes,
      //      showMinorTicks = true;

      //    // find if minor ticks are present on both axes
      //    axes.forEach(function (a) {
      //      console.log(a.minorTicks);

      //      if (Object.keys(a.minorTicks).length === 0) {
      //        showMinorTicks = false;
      //      }
      //    });

      //    // hide/show ticks
      //    axes.forEach(function (a) {
      //      for (var key in a.minorTicks) {
      //        var mt = a.minorTicks[key].gridLine;
      //        showMinorTicks ? mt.show() : mt.hide();
      //      }
      //    });
      //  }
      //}
    },
    yAxis: {
      minorTicks: true,
      minorTickInterval: 0.1,
      minorGridLineColor: 'red',
      minorGridLineWidth: 0.3,

      tickInterval: 1,
      gridLineWidth: 1.5,
      gridLineColor: 'red',
      zoomEnabled: false,

      min: -3,
      max: 3,
      scrollbar: {
        enabled: true
      },      
      zoomEnabled: false,
    },
    xAxis: {
      minorTicks: true,
      minorTickInterval: 40,
      minorGridLineColor: 'red',
      minorGridLineWidth: 0.3,

      tickInterval: 400,
      gridLineWidth: 1.5,
      gridLineColor: 'red',

      zoomEnabled: false,

      maxRange: undefined,

      dateTimeLabelFormats: {
        millisecond: '%M:%S.%L',
        second: '%M:%S.%L',
        minute: '%M:%S.%L',
        hour: '%M:%S.%L',
        day: '%M:%S.%L',
        week: '%M:%S.%L',
        month: '%M:%S.%L',
        year: '%Y'
      },
      allowDecimals: true,      
      //  function () {
      //  var positions = [],
      //    tick = Math.floor(this.dataMin),
      //    increment = 400;

      //  if (this.dataMax !== null && this.dataMin !== null) {
      //    for (tick; tick - increment <= this.dataMax; tick += increment) {
      //      positions.push(tick);
      //    }
      //  }
      //  return positions;
      //}    
    },
    rangeSelector: {
      enabled: false
    },
    series: [      
    ],
    tooltip: {
      valueDecimals: 3,
      valueSuffix: 'mV',
      split: true,
      shared: false,
    
      xDateFormat: '%M:%S.%L'    
      //formatter() {
      //  let res = '';        
      //  //let date = new Date(this.x)
      //  //if (date.getMinutes() > 0)
      //  //  res = `${date.getMinutes()}m `;
      //  //res += `${ date.getSeconds() }.${ Math.round(date.getMilliseconds() / 10)}s`;

      //  //res = `${res} - ${Math.round(this.y * 100) / 100}mV`;

      //  res = `<b>${Math.round(this.y * 100) / 100}mV</b>`;

      //  return res;
      //}  
    },
    plotOptions: {
      series: {
        //getExtremesFromAll: true,
        point: {
          events: {
            //mouseOver: function () { setHoverData(this.category); }
          }
        }              
      },
      line: {
        dataGrouping: {
          dateTimeLabelFormats: {
            millisecond: [
              '%M:%S.%L', '%M:%S.%L', ''
            ],
            second: ['%M:%S.%L', '%M:%S.%L', ''],
            minute: ['%M:%S.%L', '%M:%S.%L', ''],
            hour: ['%M:%S.%L', '%M:%S.%L', ''],
            day: ['%M:%S.%L', '%M:%S.%L', ''],
            week: ['%M:%S.%L', '%M:%S.%L', ''],
            month: ['%M:%S.%L', '%M:%S.%L', ''],
            year: ['%M:%S.%L', '%M:%S.%L', ''],
          }
        }
      }
    }
  };

  const getOptions = () => {
    let common = ecgChartOptions;
    if (props.specificChartOptions) {
      return mergeDeep(common, props.specificChartOptions);
    }

    return common;
  }

  return (
    <>
      {(props.isHideControls === undefined || props.isHideControls === false) &&
        <Row>
          <Col lg className="mt-3">
            <DropdownChartAmp onSelectionChangedCallback={(value) => chartComponentRef.current.setChartExtremesY(value)} />
          </Col>
          <Col lg className="mt-3">
            <DropdownChartSpeed onSelectionChangedCallback={(value) => chartComponentRef.current.setChartExtremesX(value)} />
          </Col>
          <Col>
            {/*<label>Record length: {chartLength}</label>*/}

          </Col>
        </Row>
      }
      <Row>
        <Col className={`px-0 ${props.containerClassName ?? ''}`}>
          <OnlineHighcharts ref={chartComponentRef} specificChartOptions={getOptions()} seriesName={t('recordView.multiChart.seriesName.ecg')}
            onChartLoadEvent={runOnChartLoadEvent} defaultCellTimeXms={40} accumulationModeSetCallback={accumulationModeSetCallback}
            refreshModeSetCallback={refreshModeSetCallback} isStockChart={props.isStockChart} />
        </Col>
      </Row>
    </>
  )


});
export const EcgHighcharts = React.memo(EcgHighchartsForwardRef);