import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import UserViewRecords from '../../components/Shared/RecordsList/UserViewRecords'

import MainBlockHeader from '../MainBlockHeader'
import MainBlockContainer from '../MainBlockContainer';

import { useRecoilValue } from 'recoil';
import { selectedUserState } from '../../states/selectedUserState';
import UserPermissions from '../../services/user.permissions.service';

import { useTranslation } from 'react-i18next';

export default function Records() {  
  let { userUid } = useParams();
  const { t } = useTranslation();
  const user = useRecoilValue(selectedUserState);
  let permissions = new UserPermissions(user);

  return (
    <>              
      <MainBlockHeader header={t('recordList.header')} >
      </MainBlockHeader>

      <MainBlockContainer>      
        <UserViewRecords
          getRecordListUrl={`/user/${userUid}/records`}
          getPageUserRecordUrlTemplate={(userUid, recordUid) => `/user/${userUid}/records/${recordUid}`}
          allowEditRecords={permissions.hasRecordsEditPermission()}
          allowMoveRecords={permissions.hasRecordsEditPermission()}
          allowDeleteRecords={permissions.hasRecordsDeletePermission()}
          allowChangeEcg12={permissions.hasRecordsEditPermission()}
        />      
      </MainBlockContainer>
    </>
  );  
}

