import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import api from "../../services/api";
import Loading from '../Shared/Loading';
import { Form, Button, Alert } from 'react-bootstrap'
import { Formik } from "formik";
import * as Yup from "yup";
import { FormEditLine, SeparateRowForButtonOnly } from "../Shared/CommonForm/CommonForm";


export default Notifications ;

function Notifications() {
  //const user = useRecoilValue(selectedUserState);
  const { t } = useTranslation();
  const [initialLoaded, setInitialLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const formikRef = useRef();
  const [notificationSettings, setNotificationSettings] = useState({});
  const [message, setMessage] = useState("");


  useEffect(() => {
    api
      .get(`/settings/notifications`)
      .then((response) => {
        setNotificationSettings(response.data);
        setInitialLoaded(true);
      });    

  }, []);

  const handleSaveNotificationSettings = async (data, form) => {
    //console.log(data);
    //console.log(form);

    setMessage("");
    setLoading(true);

    api.post(`/settings/notifications`, { consultationResultNotifications: parseInt(data.consultationResultNotifications, 10), expertNewRequestNotifications: parseInt(data.expertNewRequestNotifications, 10) },
      { formik: form, setErrorMessage: setMessage }
    ).then(response => {
      setLoading(false);
      //navigate("/consultations/expert/accepted/list");
    },
      (error) => { setLoading(false); });

  };

  if (!initialLoaded)
    return <Loading />;

  return (
    <>
      <Formik 
        initialValues={{
          consultationResultNotifications: notificationSettings.consultationResultNotifications?.selectedValue,
          expertNewRequestNotifications: notificationSettings.expertNewRequestNotifications?.selectedValue
        }}
        validationSchema={Yup.object({
          
        })}
        onSubmit={handleSaveNotificationSettings}
        innerRef={formikRef}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>

            {notificationSettings.consultationResultNotifications && 
              <>
              <FormEditLine xxlWide displayName={t('settingsNotifications.userNotificationSettings')}>
                  <Form.Group controlId="validationFormik01">
                    <Form.Select
                      name="consultationResultNotifications"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.consultationResultNotifications && !!errors.consultationResultNotifications}
                      value={values.consultationResultNotifications}
                    >
                      {
                        notificationSettings.consultationResultNotifications.items.map(x => {
                          return <option value={x.value} key={x.value}>{x.displayName}</option>
                        })
                      }
                    </Form.Select>
                    <Form.Control.Feedback type="invalid" className="mb-4">{errors.userConsultation}</Form.Control.Feedback>
                  </Form.Group> 
                </FormEditLine>                              
              </>}

            {notificationSettings.expertNewRequestNotifications &&
              <>
              <FormEditLine xxlWide displayName={t('settingsNotifications.expertNotificationSettings')}>
                  <Form.Group controlId="validationFormik02">
                    <Form.Select
                      name="expertNewRequestNotifications"
                      value={values.expertNewRequestNotifications}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.expertNewRequestNotifications && !!errors.expertNewRequestNotifications}
                    >
                      {
                        notificationSettings.expertNewRequestNotifications.items.map(x => {
                          return <option value={x.value} key={x.value}>{x.displayName}</option>
                        })
                      }
                    </Form.Select>
                    <Form.Control.Feedback type="invalid" className="mb-4">{errors.expertNewRequestNotifications}</Form.Control.Feedback>
                  </Form.Group>
                </FormEditLine>                                  
              </>}

            <SeparateRowForButtonOnly>
              <Button variant="primary" className="" type="submit" disabled={loading}>
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span> {t('common.saveChanges')}</span>
              </Button>
            </SeparateRowForButtonOnly>

            {message && (
              <Alert key="danger" variant="danger" className="mt-3">
                {message}
              </Alert>
            )}
          </Form>
        )}
      </Formik>  
      
    </>
  )
}
