import { useTranslation } from 'react-i18next';
import styles from './TablePaging.module.scss';
export default function TablePaging({ table }) {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.pageInfo}>
        <div className={styles.rowsOnPage}>
          {t('common.table.pagination.pageRows')}: {table.getRowModel().rows.length}
        </div>      
        <div className={styles.totalRows}>
          {t('common.table.pagination.totalRecords')}{': '}{table.getPreFilteredRowModel().rows.length}
        </div> 
      </div>

      <div className={styles.pageControls} >
        <div className="d-flex align-items-center gap-3">
          <button
            className={styles.buttonArrows}
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {'<<'}
          </button>
          <button
            className={styles.buttonArrows}
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {'<'}
          </button>
          <button
            className={styles.buttonArrows}
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {'>'}
          </button>
          <button
            className={styles.buttonArrows}
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {'>>'}
          </button>
          <span className="d-flex align-items-center gap-1 text-nowrap">
            <div>{t('common.table.pagination.page')}</div>
            <strong>
              {table.getState().pagination.pageIndex + 1}{' '}{t('common.table.pagination.pageOf')}{' '}{table.getPageCount()}
            </strong>
           </span>
        </div>
        <div className={styles.moveToPageControls }>
          <span className="d-flex align-items-center gap-2 text-nowrap">
          {t('common.table.pagination.goToPage')}:
          <input
            type="number"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              table.setPageIndex(page)
            }}
            className={styles.pageNumberInput}
          />
        </span>
        <select
          className={styles.showRowsSelection }
          value={table.getState().pagination.pageSize}
          onChange={e => {
            table.setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              {t('common.table.pagination.show')} {pageSize}
            </option>
          ))}
        </select>
        </div>
      </div>
    </>
  )
}