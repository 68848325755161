import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import MainBlockContainer from '../MainBlockContainer';
import MainBlockHeader from '../MainBlockHeader'

import UsersGroupsList from '../../components/Shared/User/UsersGroupsList';
import { useTranslation } from 'react-i18next';

export default function ChangeUser() {  
  let navigate = useNavigate();  
  const { t } = useTranslation();
 
  const onUserClick = function (item) {
    navigate(`/user/${item.itemUid}`);
  };

  return (
    <>              
      <MainBlockHeader header={t('changeUser.header')} >
      </MainBlockHeader>

      <MainBlockContainer>
        <UsersGroupsList onUserItemClick={onUserClick} />
        {/*<UserLoginUsersWithPermissions userListBaseUrl='/settings/users' basePermissionsUrl='/settings/shared_access' />*/}
      </MainBlockContainer>
    </>
  );  
}

