import React from 'react';

import MainBlockHeader from '../MainBlockHeader'
import MainBlockContainer from '../MainBlockContainer';
import { useTranslation, Trans } from 'react-i18next';

export default function About() {
  //let { userUid } = useParams();
  const { t } = useTranslation();

  return (
    <>
      <MainBlockHeader header={t('about.header')} >
      </MainBlockHeader>

      <MainBlockContainer>
        <p>
          <Trans i18nKey="about.developer">
            <b>Developer</b> Delfihealth Ltd
          </Trans>
        </p>

        <p>
          <Trans i18nKey="about.address">
            <b>Address:</b>
          </Trans>
        </p>

        <p>
          <a href="https://www.delfihealth.by" target="_blank" rel="noreferrer">www.delfihealth.by</a>
        </p>

        <p>
          <b>{t('about.version')}</b>
        </p>

        <p>
          <Trans i18nKey="about.lastUpdate">
            <b>Last update</b>
          </Trans>
        </p>
                        
        <p>
          <b>{t('about.email')}</b> <a href="mailto:support@delfihealth.com">support@delfihealth.com</a>
        </p>
      </MainBlockContainer>
    </>
  );
}

