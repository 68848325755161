import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from "../../services/api";
import UserLoginUsersWithPermissions from '../Shared/User/UserLoginUsersWithPermissions'

//https://github.com/iannbing/react-simple-tree-menu
//react-simple-tree-menu

export default MyUsers;

function MyUsers() {
  const { t } = useTranslation();
 
  useEffect(() => {
    
  }, []);


  return (
    <>      
      <UserLoginUsersWithPermissions userListBaseUrl='/settings/users' basePermissionsUrl='/settings/shared_access' />
    </>
  )
}
