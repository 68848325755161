import { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import styles from '../SideMenu.module.scss'

import * as Icons from '../SideMenuIcons';
import { useTranslation } from 'react-i18next';
import { MenuList } from './MenuList';

export { MenuListAbout };
function MenuListAbout({ closeOffcanvasAction }) {
  
  const location = useLocation();
  const path = location.pathname;
  const { t } = useTranslation();

  useEffect(() => {
  });


  const aboutLinkList = [    
    {
      link: `/settings/about`,
      text: t('sidebar.about.about'),
      icon: <Icons.Shield />,
      isActive: function () { return path.includes(this.link); }
    }
  ];

  return (
    <>
      <div className={`${styles.header} ${styles.first}`}>
        {/*<Icons.Shield />*/}
        {/*{t('sidebar.about.about')}*/}
        &nbsp;
      </div>
      <MenuList itemsList={aboutLinkList} closeOffcanvasAction={closeOffcanvasAction} />
    </>

  );

}