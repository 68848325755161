import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import DeviceItemList from '../../components/Metrology/DeviceItemList'

import MainBlockHeader from '../MainBlockHeader'
import MainBlockContainer from '../MainBlockContainer';
import { useTranslation } from 'react-i18next';

export default function DevicesRecordItems() {    
  let { deviceUid, recordUid } = useParams();

  const { t } = useTranslation();

  return (
    <>              
      <MainBlockHeader header={t('metrology.itemList.pageTitle')} >
      </MainBlockHeader>

      <MainBlockContainer>        
        <DeviceItemList
          getDeviceItemsBaseUrl={`/operator/device-list/${deviceUid}/records/${recordUid}/items`}
          uiBaseUrl={`/operator/device-list/${deviceUid}/records/${recordUid}/items`} /> 
      </MainBlockContainer>
    </>
  );  
}

