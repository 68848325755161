import { useTranslation } from 'react-i18next';

export default function Loading() {
  const { t } = useTranslation();

  return (    
    <>
        <p><em>{t('common.isLoading')}</em></p>
    </>
  );
} 


//export default function Loading(props) {
//  const { t } = useTranslation();

//  if (props.isVisible !== undefined && !props.isVisible)
//    return <></>;

//  if (props.isLoading)
//    return <p><em>{t('common.isLoading')}12</em></p>;


//  return (
//    <>{props.children}</>
//    //<>
//    //  {(props.isVisible === undefined || props.isVisible || props.isLoading) &&
//    //    (props.isLoading ?
//    //      <p><em>{t('common.isLoading')}12</em></p> : <>{ props.children }</>
//    //    )}
//    //</>
//  );
//}

//import React, { useState } from 'react';

//export const IsLoadingHOC = (WrappedComponent, loadingMessage) => {
//  function HOC(props) {

//    const { t } = useTranslation();
//    const [isLoading, setLoading] = useState(true);

//    const setLoadingState = isComponentLoading => {
//      setLoading(isComponentLoading);
//    };

//    //if (isLoading)
//    //  return <p><em>{t('common.isLoading')}132</em></p>

//    return (
//      <>      
//        <WrappedComponent {...props} setLoading={setLoadingState } />
//      </>
//      );

//  }

//  return HOC;
//}

//export default IsLoadingHOC;