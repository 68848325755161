import { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import styles from '../SideMenu.module.scss'

import * as Icons from '../SideMenuIcons';
import { useTranslation } from 'react-i18next';
import { MenuList } from './MenuList';
import AuthService from "../../../services/auth.service";

export { MenuListOperator };
function MenuListOperator({ closeOffcanvasAction }) {
  
  const location = useLocation();
  const path = location.pathname;
  const { t } = useTranslation();

  useEffect(() => {
  });


  const expertLinkList = [    
    {
      link: `/operator/user-logins`,
      text: t('sidebar.operator.userList'),
      icon: <Icons.TwoPeople />,
      //isActive: function () { return path.includes(this.link); },
      hasPermission: true,
      isDisabled: true
    },
    {
      link: `/operator/device-list`,
      text: t('sidebar.operator.deviceList'),
      icon: <Icons.ScreenStarMobile />,
      //isActive: function () { return path.includes(this.link); },
      hasPermission: true,      
    },
    {
      link: `/operator/profile`,
      text: t('sidebar.operator.profile'),
      icon: <Icons.PaperWithIcon />,
      hasPermission: true      
    },
    {
      link: `/operator/documents`,
      text: t('sidebar.operator.documents'),
      icon: <Icons.File />,
      //isActive: function () { return path.includes(this.link); },
      hasPermission: true,
      isDisabled: true
    }
  ];

  return (
    <>
      <div className={styles.header}>
        <Icons.Shield />
        {/*{t('sidebar.operator.header')}*/}
        {AuthService.getUserRoles()?.roles?.operator ?? AuthService.getLoggedInUser().displayName}

      </div>
      <MenuList itemsList={expertLinkList} closeOffcanvasAction={closeOffcanvasAction} />
    </>

  );

}