import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';

import { useTranslation } from 'react-i18next';
import mergeDeep from '../../../../utils/mergeDeep';

import { OnlineHighchartsLine } from './OnlineHighchartsLine';

const CommonHighchartsLineForwardRef = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const chartComponentRef = useRef(null);  

  useImperativeHandle(ref, () => ({
    setInitialData(data, seriesSettings) {
      chartComponentRef.current.setInitialData(data, seriesSettings);
    },
    //setDataViewMode(mode) {
    //  chartComponentRef.current.setDataViewMode(mode);
    //},
    //addNewDataToChart(data, seriesId) {
    //  chartComponentRef.current.addNewDataToChart(data, seriesId);
    //},
    //setupYAxis(settings) {
    //  chartComponentRef.current.setupYAxis(settings);    
    //},
    //scrollToEnd() {
    //  chartComponentRef.current.scrollToEnd();
    //},
    //setChartExtremesX(cellTimeMs, preferedPointStart) {
    //  chartComponentRef.current.setChartExtremesX(cellTimeMs, preferedPointStart)
    //},
    //setChartExtremesYMinMax(min, max) {
    //  chartComponentRef.current.setChartExtremesYMinMax(min, max);
    //}
  }));

  useEffect(() => {
    

  }, []);


  const otherChartOptions = {
    
  };

  const getOptions = () => {

    let common = otherChartOptions;
    if (props.chartOptions) {
      //return { ...common, ...props.specificChartOptions };
      return mergeDeep(common, props.chartOptions);
    }

    return common;
  }


  return (
    <>
      <OnlineHighchartsLine ref={chartComponentRef} specificChartOptions={getOptions()} seriesName={props.seriesName}       
      />

    </>
  )


});
export const CommonHighchartsLine = React.memo(CommonHighchartsLineForwardRef);