import React, { useState, useEffect, useRef } from 'react';
import api from "../../../services/api";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CommonHighchartsLine } from '../Ecg/Line/CommonHighchartsLine'


export function DiaryTrendsChart({ displayName, data, chartName, title }) {
  const { t } = useTranslation();


  const chartRef = useRef(null);
  
  //const yAxisSettings = [{ title: { text: 'HR, bpm' }, labels: { format: '{value} bpm' }, opposite: false }];

  const chartOptions = {
    chart: {
      zoomType: 'x'
    },
    title: {
      text: title
    },
    xAxis: {
      type: 'datetime',
      maxRange: undefined,
      dateTimeLabelFormats: {
        millisecond: '%d-%m-%Y %H:%M:%S',
        second: '%d-%m-%Y %H:%M:%S',
        minute: '%d-%m-%Y %H:%M',
        hour: '%d-%m-%Y %H:%M',
        day: '%d-%m-%Y',
        week: '%d-%m-%Y',
        month: '%d-%m-%Y',
        year: '%Y'
      }
    },    
    yAxis: {
      title: {
        text: title
      }
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      series: {
        marker: {
          enabled: true
        }
      }
    }
  };
  
  useEffect(() => {
    console.log(data);

    //let data = [[1, 29.9], [2, 71.5], [3, 106.4], [4, 129.2], [5, 144.0], [6, 176.0],
    //  [7, 135.6], [8, 148.5], [9, 216.4], [10, 194.1], [11, 95.6], [12, 54.4]];
    let colors = ['#7cb5ec', '#434348', '#90ed7d', '#f7a35c', '#8085e9', '#ffb5ec'];

    let seriesSettings = {
      type: 'line',
      //zoneAxis: 'x',
      //zones: [{
      //  value: 1,
      //  color: colors[2]
      //}, {
      //  value: 6,
      //  color: colors[5]
      //}, {
      //  value: 8,
      //  color: colors[2]
      //}, {
      //  color: colors[5]
      //}]
    }

    if (data) {

      
      //let min = Math.min(...data.map(x => x[1]));
      //let max = Math.max(...data.map(x => x[1]));

      //chartRef.current.setupYAxis(yAxisSettings);
      chartRef.current.setInitialData(data, seriesSettings);
      //chartRef.current.setChartExtremesX(data[data.length - 1][0], 0);
      //chartRef.current.setChartExtremesYMinMax(min, max);
    }
  }, [data, title]);


 

  return (
    <>
      <h3>{displayName}</h3>
      <CommonHighchartsLine ref={chartRef} seriesName={title} chartOptions={chartOptions} />            
    </>
  );
}

