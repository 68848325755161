import React from 'react';

import { default as SettingsMyUsers } from '../../components/Settings/MyUsers'

import MainBlockHeader from '../MainBlockHeader'
import MainBlockContainer from '../MainBlockContainer';
import { useTranslation } from 'react-i18next';

export default function MyUsers() {
  //let { userUid } = useParams();
  const { t } = useTranslation();

  return (
    <>
      <MainBlockHeader header={t('settings.users.pageTitile')} >
      </MainBlockHeader>

      <MainBlockContainer>
        <SettingsMyUsers />
      </MainBlockContainer>
    </>
  );
}

