import { React, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Button, Alert } from "react-bootstrap"
import { Formik } from "formik";
import * as Yup from "yup";

import TokenService from "./../../services/token.service";
import { FormEditLine } from "../Shared/CommonForm/CommonForm";

export default Profile ;

function Profile() {
    //const user = useRecoilValue(selectedUserState);
  const { t } = useTranslation();

  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {

  }, []);

  const handleLogin = async (data, form) => {
  };

    return (
      <>
        <Formik
          initialValues={{
            firstName: TokenService.getUser().firstName,
            lastName: TokenService.getUser().lastName,
            email: TokenService.getUser().email,
          }}
          validationSchema={Yup.object({
            firstName: Yup.string()
              .required(t("validation.required")),
            lastName: Yup.string()
              .required(t("validation.required")),
            email: Yup.string()
              .email(t("validation.invalidEmail"))
              .required(t("validation.required")),
          })}
          onSubmit={handleLogin}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>

              <Form.Group controlId="validationFormik00">                
                <FormEditLine displayName={t('register.yourFirstName')}>
                  <Form.Control disabled
                    type="text"
                    name="firstName"
                    placeholder=""
                    value={values.firstName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    isInvalid={touched.firstName && !!errors.firstName}
                  />
                  <Form.Control.Feedback type="invalid" className="mb-4">{errors.firstName}</Form.Control.Feedback>
                </FormEditLine>               
              </Form.Group>

              <Form.Group controlId="validationFormik01">
                <FormEditLine displayName={t('register.yourLastName')}>
                  <Form.Control disabled
                    type="text"
                    name="lastName"
                    placeholder=""
                    value={values.lastName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    isInvalid={touched.lastName && !!errors.lastName}
                  />
                  <Form.Control.Feedback type="invalid" className="mb-4">{errors.lastName}</Form.Control.Feedback>
                </FormEditLine>                               
              </Form.Group>

              <Form.Group controlId="validationFormik02">
                <FormEditLine displayName={t('auth.emailaddress')}>
                  <Form.Control disabled
                    type="text"
                    name="username"
                    placeholder="name@example.com"
                    value={values.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    isInvalid={touched.email && !!errors.email}
                  />
                  <Form.Control.Feedback type="invalid" className="mb-4">{errors.email}</Form.Control.Feedback>
                </FormEditLine>               
              </Form.Group>
              
              {/*<Button className="w-100 btn-lg" type="submit" disabled={loading}>*/}
              {/*  {loading && (*/}
              {/*    <span className="spinner-border spinner-border-sm"></span>*/}
              {/*  )}*/}
              {/*  <span> {t('auth.login')}</span>*/}
              {/*</Button>*/}

              {message && (
                <Alert key="danger" variant="danger" className="mt-3">
                  {message}
                </Alert>
              )}
            </Form>
          )}
        </Formik>


      </>
    )
}
