import React, { useState, useEffect, useMemo } from 'react';
import { Form, Button, Modal, Alert, Col, Row, Stack, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FormEditLine } from '../CommonForm/CommonForm';

import styles from './UserProfile.module.scss'

export default function ProfileFieldBase({ profileLine, value, isEditMode, children }) {
  const { t } = useTranslation();

  
  useEffect(() => {    
  }, []);

 
  return (
    <>
      {
        !isEditMode &&
        <>
          <Col className={styles.textCol}>
            {profileLine.displayName}:&nbsp;{'\u200B'}<span className={styles.textValue}>{value ?? profileLine.value}</span>
          </Col>
        </>
      }

      {
        isEditMode &&
        <>
          <FormEditLine styles={styles} displayName={profileLine.displayName}>
            {children}
          </FormEditLine>          
        </>
      }
    </>
  );
}
