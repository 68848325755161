import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';

//https://github.com/iannbing/react-simple-tree-menu
//react-simple-tree-menu

export default DropdownChartAmp;

function DropdownChartAmp({ onSelectionChangedCallback }) {
  const { t } = useTranslation();

  const [value, setValue] = useState(10);
  const [ampPerMm, setAmpPerMm] = useState(1 / 10);

  const onSelectionChanged = function (eventKey) {
    setValue(eventKey);

    let amp = 1 / eventKey;
    setAmpPerMm(amp);

    if (onSelectionChangedCallback)
      onSelectionChangedCallback(amp);
  }

  return (   
    <>
      <div className="text-nowrap">
        <label>{t('recordView.multiChart.ecg.amp')}:</label>
        <Dropdown onSelect={(eventKey) => { onSelectionChanged(eventKey) }} className="d-inline px-2">
          <Dropdown.Toggle variant="secondary" id="dropdown-basic">{value} mm/mV</Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item eventKey="10">10 mm/mV</Dropdown.Item>
            <Dropdown.Item eventKey="20">20 mm/mV </Dropdown.Item>
            <Dropdown.Item eventKey="50">50 mm/mV</Dropdown.Item>
            <Dropdown.Item eventKey="100">100 mm/mV</Dropdown.Item>
            <Dropdown.Item eventKey="200">200 mm/mV</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <label>1mm = {ampPerMm * 1000}mkV</label>
      </div>
    </>
  );
}
