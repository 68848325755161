import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';

import { OnlineHighchartsAsync } from './OnlineHighchartsAsync'
import { useTranslation } from 'react-i18next';
import mergeDeep from '../../../utils/mergeDeep';

const CommonHighchartsAsyncForwardRef = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const chartComponentRef = useRef(null);  

  useImperativeHandle(ref, () => ({
    setInitialData(data, seriesSettings) {
      chartComponentRef.current.setInitialData(data, seriesSettings);
    },
    setInitialDataForLongSeries(longData, asyncDataLoadUrl, seriesSettings) {
      chartComponentRef.current.setInitialDataForLongSeries(longData, asyncDataLoadUrl, seriesSettings);
    },
    setDataViewMode(mode) {
      chartComponentRef.current.setDataViewMode(mode);
    },
    addNewDataToChart(data, seriesId) {
      chartComponentRef.current.addNewDataToChart(data, seriesId);
    },
    addNewDataToChartLongSeries(data) {
      chartComponentRef.current.addNewDataToChartLongSeries(data)
    },
    setupYAxis(settings) {
      chartComponentRef.current.setupYAxis(settings);    
    },
    scrollToEnd() {
      chartComponentRef.current.scrollToEnd();
    },
    setChartExtremesX(cellTimeMs, preferedPointStart) {
      chartComponentRef.current.setChartExtremesX(cellTimeMs, preferedPointStart)
    },
    setChartExtremesYMinMax(min, max) {
      chartComponentRef.current.setChartExtremesYMinMax(min, max);
    }
  }));

  useEffect(() => {
    //chartComponentRef.current.setChartExtremesX(20);

  }, []);


  const otherChartOptions = {
    xAxis: {
      maxRange: 60000
    },  
  };

  const getOptions = () => {
    let common = otherChartOptions;
    if (props.chartOptions) {
      //return { ...common, ...props.specificChartOptions };
      return mergeDeep(common, props.chartOptions);
    }

    return common;
  }

  const getNavigatorOptions = () => {
    let common = {  };
    if (props.navigatorOptions) {
      //return { ...common, ...props.specificChartOptions };
      return mergeDeep(common, props.navigatorOptions);
    }

    return common;
  }

  return (
    <>
      <OnlineHighchartsAsync ref={chartComponentRef} specificChartOptions={getOptions()} specificNavigatorOptions={getNavigatorOptions()} seriesName={props.seriesName}   
        defaultCellTimeXms={400}
      />

    </>
  )


});
export const CommonHighchartsAsync = React.memo(CommonHighchartsAsyncForwardRef);