import { useRecoilValue } from 'recoil';
import { selectedUserState } from '../states/selectedUserState';

export default class UserPermissions {
  constructor(user) {
    this.user = user;
  }

  checkPermission(value) {
    //console.log(`${this.user.displayName} - ${this.user.permissions} - ${value} - ${(this.user.permissions & value) > 0}`);
    return (this.user.permissions & 16) > 0  || (this.user.permissions & value) > 0;
  }

  hasProfileReadPermission() {
    return this.checkPermission(2);
  }

  hasProfileEditPermission() {
    return this.checkPermission(4);
  }

  hasRecordsReadPermission() {
    return this.checkPermission(64);
  }

  hasRecordsEditPermission() {
    return this.checkPermission(128);
  }

  hasRecordsDeletePermission() {
    return this.checkPermission(256);
  }

  hasDocumentsReadPermission() {
    return this.checkPermission(2048);
  }

  hasDocumentsEditPermission() {
    return this.checkPermission(4096);
  }

  hasDiaryReadPermission() {
    return this.checkPermission(8192);
  }

  hasDiaryEditPermission() {
    return this.checkPermission(16384);
  }

  hasConsultationsReadPermission() {
    return this.checkPermission(1024);
  }

  hasConsultationsRequestPermission() {
    return this.checkPermission(32768);
  }

  isUserOwner() {
    return this.checkPermission(16);
  }  

  hasUserDevicesReadPermission() {
    return this.checkPermission(65536);
  }
}