import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import DateTimePicker from 'react-datetime-picker'

import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';


import api from "../../../services/api";

import { Button, Modal, Alert, Col, Row, Stack, Input } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';

import styles from './AddDiaryRecord.module.scss';
import * as CommonIcons from '../../CommonIcons';

import { FormEditLine, SaveButtonSeparateRow } from '../CommonForm/CommonForm';
import { useToastContext } from "../../../toasts/toast-context";

export default function AddDocument({ apiDiaryBaseUrl, diaryListPageUrl, diaryRecordUid }) {
  const { t } = useTranslation();
  let navigate = useNavigate();

  const [diaryDate, setDiaryDate] = useState(new Date());
  const [diaryText, setDiaryText] = useState('');
  const [diarySelection, setDiarySelection] = useState();
  const [diaryRecordType, setDiaryRecordType] = useState();
  const [selectedFile, setSelectedFile] = useState();

  const [loading, setLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [data, setData] = useState([]); 
  const { showSuccessToastMessage, showErrorToastMessage } = useToastContext();

  useEffect(() => {
    const loadSettingsOnly = () => {
      api
        .get(`/diary/settings`)
        .then((response) => {
          setData(response.data.items);
          setLoading(false);
          {
            let firstElement = response.data.items[0];
            setDiarySelection(firstElement.uid);
            setDiaryRecordType(firstElement.recordType);
          }
        });
    };

    const loadRecordWithSettings = () => {
      api
        .get(`${apiDiaryBaseUrl}/edit/${diaryRecordUid}`)
        .then((response) => {
          setData(response.data.settings.items);
          setLoading(false);
          
          let firstElement = response.data.settings.items.find(x => x.uid === response.data.record.typeUid);
          setDiarySelection(firstElement.uid);
          setDiaryRecordType(firstElement.recordType);
          setDiaryText(response.data.record.value);
          setDiaryDate(new Date(response.data.record.date));
        });
    }

    if (diaryRecordUid)
      loadRecordWithSettings();
    else
      loadSettingsOnly();

  }, []);

 
  const onDiarySelectionTypeChanged = (newUid) => {
    setDiarySelection(newUid);
    let item = data.find(x => x.uid === newUid);
    setDiaryRecordType(item.recordType);
  };

  const onDiaryInputValueChanged = (value) => {
    setDiaryText(value);
  };

  const onSendRecord = () => {
    //console.log(diaryDate, ' - ', diarySelection, ' - ', diaryText);
    setIsSending(true);

    let url = `${apiDiaryBaseUrl}/save`;
    let model = {
      uid: diaryRecordUid,
      date: diaryDate,
      typeUid: diarySelection,
      value: diaryText
    };    


    api.post(url, model).then(response => {
      //setIsSending(false);
      //setDiaryText('');    
      showSuccessToastMessage(t('diary.messages.success'));
      if (diaryListPageUrl) navigate(diaryListPageUrl);
    },
      (error) => {
        setLoading(false);
        setIsSending(false);
        showErrorToastMessage(t('diary.messages.error'));
      });

  };


  if (loading) return <></>;

  return (
    <>   
      <Row>
        <FormEditLine styles={styles} displayName={t('diary.add.date')}>
          <DateTimePicker onChange={setDiaryDate} showLeadingZeros={false} value={diaryDate} format="dd-MM-yyyy HH:mm" className={`form-control ${styles.calendarInput}`} />
        </FormEditLine>
      </Row>
      <Row>
        <FormEditLine styles={styles} displayName={t('diary.add.type')}>
          <Form.Select value={diarySelection} onChange={e => onDiarySelectionTypeChanged(e.target.value)}>
            {data.map(opt => (
              <option value={opt.uid} key={opt.uid}>{opt.displayName}</option>
            ))}
          </Form.Select>
        </FormEditLine>
      </Row>      
      <Row>
        <FormEditLine styles={styles} displayName={t('diary.add.value')}>
          {diaryRecordType === 2 &&
            <Form.Control type="number" min="0" value={diaryText} onChange={e => onDiaryInputValueChanged(e.target.value)} className={styles.input} />}
          {diaryRecordType === 3 &&
            <Form.Control type="text" value={diaryText} onChange={e => onDiaryInputValueChanged(e.target.value)} className={styles.input} />}
        </FormEditLine>
      </Row>

      <SaveButtonSeparateRow styles={styles} onClick={onSendRecord}>
        {isSending && (<span className="spinner-border spinner-border-sm"></span>)}{diaryRecordUid ? t('common.saveChanges') : t('common.addButton')}
      </SaveButtonSeparateRow>      
    </>
  );
}