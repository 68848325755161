import fileDownload from 'js-file-download';
import api from "./api";

let fileDownloadNamed = (url, defaultExtension) => {
  api.get(url, {
    responseType: 'blob',
  }).then((response) => {
    let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    let matches = filenameRegex.exec(response.headers['content-disposition']);

    let filename = `default.${defaultExtension ?? 'unknown'}`;

    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
    fileDownload(response.data, filename);
  });
}
export default fileDownloadNamed;