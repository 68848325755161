import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';

import Highcharts from 'highcharts/highstock'
import AnnotationsFactory from "highcharts/modules/annotations";
import HighchartsReact from 'highcharts-react-official'

import mergeDeep from '../../../../utils/mergeDeep';
import * as ChartConsts from '../../../ChartSettings/ChartSettings'

// init the module
AnnotationsFactory(Highcharts);
//bellCurve(Highcharts);

const OnlineHighchartsScatterForwardRef = forwardRef((props, ref) => {
 
  const chartComponentRef = useRef(null);
  const chartDataRef = useRef({}); 
  const DEFAULT_SERIES_NAME = 'series-data';


  useImperativeHandle(ref, () => ({
    setInitialData(data, seriesSettings) {
      chartDataRef.current[(seriesSettings && seriesSettings.seriesId) ?? DEFAULT_SERIES_NAME] = data;
      let chart = chartComponentRef.current.chart;
      let seriesId = (seriesSettings && seriesSettings.seriesId) ?? DEFAULT_SERIES_NAME;         

      let newSeries =
      {
        id: seriesId,
        data: data,
        name: (seriesSettings && seriesSettings.name) ?? props.seriesName,
        //color: (seriesSettings && seriesSettings.color) ?? 'blue',        
        yAxis: (seriesSettings && seriesSettings.yAxisId) ?? 0,
        color: '#7cb5ec',
        marker: {
          symbol: "circle"
        }      
      };

      if (chart.get(seriesId))
        chart.get(seriesId).update(newSeries);
      else
        chart.addSeries(newSeries);
    },
   
  }));

  useEffect(() => {
    //console.log('accumulation mode:', props.mode);    

  }, []);

  const newOptions = {
    chart: {
      type: 'scatter',
      zoomType: 'xy',
      zooming: {
        mouseWheel: {
          enabled: false
        }
      }
    },
    subtitle: {
      text: ''
    },
    credits: {
      enabled: false
    }
  };

  const getOptions = () => {
    let base = ChartConsts.baseChartOptions;
    
    let common = mergeDeep(base, newOptions);
    if (props.specificChartOptions) {
      //return { ...common, ...props.specificChartOptions };
      return mergeDeep(common, props.specificChartOptions);
    }

    return common;
  }
  
  return (
    <>
      <HighchartsReact
        ref={chartComponentRef}
        highcharts={Highcharts}
        constructorType={'chart'}
        options={getOptions()}
      />

    </>
  )


});
export const OnlineHighchartsScatter = React.memo(OnlineHighchartsScatterForwardRef);