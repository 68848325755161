import styles from './MainBlockHeader.module.scss'

import { Container } from 'reactstrap';

export default function MainBlockContainer(props) {
  return (
    <Container fluid className={styles.mainContainer} >
      { props.children }
    </Container>
  )
}