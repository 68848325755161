export const LyingOnBack = () => {
  return (   
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.8433 14.16H11.2486C9.42166 14.16 7.77695 13.0527 7.08955 11.36L2.76675 13.9439C1.98845 14.4092 1 13.8484 1 12.9417C1 12.5641 1.18253 12.2099 1.48997 11.9908L4.03117 10.1797C5.9253 8.82975 8.3935 8.58624 10.5149 9.54H14.955C15.5359 9.54 16.1054 9.70068 16.6005 10.0043C16.8556 10.1606 17.0871 10.3525 17.2879 10.5742L17.4767 10.7825C17.8135 11.1542 18 11.6378 18 12.1393C18 13.2553 17.0953 14.16 15.9793 14.16H15.8433Z" fill="currentColor"/>
      <circle cx="21" cy="12" r="2" fill="currentColor"/>
    </svg>
  )
}

export const LyingFaceDown = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3799 15.7703C12.2658 15.662 13.1251 15.3951 13.9165 14.9823L15.3924 14.2125L16.5538 15.4779C17.2199 16.2037 18.3249 16.3131 19.1204 15.732L21.695 13.8511C22.0419 13.5977 22.1115 13.1081 21.8491 12.768C21.603 12.4491 21.1517 12.3762 20.8178 12.6012L18.6393 14.0692C18.2722 14.3166 17.7792 14.2571 17.4816 13.9295L16.776 13.1529C17.1137 12.5357 17.0751 11.7394 16.5833 11.1475C15.9617 10.4354 14.9677 10.1758 14.0772 10.4932L10.4015 11.8033L10.3786 11.7983C9.20149 11.5414 7.98772 11.4987 6.79545 11.6721L3.98221 9.16544C3.38061 8.6294 2.42526 8.91275 2.21321 9.69012C2.10173 10.0988 2.23826 10.5351 2.56287 10.8073L6.40909 14.0328H1.98361C1.44038 14.0328 1 14.4732 1 15.0164C1 15.5596 1.44038 16 1.98361 16H6.02273H9.5L11.3799 15.7703Z" fill="currentColor" />
      <circle cx="19" cy="10" r="2" fill="currentColor" />
    </svg>
  )
}

export const LyingRightSide = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1058_6125)">
        <path d="M9.125 12.875H15.25H18.625H22.5625C23.0803 12.875 23.5 12.4553 23.5 11.9375C23.5 11.4197 23.0803 11 22.5625 11H14.375L17.5 9.5L19.5 11.25H22.25L20.6853 9.81944C18.9742 8.25503 16.506 7.83672 14.375 8.75H9.96042C9.41252 8.75 8.87366 8.88963 8.39471 9.15572C8.13285 9.3012 7.89254 9.48246 7.68072 9.69428L7.51777 9.85723C7.18625 10.1888 7 10.6384 7 11.1072C7 12.0835 7.79146 12.875 8.76777 12.875H9.125Z" fill="currentColor" />
        <circle cx="2" cy="2" r="2" transform="matrix(0 1 1 0 2.75 6.5)" fill="currentColor" />
        <path d="M8.05418 9.13616C7.40155 9.51623 7.00012 10.2145 7.00012 10.9698C7.00012 11.3116 7.12393 11.642 7.34866 11.8996L7.36906 11.923L8.20482 10.9376C8.72305 10.3266 9.4128 9.88536 10.1847 9.67094L10.7174 9.52298C11.0708 9.42479 11.4091 9.27845 11.7227 9.08806L14.1939 7.58772C14.5241 7.38721 14.684 6.99401 14.5873 6.61994C14.4421 6.05782 13.8002 5.78993 13.2985 6.0821L8.05418 9.13616Z" fill="currentColor" />
        <path d="M8.6881 12.875C9.41268 12.875 10.0001 12.2876 10.0001 11.5631C10.0001 11.5211 9.99703 11.4792 9.99101 11.4376L9.93257 11.0343L8.4563 11.0343L2.75006 11L2.84672 10.768C3.08976 10.1847 2.63491 9.54934 2.00443 9.59138C1.69353 9.6121 1.41881 9.80105 1.28823 10.084L1.08703 10.5199C0.579475 11.6196 1.38272 12.875 2.5939 12.875L8.6881 12.875Z" fill="currentColor" />
        <g clip-path="url(#clip1_1058_6125)">
          <path d="M11.0568 9.375L10.9605 9.26927C10.62 8.89375 10.3947 8.64584 10.1198 8.64584C9.89448 8.64584 9.71875 8.82229 9.71875 9.04688C9.71875 9.17375 9.77781 9.29552 9.87042 9.375C9.77781 9.45448 9.71875 9.57625 9.71875 9.70313C9.71875 9.92771 9.89448 10.1042 10.1198 10.1042C10.3947 10.1042 10.62 9.85625 10.9605 9.48073L11.0568 9.375Z" fill="#FF0000" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1058_6125">
          <rect width="100%" height="100%" fill="white" transform="matrix(0 1 1 0 0 0)" />
        </clipPath>
        <clipPath id="clip1_1058_6125">
          <rect width="1.75" height="1.75" fill="white" transform="matrix(0 1 1 0 9.5 8.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const LyingLeftSide = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_971_6140)">
        <path d="M14.875 12.875H8.75H5.375H1.4375C0.919733 12.875 0.5 12.4553 0.5 11.9375C0.5 11.4197 0.919733 11 1.4375 11H9.625L6.5 9.5L4.5 11.25H1.75L3.31467 9.81944C5.02575 8.25503 7.49401 7.83672 9.625 8.75H14.0396C14.5875 8.75 15.1263 8.88963 15.6053 9.15572C15.8672 9.3012 16.1075 9.48246 16.3193 9.69428L16.4822 9.85723C16.8138 10.1888 17 10.6384 17 11.1072C17 12.0835 16.2085 12.875 15.2322 12.875H14.875Z" fill="currentColor" />
        <circle cx="19.25" cy="8.5" r="2" transform="rotate(90 19.25 8.5)" fill="currentColor" />
        <path d="M15.9458 9.13616C16.5985 9.51623 16.9999 10.2145 16.9999 10.9698C16.9999 11.3116 16.8761 11.642 16.6513 11.8996L16.6309 11.923L15.7952 10.9376C15.277 10.3266 14.5872 9.88536 13.8153 9.67094L13.2826 9.52298C12.9292 9.42479 12.5909 9.27845 12.2773 9.08806L9.80614 7.58772C9.4759 7.38721 9.31602 6.99401 9.41265 6.61994C9.55786 6.05782 10.1998 5.78993 10.7015 6.0821L15.9458 9.13616Z" fill="currentColor" />
        <path d="M15.3119 12.875C14.5873 12.875 13.9999 12.2876 13.9999 11.5631C13.9999 11.5211 14.003 11.4792 14.009 11.4376L14.0674 11.0343L15.5437 11.0343L21.2499 11L21.1533 10.768C20.9102 10.1847 21.3651 9.54934 21.9956 9.59138C22.3065 9.6121 22.5812 9.80105 22.7118 10.084L22.913 10.5199C23.4205 11.6196 22.6173 12.875 21.4061 12.875L15.3119 12.875Z" fill="currentColor" />
        <g clip-path="url(#clip1_971_6140)">
          <path d="M14.1932 11.375L14.2895 11.2693C14.63 10.8938 14.8553 10.6458 15.1302 10.6458C15.3555 10.6458 15.5312 10.8223 15.5312 11.0469C15.5312 11.1738 15.4722 11.2955 15.3796 11.375C15.4722 11.4545 15.5312 11.5763 15.5312 11.7031C15.5312 11.9277 15.3555 12.1042 15.1302 12.1042C14.8553 12.1042 14.63 11.8563 14.2895 11.4807L14.1932 11.375Z" fill="#FF0000" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_971_6140">
          <rect width="100%" height="100%" fill="white" transform="matrix(0 1 -1 0 24 0)" />
        </clipPath>
        <clipPath id="clip1_971_6140">
          <rect width="1.75" height="1.75" fill="white" transform="matrix(0 1 -1 0 15.75 10.5)" />
        </clipPath>
      </defs>
    </svg>

  )
}

export const LeanBack = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.318 8.33035C13.9309 9.45319 14.3185 10.685 14.4589 11.9565L14.6397 13.5938V17.4418V22.0164C14.6397 22.5596 14.1993 23 13.6561 23C13.1129 23 12.6725 22.5596 12.6725 22.0164V12.5962V17.0142L9.72035 22.0324C9.43562 22.5164 8.79455 22.6475 8.34267 22.3142C7.96612 22.0364 7.84909 21.5251 8.06729 21.1112L11.1878 15.1914C10.5804 14.5192 10.4289 13.5511 10.8019 12.7256L11.1878 11.8716L9.25796 7.85161C8.96744 7.24644 8.97452 6.54065 9.27714 5.94143C9.4187 5.66112 9.67518 5.45622 9.97985 5.38005L10.2437 5.31409C10.9836 5.12911 11.7551 5.46725 12.1206 6.13672L13.318 8.33035Z" fill="currentColor" />
      <circle cx="8.48809" cy="3.48815" r="2" transform="rotate(16.6056 8.48809 3.48815)" fill="currentColor" />
      <path d="M9.84556 8.33203C10.571 8.04248 11.052 7.34605 11.0659 6.56507L11.08 5.77857C11.0847 5.51169 10.8696 5.29282 10.6027 5.29294C10.2904 5.29308 9.98145 5.35781 9.69534 5.48308L9.52809 5.5563C8.64078 5.94479 8.06101 6.81451 8.04374 7.78299L8.03294 8.38847L7.99381 10.5831L7.9298 14.1726C7.92193 14.6144 8.27366 14.9789 8.71541 14.9868C9.15717 14.9946 9.52167 14.6429 9.52955 14.2012L9.59355 10.6117L9.6286 8.64616C9.63108 8.50732 9.71659 8.38351 9.84556 8.33203Z" fill="currentColor" />
    </svg>
  )
}

export const LeanForward = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 5C16.2091 5 18 6.79086 18 9V17.8966C18 18.506 17.506 19 16.8966 19C16.2871 19 15.7931 18.506 15.7931 17.8966V10.1154H10V7C10 5.89543 10.8954 5 12 5H14Z" fill="currentColor" />
      <path d="M10 7.00002C10 5.89544 10.8954 5 12 5V13.0001C12 13.5524 11.5523 14.0001 11 14.0001C10.4477 14.0001 10 13.5524 10 13.0001V7.00002Z" fill="currentColor" />
      <circle cx="7.00002" cy="7.00002" r="2.00002" fill="currentColor" />
    </svg>
  )
}

export const Stand = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 8.7V12.275V14.0625V14.9563V15.85V22C15 22.5523 14.5523 23 14 23C13.4477 23 13 22.5523 13 22V16H12V22C12 22.5523 11.5523 23 11 23C10.4477 23 10 22.5523 10 22V16V14V12.275V7H13.3C14.2389 7 15 7.76112 15 8.7Z" fill="currentColor" />
      <circle cx="12.5" cy="3.5" r="2.5" fill="currentColor" />
      <path d="M7.99994 9.25C7.99994 8.00736 9.0073 7 10.2499 7H12.1538L11.1155 8.09178C10.4083 8.83534 10.0139 9.82224 10.0139 10.8484V11.5V14.993C10.0139 15.5492 9.56309 16 9.00694 16C8.45079 16 7.99994 15.5492 7.99994 14.993V11.5V9.25Z" fill="currentColor" />
      <path d="M12.7203 7H14.75C15.9926 7 17 8.00736 17 9.25V11.5V14.993C17 15.5492 16.5492 16 15.993 16C15.4368 16 14.986 15.5492 14.986 14.993V11.5V10.915C14.986 9.84933 14.5607 8.82769 13.8045 8.07676L12.7203 7Z" fill="currentColor" />
    </svg>

  )
}

export const LeanRight = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.7463 7.89443C14.2544 8.572 14.3362 9.47852 13.9574 10.2361L13.0754 12L12.0754 14.0625V14.9563V15.85V22C12.0754 22.5523 11.6277 23 11.0754 23C10.5232 23 10.0754 22.5523 10.0754 22V16H9.07544V22C9.07544 22.5523 8.62772 23 8.07544 23C7.52315 23 7.07544 22.5523 7.07544 22V16V15V14.9443C7.07544 14.3233 7.22002 13.7108 7.49773 13.1554L7.57544 13L8.05763 12L9.57309 8.24478C9.87667 7.49255 10.6066 7 11.4178 7H11.9574C12.6612 7 13.324 7.33137 13.7463 7.89443Z" fill="currentColor" />
      <circle cx="13.0754" cy="4" r="2" fill="currentColor" />
      <path d="M10.5499 6.69659L12.297 7.07431L11.1476 7.85643C10.2887 8.44097 9.68721 9.33326 9.46764 10.3488L9.32994 10.9856L8.59174 14.3998C8.48004 14.9164 7.97071 15.2446 7.45411 15.1329C6.93752 15.0212 6.60929 14.5119 6.72098 13.9953L7.45918 10.5812L7.93469 8.38197C8.19147 7.19439 9.36235 6.43982 10.5499 6.69659ZM12.5302 7.12571L14.306 7.52407C15.5041 7.79283 16.3556 8.8565 16.3556 10.0844L16.3556 16.045C16.3556 16.5738 15.9269 17.0025 15.3981 17.0025C14.8749 17.0025 14.4485 16.5826 14.4406 16.0594L14.3802 12.0518L14.3802 12.0518L14.38 12.0489L14.2851 10.7495C14.2354 10.0687 14.0144 9.4114 13.6426 8.83884L13.3329 8.36185L12.5302 7.12571Z" fill="currentColor" stroke="white" stroke-width="0.1" />
      <g clip-path="url(#clip0_1046_6805)">
        <path d="M9.99992 10.7792L10.1208 10.6692C10.5499 10.28 10.8333 10.0225 10.8333 9.70833C10.8333 9.45083 10.6316 9.25 10.3749 9.25C10.2299 9.25 10.0908 9.3175 9.99992 9.42333C9.90909 9.3175 9.76992 9.25 9.62492 9.25C9.36825 9.25 9.16659 9.45083 9.16659 9.70833C9.16659 10.0225 9.44992 10.28 9.87909 10.6692L9.99992 10.7792Z" fill="#FF0000" />
      </g>
      <defs>
        <clipPath id="clip0_1046_6805">
          <rect width="2" height="2" fill="white" transform="matrix(-1 0 0 1 11 9)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const LeanLeft = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.2537 7.89443C9.74556 8.572 9.66382 9.47852 10.0426 10.2361L10.9246 12L11.9246 14.0625V14.9563V15.85V22C11.9246 22.5523 12.3723 23 12.9246 23C13.4768 23 13.9246 22.5523 13.9246 22V16H14.9246V22C14.9246 22.5523 15.3723 23 15.9246 23C16.4768 23 16.9246 22.5523 16.9246 22V16V15V14.9443C16.9246 14.3233 16.78 13.7108 16.5023 13.1554L16.4246 13L15.9424 12L14.4269 8.24478C14.1233 7.49255 13.3934 7 12.5822 7H12.0426C11.3388 7 10.676 7.33137 10.2537 7.89443Z" fill="currentColor" />
      <circle cx="2" cy="2" r="2" transform="matrix(-1 0 0 1 12.9246 2)" fill="currentColor" />
      <path d="M13.4501 6.69659L11.703 7.07431L12.8524 7.85643C13.7113 8.44097 14.3128 9.33326 14.5324 10.3488L14.6701 10.9856L15.4083 14.3998C15.52 14.9164 16.0293 15.2446 16.5459 15.1329C17.0625 15.0212 17.3907 14.5119 17.279 13.9953L16.5408 10.5812L16.0653 8.38197C15.8085 7.19439 14.6376 6.43982 13.4501 6.69659ZM11.4698 7.12571L9.69398 7.52407C8.49589 7.79283 7.64439 8.8565 7.64439 10.0844L7.64439 16.045C7.64439 16.5738 8.0731 17.0025 8.60193 17.0025C9.12514 17.0025 9.55147 16.5826 9.55937 16.0594L9.61984 12.0518L9.61975 12.0518L9.61996 12.0489L9.71485 10.7495C9.76457 10.0687 9.9856 9.4114 10.3574 8.83884L10.6671 8.36185L11.4698 7.12571Z" fill="currentColor" stroke="white" stroke-width="0.1" />
      <g clip-path="url(#clip0_1046_6811)">
        <path d="M10.9999 10.7792L11.1208 10.6692C11.5499 10.28 11.8333 10.0225 11.8333 9.70833C11.8333 9.45083 11.6316 9.25 11.3749 9.25C11.2299 9.25 11.0908 9.3175 10.9999 9.42333C10.9091 9.3175 10.7699 9.25 10.6249 9.25C10.3683 9.25 10.1666 9.45083 10.1666 9.70833C10.1666 10.0225 10.4499 10.28 10.8791 10.6692L10.9999 10.7792Z" fill="#FF0000" />
      </g>
      <defs>
        <clipPath id="clip0_1046_6811">
          <rect width="2" height="2" fill="white" transform="matrix(-1 0 0 1 12 9)" />
        </clipPath>
      </defs>
    </svg>
  )
}