import React, { useState, useEffect, useMemo } from 'react';
import api from "../../services/api";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TableFull from '../Shared/CommonTable/TableFull';

import { dateFormatter } from '../../utils/dateFormatter'
import Loading from '../Shared/Loading'

import styles from './ExpertCompletedConsultations.module.scss'

export default function ExpertCompletedConsultations() {
  const { t } = useTranslation();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  let navigate = useNavigate();

  useEffect(() => {    
    loadConsultations();
  }, []);

  const loadConsultations = function () {
    api
      .get(`/consultation/expert/completed/list`)
      .then((response) => {
        setData(response.data.items);
        setLoading(false);
      });
  }

  const columns = useMemo(
    () =>
      [
        {
          header: t('consultation.expert.list.userName'),
          accessorKey: "user.displayName",
          enableColumnFilter: false,          
        },
        {
          header: t('consultation.expert.list.record'),
          accessorKey: "userRecord.displayName",
          enableColumnFilter: false,
        },
        {
          header: t('consultation.expert.list.requestDate'),
          accessorKey: "requestDate",
          enableColumnFilter: false,
          cell: props => <>{dateFormatter(props.getValue())}</>,
        },
        {
          header: t('consultation.expert.list.acceptDate'),
          accessorKey: "acceptDate",
          enableColumnFilter: false,
          cell: props => <>{dateFormatter(props.getValue())}</>,
        }, 
        {
          header: t('consultation.expert.list.completeDate'),
          accessorKey: "completeDate",
          enableColumnFilter: false,
          cell: props => <>{dateFormatter(props.getValue())}</>,
        }        
      ],
    [t]
  ); 

  const onRecordClickHandler = (record) => {
    console.log(record);
    navigate(`/consultations/expert/completed/${record.uid}/current_record/${record.userRecord.uid}`);
  };

  if (loading)
    return <Loading />;

  return (
    <>        
      <TableFull styles={styles} data={data} columns={columns} onRowClick={onRecordClickHandler} />
    </>
  );
}