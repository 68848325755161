import React, { useState, useEffect, useRef } from 'react';
import api from "../../../services/api";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CommonHighchartsAsync } from '../Ecg/CommonHighchartsAsync'


export function RRChart({ displayName, data, chartName, requestUrl }) {
  const { t } = useTranslation();


  const chartRef = useRef(null);
  
  //const yAxisSettings = [{ title: { text: 'RR, ms' }, labels: { format: '{value} ms' }, opposite: false }];

  const chartOptions = {
    title: {
      text: t('recordView.processedData.charts.rrChart.header')
    }, 
    xAxis: {
      maxRange: undefined,
      tickPositioner: undefined,
      tickInterval: undefined,
      minorTicks: false,
      minorTickInterval: undefined
    },
    yAxis: {
      min: 200,
      max: 2500,
      title: { text: '' }, labels: { format: '{value}' }, opposite: false
    }
  };

  const navigatorOptions = {
    xAxis: {
      maxRange: 5 * 3600000,
      range: 1 * 3600000
    }
  };

  useEffect(() => {
    //console.log(displayName);

    if (data) {

      //let min = Math.min(...data.map(x => x[1]));
      //let max = Math.max(...data.map(x => x[1]));

      //chartRef.current.setupYAxis(yAxisSettings);
      chartRef.current.setInitialData([]);
      chartRef.current.setInitialDataForLongSeries(data.longRecord, requestUrl);
      //chartRef.current.setChartExtremesX(data[data.length - 1][0], 0);
      //chartRef.current.setChartExtremesYMinMax(min, max);
    }
  }, []);


 

  return (
    <>
      <h3>{displayName}</h3>
      <CommonHighchartsAsync ref={chartRef} seriesName={'RR'} chartOptions={chartOptions} navigatorOptions={navigatorOptions} />            
    </>
  );
}

