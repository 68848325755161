import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CheckBox from '../CheckBox'
import { Button, Row, Col, Form, Container, Alert } from 'react-bootstrap';
import OneUserLoginPermissions from './OneUserLoginPermissions'


export default MyUsersPermissions;

function MyUsersPermissions({ permissions, isGroup, onUpdatePersmissions }) {
  //const user = useRecoilValue(selectedUserState);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
  }, [permissions]);

  const onUpdatePersmissionsClick = function () {
    setLoading(true);
    onUpdatePersmissions().then(() => {
      setLoading(false);
      setMessage(null);
    }, (error) => {
      setLoading(false);
      setMessage(error ?? t('settings.userPermissions.errorUpdating'));
    });
  }
  
  return (
    <>      
      <div className="p-2  w-100 flex-fill bd-highlight">
        <Form>
          <Container>          
            {isGroup && <Row><Col>{t('settings.userPermissions.group.header')}</Col></Row> }
            {!isGroup && <Row><Col>{t('settings.userPermissions.user.header')}</Col></Row>}
          
            {permissions && permissions.userLoginList.map((userLogin) =>
              <div key={userLogin.uid} className="mb-5">              
                <Row>
                  <Col><b>{t('settings.userPermissions.userName')}: {userLogin.userName}</b></Col>
                </Row>
                <Row>
                  <Col><b>{t('settings.userPermissions.userEmail')}: {userLogin.userEmail}</b></Col>
                </Row>

                <OneUserLoginPermissions
                  userLoginUid={userLogin.uid}
                  permissions={userLogin.permissions}
                  isDisabled={userLogin.isInherited}
                  onPermissionUpdate={(perms => userLogin.permissions = perms)}
                  isGroup={isGroup}
                />
              </div>
            )}

            {(!permissions || permissions.userLoginList.length === 0) && <Row>
              <Alert key="danger" variant="warning" className="mt-3">
                {isGroup ? t('settings.userPermissions.noAccessToGroupToControlPermissions') : t('settings.userPermissions.noAccessToUserToControlPermissions')}
              </Alert>
            </Row>}

            {message && <Row>
              <Alert key="danger" variant="danger" className="mt-3">
                {message}
              </Alert>
            </Row>}

            {permissions && permissions.userLoginList.length > 0 && <Row>
              <Col></Col>
              <Col><Button variant="primary" className="w-100" onClick={onUpdatePersmissionsClick} disabled={loading}>{loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )} {t('settings.userPermissions.updateButton')}</Button></Col>
            </Row>}
          </Container>
        </Form>
      </div>
    </>
  )
}
