//import { React } from 'react';
//import { useTranslation } from 'react-i18next';

export default PersonalData ;

function PersonalData() {
    //const user = useRecoilValue(selectedUserState);
    //const { t } = useTranslation();

    return (
      <>
        
      </>
    )
}
