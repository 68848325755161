import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import api from "../../../services/api";
import { useTranslation } from 'react-i18next';

import { Col, Row } from 'react-bootstrap';


import { HRHistogramChart } from './HRHistogramChart'
import { RRHistogramChart } from './RRHistogramChart'
import { ScatterChart } from './ScatterChart'
import { HRSpecterChart } from './HRSpecterChart'
import { RRSpecterChart } from './RRSpecterChart'
import { AverageIntervalChart } from './AverageIntervalChart'

import { AllProcessedDataSlider } from './AllProcessedDataSlider'
import { AllProcessedDataValues } from './AllProcessedDataValues'

import Loading from '../Loading'

import styles from './AllProcessedData.module.scss'
export const AllProcessedDataSmallCharts = forwardRef((props, ref) => {

  useImperativeHandle(ref, () => {
    return {
      setLastProcessedTime(lastProcessedTime) {
        let isMaxValueSelected = sliderRef.current?.isCurrendSliderMaxRight();
        sliderRef.current?.setLastProcessedTime(lastProcessedTime);
        if (isMaxValueSelected)
          sliderRef.current?.setSliderValue(lastProcessedTime);
      }
    };
  }, []);

  const sliderRef = useRef(null);
  const { t } = useTranslation();

  const [dataCharts, setDataCharts] = useState([]);
  const [singleValues, setSingleValues] = useState({});

  const [initialMaxTimeSeconds] = useState(props.totalRecordLengthSeconds);
  const ChartDataTypes = {
    //EcgWithRrIntervals: 10,
    //HR: 20,
    HRHistogram: 30,
    //RR: 40,
    RRHistogram: 50,
    //SI: 60,
    Scatter: 70,
    HRSpecter: 80,
    RRSpecter: 90,
    EcgAverageInterval: 100
  }

  useEffect(() => {
    //setMaxTimeSeconds(props.totalRecordLengthSeconds);
    onSliderTimeChange(props.totalRecordLengthSeconds);
  }, [props]);


  const onSliderTimeChange = (newTime) => {

    console.log(newTime);

    api.post(`${props.requestUrl}/window?endTimeSec=${newTime}`)
      .then((response) => {

        if (response.data.chartList && response.data.values) {
          setDataCharts(response.data.chartList);
          setSingleValues(response.data.values);
        }
      }, (error) => {
        //setLoading(false);
      });
  };

  const getDataByType = (chartType) => dataCharts.find(x => x.chartType === chartType);

  const HRHistogramComponent = () => {
    let x = getDataByType(ChartDataTypes.HRHistogram);
    return (
      <>
        {x && <HRHistogramChart data={x.dataColumns} displayName={x.displayName} plotLinesX={x.plotLinesX} />}
      </>
    );
  }

  const RRHistogramComponent = () => {
    let x = getDataByType(ChartDataTypes.RRHistogram);
    return (
      <>
        {x && <RRHistogramChart data={x.dataColumns} displayName={x.displayName} plotLinesX={x.plotLinesX} />}
      </>
    );
  }

  const ScatterComponent = () => {
    let x = getDataByType(ChartDataTypes.Scatter);
    return (
      <>
        {x && <ScatterChart data={x.data} displayName={x.displayName} plotLinesX={x.plotLinesX} />}
      </>
    );
  }

  const HRSpecter = () => {
    let x = getDataByType(ChartDataTypes.HRSpecter);
    return (
      <>
        {x && <HRSpecterChart data={x.data} displayName={x.displayName} plotLinesX={x.plotLinesX} />}
      </>
    );
  }

  const RRSpecter = () => {
    let x = getDataByType(ChartDataTypes.RRSpecter);
    return (
      <>
        {x && <RRSpecterChart data={x.data} displayName={x.displayName} plotLinesX={x.plotLinesX} />}
      </>
    );
  }

  const EcgAverageInterval = () => {
    let x = getDataByType(ChartDataTypes.EcgAverageInterval);
    return (
      <>
        {x && <AverageIntervalChart data={x.data} displayName={x.displayName} markers={x.markers} />}
      </>
    );
  }

  return (
    <>
      <h2 className="h2">{t('recordView.processedData.headerPart')}</h2>

      <AllProcessedDataValues values={singleValues} isDetailed={true} />

      <Row className="pt-5"><p>{t('recordView.processedData.selectTimeRange')}</p></Row>
      <Row className="py-3">
        <Col>
          <AllProcessedDataSlider ref={sliderRef} minTimeSec={0} maxTimeSec={initialMaxTimeSeconds} onTimeChangedCallback={onSliderTimeChange} />
        </Col>
      </Row>

      <Row className="py-3">
        <Col className={styles.oneChart}>
          <HRHistogramComponent />
        </Col>
        <Col className={styles.oneChart}>
          <RRHistogramComponent />
        </Col>
        <Col className={styles.oneChart}>
          <ScatterComponent />
        </Col>
      </Row>

      <Row className="py-3">
        <Col className={styles.oneChart}>
          <HRSpecter />
        </Col>
        <Col className={styles.oneChart}>
          <RRSpecter />
        </Col>
        <Col className={styles.oneChart}>
          <EcgAverageInterval />
        </Col>
      </Row>

      <Row>

      </Row>

    </>
  );
});