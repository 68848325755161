import React, {useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Button, Alert, Row, Col } from 'react-bootstrap'
import api from "../../../services/api";

import RequestConsultationConfirmModal from '../../Consultation/RequestConsultationConfirmModal'

import Loading from '../Loading';
import { dateFormatter } from '../../../utils/dateFormatter'

export default function ViewConsultationList({ recordUid, allowRequestConsultation }) {
  const { t } = useTranslation();
  const [initialLoaded, setInitialLoaded] = useState(false);

  const [reportList, setReportList] = useState();

  const refreshList = () => {
    api
      .get(`/consultation/user/record/${recordUid}`)
      .then((response) => {
        setReportList(response.data);
        setInitialLoaded(true);
      });
  };

  useEffect(() => {
    refreshList(recordUid, allowRequestConsultation);

  }, [recordUid, allowRequestConsultation]);

  if (!initialLoaded)
    return <Loading />;

  return (
    <>
      <h2 className="h2">{t('consultation.user.view.header')}</h2>
      {reportList.items.length == 0 &&
        <>
        <h4 className="h4">{t('consultation.user.view.noConsultations')}</h4>
        {allowRequestConsultation &&
          <RequestConsultationConfirmModal
            recordUid={recordUid}
            requestSentCallback={refreshList}
          />
        }
        </>
      }
      {reportList.items.map(report =>
        <React.Fragment key={report.uid}>
          <Row>
            <Col>
              {t('consultation.user.view.state')}: {report.state}
            </Col>
            <Col>
              {t('consultation.user.view.requestDate')}: {dateFormatter(report.requestDate)}
            </Col>
            
            <Col>
              {report.acceptDate && 
                <>{t('consultation.user.view.acceptDate')}: {dateFormatter(report.acceptDate)}</>
              }
            </Col>
          </Row>
          <Row>
            {!(report.acceptDate) &&
              <i>{t('consultation.user.view.waitingExpert')}</i>
            }

            {(report.acceptDate && !report.completeDate) &&
              <i>{t('consultation.user.view.waitingReport')}</i>
            }
          </Row>
          {(report.acceptDate && report.completeDate) &&
            <>
              <Row>
                <Col>
                  {t('consultation.user.view.completeDate')}: {dateFormatter(report.completeDate)}
              </Col>
              <Col>
                  {t('consultation.user.view.expertName')}: {report.expert.displayName}
              </Col>
              <Col>

              </Col>
              </Row>                         
              <Row>
                <Col>
                  {t('consultation.user.view.report.header')}:
                </Col>
              </Row>
              <Row>
              <Col>
                <Form.Control as="textarea" rows={5} disabled value={report.report.reportText}></Form.Control>                
                </Col>
              </Row>
            </>
          }
          <Row className="mb-4"></Row>
        </React.Fragment>
      )}

    </>
  );
}