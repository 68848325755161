import { atom } from 'recoil';

const selectedUserState = atom({
  key: 'selectedUserState', // unique ID (with respect to other atoms/selectors)
  default: {
    //uid: '00000000-0000-0000-0000-000000000000', // default value (aka initial value)
    uid: null,
    name: '',
    permissions: 0
  }
});

export { selectedUserState }