import { atom } from 'recoil';

const userListState = atom({
  key: 'userListState', // unique ID (with respect to other atoms/selectors)
  default: {
    mainUser: null,
    users: [],
    groups: []
  }
});

export { userListState }