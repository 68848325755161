import React, { useState, useEffect } from 'react';

//import { useParams } from 'react-router-dom';

import ExpertCompletedConsultations from '../../components/Consultation/ExpertCompletedConsultations'

import MainBlockHeader from '../MainBlockHeader'
import MainBlockContainer from '../MainBlockContainer';
import { useTranslation } from 'react-i18next';

export default function CompletedConsultations() {  
  //let { userUid } = useParams();
  const { t } = useTranslation();

  return (
    <>              
      <MainBlockHeader header={t('consultation.expert.completedList.header')} >
      </MainBlockHeader>

      <MainBlockContainer>      
        <ExpertCompletedConsultations />
      </MainBlockContainer>
    </>
  );  
}

