import i18n from 'i18next'

export function recordLengthFormatter(seconds, isShowZeroes) {
  //if (seconds < 3600)
  //  return new Date(seconds * 1000).toISOString().substring(14, 22);
  //return new Date(seconds * 1000).toISOString().substring(11, 19)
  //let date = new Date(seconds * 1000);
  if (isShowZeroes && seconds === 0) return `0''`;

  if (!seconds) return '';

  let days = Math.floor(seconds / 86400);
  let hours = Math.floor((seconds - days * 86400) / 3600);
  let minutes = Math.floor((seconds - days * 86400 - hours * 3600) / 60);
  //let sec = Math.round((seconds - days * 86400 - hours * 3600 - minutes * 60) * 10) / 10;
  let sec = Math.round(seconds - days * 86400 - hours * 3600 - minutes * 60);

  let str = '';
  if (days > 0) str += `${days}d `;
  if (hours > 0) str += `${hours}h `;
  str += `${minutes}'\u00A0${sec}''`;

  return str;
}