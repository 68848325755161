import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
//import { dateFormatter } from '../../../utils/dateFormatter'

function DeviceItemCalculated({ calculated }) {
  const { t } = useTranslation();


  const ShowResultString = (calculatedValue) => `${calculatedValue.name}: ${calculatedValue.value.toFixed(calculatedValue.decimalPoints)} ${calculatedValue.postfix}`;

  return (
    <>
      {calculated && (calculated.mainValue || calculated.deviationPercentage) &&
        <>
        <Row className="h4 mt-4">
          <Col>{t('metrology.itemCalculated.header')}</Col>
        </Row>
          {calculated.mainValue &&
            <Row className="h5 mt-4">
              <Col>
                {ShowResultString(calculated.mainValue)}
            </Col>
            {calculated.deviationPercentage &&              
                <Col>
                  {ShowResultString(calculated.deviationPercentage)}
                </Col>              
            }
            </Row>
          }          
        </>
      }
    </>
  );
}

export default DeviceItemCalculated;
