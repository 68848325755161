import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
//import { recordLengthFormatter } from '../../../utils/recordLengthFormatter'

//import Loading from '../Loading'

import * as BodyPositionIcons from './Icons/BodyPositionIcons'

import styles from './ProcessedCalculatedData.module.scss'

export function ProcessedCalculatedData({ processedData }) {
  const { t } = useTranslation();

  useEffect(() => {

    console.log('processed data: ', processedData);

  }, []);

  const bodyPositionMapping = [
    { id: 1, icon: <BodyPositionIcons.LyingOnBack /> },
    { id: 2, icon: <BodyPositionIcons.LeanBack/> },
    { id: 3, icon: <BodyPositionIcons.Stand/> },
    { id: 4, icon: <BodyPositionIcons.LeanForward/> },
    { id: 5, icon: <BodyPositionIcons.LyingFaceDown/> },
    { id: 6, icon: <BodyPositionIcons.LyingRightSide/> },
    { id: 7, icon: <BodyPositionIcons.LyingLeftSide/> },
    { id: 8, icon: <BodyPositionIcons.LeanRight/> },
    { id: 9, icon: <BodyPositionIcons.LeanLeft/> },
  ];

  const bodyPositionIcon = (positionId, tempAccelData) => {
    //console.log('positionId: ', positionId, 'tempAccelData: ', tempAccelData);
    return bodyPositionMapping.find(x => x.id === positionId)?.icon
  };

  const BodyIcon = ({ title, value, postFix }) => (
    <div lg className={styles.bodyIcon}>{title && <span>{title}: </span>}{value && <span className={styles.valueCell}>{value}{postFix}</span>}</div>
  )

  const HeaderWithValues = ({ title, value, postFix }) => (
    <div className="flex-fill p-4">{title && <span>{title}: </span>}{value && <span className={styles.valueCell}>{value}{postFix}</span>}</div>
  )

  return (
    <>
      <div className={`${styles.lineWithData} h5 d-flex flex-wrap`}>        
        <BodyIcon value={bodyPositionIcon(processedData?.bodyPosition, processedData?.tempAccelData)} />
        <HeaderWithValues title={t('recordView.processedData.heartRate')} value={processedData?.heartRateValue} postFix='&nbsp;bpm'/>
        <HeaderWithValues title={t('recordView.processedData.hrv')} value={processedData?.hrv ?? '-'} />
        <HeaderWithValues title={t('recordView.processedData.si')} value={processedData?.si ?? '-'} />
        <HeaderWithValues title={t('recordView.processedData.breath')} value={processedData?.breath ?? '-'} />
        <HeaderWithValues title={t('recordView.processedData.apnoe')} value={processedData?.apnoe ?? '-'} />
        <HeaderWithValues title={t('recordView.processedData.temperature')} value={processedData?.temperature} postFix={'\u00B0C'} />
        
      </div >

    </>
  );
}

