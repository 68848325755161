import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ModalMessage({ show, onHide, header, body, button }) {
  return (
    <Modal
      show={show}
      onHide={onHide }
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {header}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>      
        <p>
          {body}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>{button}</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalMessage;