import fileDownload from 'js-file-download';
import api from "./api";

let fileDownloadShow = (url, defaultExtension) => {
  api.get(url, {
    responseType: 'blob',
  }).then((response) => {

    //There is no way to set the name of the file yet
    //https://stackoverflow.com/questions/41947735/custom-name-for-blob-url
    //
    //let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    //let matches = filenameRegex.exec(response.headers['content-disposition']);

    //let filename = `default.${defaultExtension ?? 'unknown'}`;

    //if (matches != null && matches[1]) {
    //  filename = matches[1].replace(/['"]/g, '');
    //}

    //Ideas from:
    //https://stackoverflow.com/questions/41561884/how-to-open-a-pdf-in-new-tab-in-reactjs
    //Create a Blob from the PDF Stream

    //console.log(response);
    const file = new Blob([response.data], { type: response.data.type });
    //Build a URL from the file
    const fileURL = URL.createObjectURL(file);    
    //Open the URL on new Window
    const pdfWindow = window.open();
    pdfWindow.location.href = fileURL;
  }).catch((error) => {
    console.log(error);
  });;
}
export default fileDownloadShow;