import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';

//https://github.com/iannbing/react-simple-tree-menu
//react-simple-tree-menu

export default DropdownChartSpeedBreathing;

function DropdownChartSpeedBreathing({ onSelectionChangedCallback }) {
  const { t } = useTranslation();

  const [value, setValue] = useState(2.5);
  const [speedPerMm, setSpeedPerMm] = useState(1000 / 2.5);

  const onSelectionChanged = function (eventKey) {
    setValue(eventKey);
    let speed = 1000 / eventKey;
    setSpeedPerMm(speed);

    if (onSelectionChangedCallback)
      onSelectionChangedCallback(speed);
  }

  return (   
    <>
      <div className="text-nowrap">
        <label>{t('recordView.multiChart.ecg.speed')}:</label>
        <Dropdown onSelect={(eventKey) => { onSelectionChanged(eventKey) }} className="d-inline px-2">
          <Dropdown.Toggle variant="secondary" id="dropdown-basic">{value} mm/s</Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item eventKey="1">1 mm/s</Dropdown.Item>
            <Dropdown.Item eventKey="2.5">2.5 mm/s</Dropdown.Item>
            <Dropdown.Item eventKey="5">5 mm/s</Dropdown.Item>
            <Dropdown.Item eventKey="10">10 mm/s</Dropdown.Item>
            <Dropdown.Item eventKey="20">20 mm/s</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <label>1mm = {speedPerMm < 1000 ? `${speedPerMm}ms` : `${speedPerMm / 1000}s`}</label>
      </div>
    </>
    );
}
