import React, { Component } from 'react';

import { Container, Row, Col} from 'react-bootstrap';

import { Outlet } from 'react-router-dom';
import styles from './LayoutAuth.module.scss'

export class LayoutAnonymous extends Component {  

  render() {
    return (
      <>        
        <div className={`${styles.root}`}>          
          <Container fluid="xl" className={`h-100`}>
            <Row className="h-100">
              <Col className="" >
                <main>
                  <div>
                    <Outlet />
                  </div>
                </main>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}
