import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from "../../../services/api";
import '../../../../node_modules/react-simple-tree-menu/dist/main.css';
import { FormControl, ListGroup, Button, Row, Col, Form, Stack, Container } from 'react-bootstrap';
import styles from './UserLoginUsersWithPermissions.module.scss';
import UsersGroupsList from './UsersGroupsList';
import Loading from '../Loading'
import UserLoginUsersPermissionList from './UserLoginUsersPermissionList'
import UserLoginUsersPermissionsAdd from './UserLoginUsersPermissionsAdd'

//https://github.com/iannbing/react-simple-tree-menu
//react-simple-tree-menu

export default UserLoginUsersWithPermissions;

function UserLoginUsersWithPermissions({ userLoginUserListBaseUrl, basePermissionsUrl, blockEditUserList }) {
  //const user = useRecoilValue(selectedUserState);
  const { t } = useTranslation();
  let [users, setUsers] = useState();
  let [selectedGroup, setSelectedGroup] = useState({ uid: '', name: '' });
  const [controlEditMode, setControlEditMode] = useState(false);

  const [userPermissions, setUserPermissions] = useState(null);
  const [isGroup, setIsGroup] = useState(null);
  const [isPermissionLoading, setPermissionLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  //const refreshList = function () {
  //  loadUsers();
  //}

  //const loadUsers = function () {
  //  api
  //    .get(`/settings/users`)
  //    .then((response) => {
  //      setUsers(response.data.userList);
  //    });
  //};

  useEffect(() => {
    //loadUsers();
  }, []);

  const onUserClick = function (item) {
    console.log('Clicked on user: ', item);
    setPermissionLoading(true);
    api.get(`${basePermissionsUrl}/user/${item.itemUid}`)
      .then((response) => {
        console.log(response.data);
        setUserPermissions(response.data);
        setIsGroup(false);
        setPermissionLoading(false);
        setSelectedItem(item);
      });
  };

  const onGroupClick = function (item) {
    console.log('Clicked on group: ', item);
    setPermissionLoading(true);
    api.get(`${basePermissionsUrl}/group/${item.itemUid}`)
      .then((response) => {
        console.log(response.data);
        setUserPermissions(response.data);
        setIsGroup(true);
        setPermissionLoading(false);
        setSelectedItem(item);
      });
  };

  const repeatOnItemClick = function () {
    if (isGroup)
      onGroupClick(selectedItem);
    else
      onUserClick(selectedItem);
  };

  const onUpdatePersmissions = function () {
    return new Promise((resolve, reject) => {      
      console.log(userPermissions);
      let url = `${basePermissionsUrl}/${isGroup ? 'group' : 'user'}/${selectedItem.itemUid}/update`
      api.post(url, userPermissions)
        .then(response => {        
          if (response.data && response.data.success) {
            repeatOnItemClick();
            resolve();
          } else {
            reject(response.data.errorMessage);
          }
        },
        error => {
          reject();
        });    
    });
  };

  //const onSavePersmissions = function (newPermissions) {
  //  console.log(newPermissions);
  //  repeatOnItemClick();
  //}

  const onSavePersmissions = function (newPermissions) {
    return new Promise((resolve, reject) => {   
      let url = `${basePermissionsUrl}/${isGroup ? 'group' : 'user'}/${selectedItem.itemUid}/add`;
      api.post(url, newPermissions).
        then(response => {
          if (response.data && response.data.result) {
            repeatOnItemClick();
            resolve();
          } else {
            reject();
          }          
        },
          error => {
            reject();
          }); 
    });
  };


  return (
    <>      
      {/*<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">*/}
      <div className={`d-flex p-2 bd-highlight ${styles.settingsUserList}`}>
        <div className={`p-2  w-100 flex-fill bd-highlight ${styles.user_tree}`}>

          <UsersGroupsList onUserItemClick={onUserClick} onGroupItemClick={onGroupClick} userLoginUserListBaseUrl={userLoginUserListBaseUrl} blockEdit={blockEditUserList} />

        </div>

        <div className={`p-2  w-100 flex-fill bd-highlight ${styles.settingsPermissions}`}>
          <Container>
            <Row><Col>{t('settings.userPermissions.header')}</Col></Row>
            <Row>
              <Col>{isPermissionLoading && <Loading />}</Col>
            </Row>
            <Row>
              {userPermissions && !isPermissionLoading && <Col><UserLoginUsersPermissionList permissions={userPermissions} isGroup={isGroup} onUpdatePersmissions={onUpdatePersmissions} /></Col>}
            </Row>    
            <Row className="mt-5">
              {userPermissions && userPermissions.canAdd && <Col><UserLoginUsersPermissionsAdd isGroup={isGroup} onSavePersmissions={onSavePersmissions} /></Col> }
            </Row>
          </Container>
        </div>
      </div>
      {/*</div>*/}
    </>
  )
}
